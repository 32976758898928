import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Divider,
  Chip,
  Button,
  Stack
} from '@mui/material';
import { format } from 'date-fns';
import { 
  Email as EmailIcon,
  Download as DownloadIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon
} from '@mui/icons-material';
import { toast } from 'react-hot-toast';

const WaiverDetailView = ({ waiver, onClose }) => {
  const formatDate = (date) => {
    if (!date) return 'N/A';
    return format(new Date(date), 'MMM d, yyyy h:mm a');
  };

  const getStatusChip = (status) => {
    switch (status) {
      case 'pending':
        return <Chip label="Pending" color="warning" />;
      case 'completed':
        return <Chip label="Completed" color="success" />;
      default:
        return <Chip label={status} />;
    }
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Participant Information
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary">Name</Typography>
              <Typography variant="body1">{waiver.participantName || 'N/A'}</Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary">Email</Typography>
              <Typography variant="body1">{waiver.participantEmail || 'N/A'}</Typography>
            </Box>
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Booking Information
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary">Booking Number</Typography>
              <Typography variant="body1">{waiver.bookingNumber || 'N/A'}</Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary">Total Participants</Typography>
              <Typography variant="body1">{waiver.totalParticipants || 'N/A'}</Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary">Start Time</Typography>
              <Typography variant="body1">{waiver.startTime || 'N/A'}</Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle2" color="text.secondary">Meeting Point</Typography>
              <Typography variant="body1">{waiver.meetingPoint || 'N/A'}</Typography>
            </Box>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Tour Information
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" color="text.secondary">Tour Name</Typography>
                  <Typography variant="body1">{waiver.tourName || 'N/A'}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" color="text.secondary">Tour ID</Typography>
                  <Typography variant="body1">{waiver.tourId || 'N/A'}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" color="text.secondary">Tour Date</Typography>
                  <Typography variant="body1">{waiver.tourDate || 'N/A'}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Waiver Information
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" color="text.secondary">Waiver Template</Typography>
                  <Typography variant="body1">{waiver.waiverTemplateName || 'N/A'}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" color="text.secondary">Status</Typography>
                  {getStatusChip(waiver.status)}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" color="text.secondary">Created At</Typography>
                  <Typography variant="body1">{formatDate(waiver.createdAt)}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" color="text.secondary">Email Sent</Typography>
                  <Typography variant="body1">{waiver.emailSent ? 'Yes' : 'No'}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" color="text.secondary">Email Sent At</Typography>
                  <Typography variant="body1">{formatDate(waiver.emailSentAt)}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" color="text.secondary">Completed At</Typography>
                  <Typography variant="body1">{formatDate(waiver.completedAt)}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        
        {waiver.status === 'completed' && waiver.formData && (
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Form Data
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                {Object.entries(waiver.formData).map(([key, value]) => (
                  <Grid item xs={12} md={6} key={key}>
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="subtitle2" color="text.secondary">{key}</Typography>
                      <Typography variant="body1">
                        {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value || 'N/A'}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>
        )}
        
        {waiver.status === 'completed' && waiver.signature && (
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Signature
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Box sx={{ mb: 2, border: '1px solid', borderColor: 'grey.300', p: 1 }}>
                <img src={waiver.signature} alt="Signature" style={{ maxWidth: '100%' }} />
              </Box>
            </Paper>
          </Grid>
        )}
        
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Actions
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Stack direction="row" spacing={2}>
              {waiver.status === 'pending' && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EmailIcon />}
                  onClick={() => {
                    // Implement resend functionality
                    toast.success('Email resent successfully');
                  }}
                >
                  Resend Email
                </Button>
              )}
              
              {waiver.status === 'completed' && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  onClick={() => {
                    // Implement download functionality
                    toast.success('Waiver downloaded successfully');
                  }}
                >
                  Download Waiver
                </Button>
              )}
              
              {waiver.status === 'pending' && (
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<CheckCircleIcon />}
                  onClick={() => {
                    // Implement mark as completed functionality
                    toast.success('Waiver marked as completed');
                  }}
                >
                  Mark as Completed
                </Button>
              )}
              
              <Button
                variant="outlined"
                color="error"
                startIcon={<CancelIcon />}
                onClick={() => {
                  // Implement cancel/delete functionality
                  toast.success('Waiver request cancelled');
                  onClose();
                }}
              >
                Cancel Request
              </Button>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WaiverDetailView; 