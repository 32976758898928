import React from 'react';
import { Box, Button, Typography, Card, useTheme, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const FloatingBookingWidget = ({ tourDetails, onBookNow }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (!isMobile) return null;

  return (
    <Box
      component={motion.div}
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 260, damping: 20 }}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        display: { xs: 'block', md: 'none' }
      }}
    >
      <Card
        elevation={0}
        sx={{
          background: 'rgba(255, 255, 255, 0.95)',
          backdropFilter: 'blur(10px)',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          borderRadius: '16px 16px 0 0',
          py: 2.5,
          px: { xs: 3, sm: 4 },
          margin: 0,
          maxWidth: '100%',
          borderTop: `1px solid ${theme.brandColors.blue}10`
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: { xs: 2, sm: 3 },
          width: '100%'
        }}>
          <Box sx={{ 
            flex: '1 1 auto',
            overflow: 'hidden',
            mr: 2
          }}>
            <Typography 
              variant="h6" 
              sx={{ 
                color: theme.brandColors.blue,
                fontWeight: 800,
                fontSize: '1.25rem',
                lineHeight: 1.2,
                mb: 0.5,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {tourDetails.price}
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                color: 'text.secondary',
                fontSize: '0.9rem',
                lineHeight: 1.2,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                opacity: 0.9
              }}
            >
              {tourDetails.shortTitle}
            </Typography>
          </Box>
          <Box 
            component={motion.div}
            whileTap={{ scale: 0.97 }}
            sx={{ flex: '0 0 auto' }}
          >
            <Button
              variant="contained"
              onClick={onBookNow}
              endIcon={<ArrowForwardIcon />}
              sx={{
                backgroundColor: theme.brandColors.blue,
                color: 'white',
                fontWeight: 600,
                whiteSpace: 'nowrap',
                px: { xs: 3, sm: 4 },
                py: 1.5,
                fontSize: '1.1rem',
                borderRadius: '50px',
                transition: 'all 0.2s ease-in-out',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: theme.brandColors.lightBlue,
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '1.2rem',
                  ml: 0.5
                }
              }}
            >
              Book Now
            </Button>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default FloatingBookingWidget; 