import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, Paper, Grid, Divider, Chip, CircularProgress, Card, 
  CardContent, CardActions, Button, IconButton, Tooltip
} from '@mui/material';
import { format, addDays, isWithinInterval, parseISO } from 'date-fns';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SendIcon from '@mui/icons-material/Send';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import { useTheme } from '@mui/material/styles';

const UpcomingToursView = ({ onViewDetails, onViewChoices, onResendEmail, guestChoices }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [upcomingTours, setUpcomingTours] = useState([]);
  
  useEffect(() => {
    fetchUpcomingTours();
  }, []);
  
  const fetchUpcomingTours = async () => {
    try {
      setLoading(true);
      
      // Get today's date and one week ahead
      const today = new Date();
      const oneWeekAhead = addDays(today, 7);
      
      // Query menu requests for tours in the next 7 days
      const requestsQuery = query(
        collection(db, 'menuRequests')
      );
      
      const querySnapshot = await getDocs(requestsQuery);
      
      const tours = [];
      querySnapshot.forEach((doc) => {
        const tourData = {
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || new Date()
        };
        
        // Parse tour date and check if it's within the next 7 days
        try {
          if (tourData.tourDate) {
            // Try to parse the tour date
            let tourDateObj;
            if (typeof tourData.tourDate === 'string') {
              // Handle different string date formats
              if (tourData.tourDate.includes('Invalid')) {
                // Skip invalid dates
                return;
              }
              
              // Try to parse with specific formats
              try {
                tourDateObj = new Date(tourData.tourDate);
                
                // Backup way to extract date if needed - match patterns like "Tuesday, March 18, 2025"
                if (!isNaN(tourDateObj.getTime())) {
                  // Valid date, add to tours
                  tourData.tourDateObj = tourDateObj;
                  tours.push(tourData);
                }
              } catch (e) {
                console.warn('Error parsing date:', tourData.tourDate);
              }
            }
          }
        } catch (err) {
          console.error('Error processing tour date:', err);
        }
      });
      
      // Sort by tour date
      tours.sort((a, b) => a.tourDateObj - b.tourDateObj);
      
      setUpcomingTours(tours);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching upcoming tours:', error);
      setLoading(false);
    }
  };
  
  const groupToursByDay = () => {
    const days = {};
    
    // Group tours by date
    upcomingTours.forEach(tour => {
      const dateKey = format(tour.tourDateObj, 'yyyy-MM-dd');
      const displayDate = format(tour.tourDateObj, 'EEEE, MMMM d, yyyy');
      
      if (!days[dateKey]) {
        days[dateKey] = {
          date: displayDate,
          tours: []
        };
      }
      
      days[dateKey].tours.push(tour);
    });
    
    // Convert to array for rendering
    return Object.values(days);
  };
  
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
        <CircularProgress />
      </Box>
    );
  }
  
  const dayGroups = groupToursByDay();
  
  return (
    <Box>
      {dayGroups.length === 0 ? (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="h6">No upcoming tours found</Typography>
        </Paper>
      ) : (
        dayGroups.map((dayGroup, index) => (
          <Paper key={index} sx={{ mb: 3, overflow: 'hidden' }}>
            <Box sx={{ p: 2, backgroundColor: theme.palette.primary.main, color: 'white' }}>
              <Typography variant="h6">{dayGroup.date}</Typography>
            </Box>
            <Box sx={{ p: 2 }}>
              <Grid container spacing={2}>
                {dayGroup.tours.map(tour => {
                  const hasSubmitted = guestChoices[tour.bookingNumber] !== undefined;
                  
                  return (
                    <Grid item xs={12} md={6} lg={4} key={tour.id}>
                      <Card variant="outlined" sx={{
                        backgroundColor: hasSubmitted ? 'rgba(76, 175, 80, 0.1)' : 'inherit'
                      }}>
                        <CardContent>
                          <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                            {tour.tourName}
                          </Typography>
                          <Box sx={{ mb: 1 }}>
                            <Chip 
                              size="small" 
                              label={tour.tourType || 'No Type'} 
                              color="primary"
                              sx={{ mr: 1 }}
                            />
                            {hasSubmitted ? (
                              <Chip 
                                size="small" 
                                label="Menu Completed" 
                                color="success"
                              />
                            ) : (
                              <Chip 
                                size="small" 
                                label="Menu Pending" 
                                color="warning"
                              />
                            )}
                          </Box>
                          <Typography variant="body2" color="text.secondary">
                            <strong>Guest:</strong> {tour.participantName}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            <strong>Email:</strong> {tour.participantEmail}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            <strong>Booking:</strong> {tour.bookingNumber}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Tooltip title="View Details">
                            <IconButton 
                              size="small" 
                              onClick={() => onViewDetails(tour)}
                              color="primary"
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                          {hasSubmitted && (
                            <Tooltip title="View Menu Choices">
                              <IconButton 
                                size="small" 
                                onClick={() => onViewChoices(tour)}
                                color="secondary"
                                sx={{ ml: 1 }}
                              >
                                <RestaurantMenuIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {!hasSubmitted && (
                            <Tooltip title="Resend Email">
                              <IconButton 
                                size="small" 
                                onClick={() => onResendEmail(tour)}
                                color="info"
                                sx={{ ml: 1 }}
                              >
                                <SendIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Paper>
        ))
      )}
    </Box>
  );
};

export default UpcomingToursView; 