// src/pages/TemplateTourPage.jsx

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Box,
  Divider,
  useMediaQuery,
  useTheme,
  Container,
  Chip
} from '@mui/material';
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';
import { db } from 'config/firebase/firebase';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'; // Example if you want more icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // Added for the Book Now button arrow
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import { motion } from 'framer-motion';

import TrekksoftBookingWidget from 'hooks/useTrekksoftLoader';
import RecommendedTours from './RecommendedTours';
import FooterMobile from 'components/FooterMobile.js'; // If you still use it
import tourImages from '../data/tour-images.json';
import { extractTourId } from '../utils/urlUtils';
import FloatingBookingWidget from './FloatingBookingWidget';

function TemplateTourPage() {
  // 1. Hooks & Data
  const { tourId: urlParam } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Extract the actual tour ID from either format
  const tourId = extractTourId(`/tour/${urlParam}`) || urlParam;

  const [tourDetails, setTourDetails] = useState({
    headerImage: '',
    shortTitle: '',
    shortDescription: '',
    duration: '',
    price: '',
    highlights: [],
    travelerTips: [],
    requirements: [],
    included: [],
    days: '',
    description: '',
    activityLevel: '',
    departureTime: '',
    ages: '',
  });

  const [galleryImages, setGalleryImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [recommendedTours, setRecommendedTours] = useState([]);
  
  // Add state for highlights gallery
  const [highlightIndex, setHighlightIndex] = useState(0);

  // Add scroll position tracking
  const [isScrolled, setIsScrolled] = useState(false);

  // Add this effect to handle WhatsApp button positioning
  const [showBookingWidget, setShowBookingWidget] = useState(false);

  // 2. Fetching Data
  useEffect(() => {
    const fetchTourDetailsAndImages = async () => {
      try {
        const tourRef = doc(db, 'rewrittenTourDescriptions', tourId);
        const tourSnap = await getDoc(tourRef);

        if (tourSnap.exists()) {
          const data = tourSnap.data();
          let includedData = data.included || [];
          if (typeof includedData === 'string') {
            includedData = includedData.split(',').map(item => item.trim());
          }

          setTourDetails({
            headerImage: tourImages[tourId]?.header || '',
            shortTitle: data.shortTitle || '',
            shortDescription: data.shortDescription || '',
            duration: data.duration || '',
            price: data.price || '',
            highlights: data.highlights || [],
            travelerTips: data.travelerTips || [],
            requirements: data.requirements || [],
            included: includedData,
            days: data.days || '',
            description: data.description || '',
            activityLevel: data.activityLevel || '',
            departureTime: data.departureTime || '',
            ages: data.ages || '',
          });

          setGalleryImages(tourImages[tourId]?.gallery || []);
        } else {
          console.log('No tour details found!');
        }

        const recommendedSnap = await getDocs(collection(db, 'recommendedTours'));
        const recommendedData = recommendedSnap.docs.map(doc => doc.data());
        setRecommendedTours(recommendedData);
      } catch (err) {
        console.error('Error fetching tour details:', err);
      }
    };

    fetchTourDetailsAndImages();
  }, [tourId]);

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 100;
      if (show !== isScrolled) setIsScrolled(show);
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled]);

  // 3. Handlers
  const handleBookNowClick = () => {
    setShowBookingWidget(true);
    const bookingUrl = `https://bw.trekksoft.com/views/window.html?type=activity-booking&id=${tourId}&clientId=BOOKIE-5badffc8663be9c7621fe18cf&token=f644f78038045737f3c2da77ea17bf4521b12a1ccdc0aded`;
    window.open(bookingUrl, 'BookingWindow', 'width=600,height=800,scrollbars=yes,resizable=yes');
  };

  const handleImageClick = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };
  
  // Add handlers for highlights gallery navigation
  const handlePrevHighlight = () => {
    setHighlightIndex((prevIndex) => 
      prevIndex === 0 ? galleryImages.length - 1 : prevIndex - 1
    );
  };
  
  const handleNextHighlight = () => {
    setHighlightIndex((prevIndex) => 
      (prevIndex + 1) % galleryImages.length
    );
  };

  // 4. Layout & Render
  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      transition={{ duration: 0.5 }}
    >
      {/* Mobile Sticky Bottom Bar */}
      {isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: '#FFFFFF',
            boxShadow: '0 -2px 8px rgba(0,0,0,0.1)',
            zIndex: 1000,
            p: 1.5,
            display: { xs: 'block', md: 'none' },
            borderTop: 1,
            borderColor: 'divider'
          }}
        >
          <Container maxWidth="lg">
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
              px: { xs: 1, sm: 2 }
            }}>
              <Box sx={{ flex: 1 }}>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    color: '#4355b9',
                    fontWeight: 700,
                    fontSize: '1.1rem',
                    mb: 0.25
                  }}
                >
                  {tourDetails.price}
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'text.secondary',
                    fontSize: '0.85rem'
                  }}
                  noWrap
                >
                  {tourDetails.shortTitle}
                </Typography>
              </Box>
              <Button
                variant="contained"
                onClick={handleBookNowClick}
                endIcon={<ArrowForwardIcon />}
                sx={{
                  backgroundColor: '#4355b9',
                  color: 'white',
                  fontWeight: 600,
                  whiteSpace: 'nowrap',
                  minWidth: '150px',
                  px: 4,
                  py: 1,
                  fontSize: '0.95rem',
                  borderRadius: '50px',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
                  '&:hover': {
                    backgroundColor: '#3a49a0',
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: '1rem',
                    ml: 0.5
                  }
                }}
              >
                Book Now
              </Button>
            </Box>
          </Container>
        </Box>
      )}

      {/* Add padding bottom to content for mobile sticky bar */}
      <Box sx={{ pb: isMobile ? '80px' : 0 }}>
        {/* Hero Section with Centered Text Overlay */}
        <Box
          sx={{
            width: '100%',
            height: isMobile ? '80vh' : '85vh',
            overflow: 'hidden',
            position: 'relative',
            mb: { xs: 4, md: 16 } // Increased bottom margin to make space for the widget
          }}
        >
          <motion.img
            initial={{ scale: 1.1 }}
            animate={{ scale: 1 }}
            transition={{ duration: 1.2 }}
            src={tourDetails.headerImage}
            alt="Tour Hero"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center center',
              display: 'block'
            }}
          />
          
          {/* Text Overlay */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              padding: { xs: 3, md: 5 },
              background: 'linear-gradient(to bottom, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.4) 100%)',
              zIndex: 2
            }}
          >
            <Typography
              variant="h1"
              component="h1"
              sx={{
                color: 'white',
                fontWeight: 800,
                fontSize: { xs: '3rem', sm: '3.5rem', md: '5rem' },
                letterSpacing: '0.05em',
                textTransform: 'uppercase',
                mb: 2,
                textShadow: '0 2px 10px rgba(0,0,0,0.3)',
                marginTop: { xs: '2rem', md: '4rem' }
              }}
            >
              {tourDetails.shortTitle}
            </Typography>
            
            <Typography
              variant="h5"
              sx={{
                color: 'white',
                fontWeight: 400,
                fontSize: { xs: '1rem', sm: '1.2rem', md: '1.4rem' },
                maxWidth: '800px',
                mb: 5,
                textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                display: { xs: 'none', md: 'block' } // Hide on mobile, show on desktop
              }}
            >
              {tourDetails.shortDescription.replace(/\. Duration:.*$/, '')}
            </Typography>
            
            <Button
              variant="contained"
              onClick={handleBookNowClick}
              endIcon={<ArrowForwardIcon />}
              sx={{
                backgroundColor: '#4355b9',
                color: 'white',
                fontWeight: 600,
                px: { xs: 5, md: 8 },
                py: { xs: 1.5, md: 1.8 },
                fontSize: { xs: '1rem', md: '1.1rem' },
                borderRadius: '50px',
                boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
                minWidth: { xs: '200px', md: '250px' },
                '&:hover': {
                  backgroundColor: '#3a49a0',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '1.2rem',
                  ml: 1
                },
                display: { xs: 'none', md: 'flex' } // Hide on mobile, show on desktop
              }}
            >
              Book Now
            </Button>
            
            {/* Tour Info Text - Matching the image style with GUARANTEED spacing */}
            <Box
              sx={{
                display: { xs: 'none', md: 'block' }, // Hide on mobile, show on desktop
                mt: 4, // Keep the original margin top
                width: '100%',
                textAlign: 'center',
                zIndex: 5
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: 'white',
                  fontWeight: 500,
                  fontSize: { md: '1.5rem' },
                  letterSpacing: '0.05em',
                  textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                  lineHeight: 1.5
                }}
              >
                {(() => {
                  // Build the info string with guaranteed spacing
                  const infoParts = [];
                  
                  if (tourDetails.ages) {
                    infoParts.push(`Ages: ${tourDetails.ages}`);
                  }
                  
                  if (tourDetails.duration) {
                    infoParts.push(`Duration: ${tourDetails.duration}`);
                  }
                  
                  if (tourDetails.activityLevel) {
                    infoParts.push(`Activity Level: ${tourDetails.activityLevel}`);
                  }
                  
                  if (tourDetails.departureTime) {
                    infoParts.push(`Departs: ${tourDetails.departureTime}`);
                  }
                  
                  if (tourDetails.days) {
                    infoParts.push(`Days: ${tourDetails.days}`);
                  }
                  
                  // Join all parts with proper spacing
                  return infoParts.join(' | ');
                })()}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Rest of the content */}
        <Container maxWidth="xl" sx={{ position: 'relative' }}>
          {/* Booking Widget - Positioned to overlap with hero */}
          <Box
            sx={{
              display: { xs: 'none', md: 'block' },
              position: { xs: 'static', md: 'absolute' },
              top: { md: '-180px' },
              right: { md: '5%' },
              zIndex: 20,
              width: { md: '520px' }, // Increased from 450px to 520px for a much wider widget
              maxWidth: { md: '100%' }
            }}
          >
            <Card
              sx={{
                boxShadow: 4,
                borderRadius: 2,
                overflow: 'visible',
                bgcolor: 'white',
                border: '1px solid #eaeaea',
                minHeight: { md: '750px' } // Increased from 700px to 750px
              }}
            >
              <CardContent sx={{ p: 2 }}>
                <Typography
                  variant="h5"
                  sx={{ 
                    fontWeight: 600, 
                    color: '#4355b9',
                    mb: 2, // Reduced from mb: 3 to mb: 2 to give more space to the widget
                    fontSize: '1.3rem',
                    textAlign: 'center'
                  }}
                >
                  Book Your Experience Today
                </Typography>
                
                {/* Use the default booking widget without any custom styling */}
                <TrekksoftBookingWidget tourId={tourId} />
              </CardContent>
            </Card>
          </Box>

          <Grid
            container
            spacing={4}
            sx={{ 
              px: { xs: 2, md: 4 }, 
              mb: 0, 
              mt: 0,
              bgcolor: 'white',
              borderRadius: 2,
              boxShadow: 3,
              overflow: 'hidden',
              py: 4,
              position: 'relative',
              zIndex: 10
            }}
          >
            {/* --- LEFT COLUMN: Description, Inclusions, Highlights, Etc. --- */}
            <Grid item xs={12} md={7}>
              {/* Two-tone title */}
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <Typography
                  component="span"
                  sx={{
                    color: 'var(--turquoise-color)',
                    fontWeight: 800,
                    fontSize: { xs: '2.2rem', md: '2.5rem' },
                    letterSpacing: '0.02em',
                    mr: 1
                  }}
                >
                  TOUR
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    color: '#4355b9',
                    fontWeight: 800,
                    fontSize: { xs: '2.2rem', md: '2.5rem' },
                    letterSpacing: '0.02em'
                  }}
                >
                  DETAILS
                </Typography>
              </Box>

              {/* Tour Description */}
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: '1rem', md: '1.05rem' },
                  lineHeight: 1.7,
                  mb: 4,
                  color: '#333'
                }}
              >
                {tourDetails.description}
              </Typography>

              {/* INCLUDED Section */}
              <Typography
                variant="h6"
                sx={{
                  color: '#4355b9',
                  fontWeight: 700,
                  fontSize: { xs: '1.3rem', md: '1.5rem' },
                  mb: 2,
                  letterSpacing: '0.02em'
                }}
              >
                INCLUDED
              </Typography>
              {tourDetails.included && tourDetails.included.length > 0 ? (
                tourDetails.included.map((inc, idx) => (
                  <Typography 
                    key={idx} 
                    variant="body1" 
                    sx={{ 
                      mb: 1.5,
                      display: 'flex',
                      alignItems: 'flex-start',
                      fontSize: '1rem',
                      lineHeight: 1.5
                    }}
                  >
                    <Box component="span" sx={{ mr: 1, fontSize: '1.2rem', lineHeight: 1 }}>•</Box> {inc}
                  </Typography>
                ))
              ) : (
                <Typography variant="body1">No information available.</Typography>
              )}

              {/* REQUIREMENTS Section */}
              <Typography
                variant="h6"
                sx={{
                  color: '#4355b9',
                  fontWeight: 700,
                  fontSize: { xs: '1.3rem', md: '1.5rem' },
                  mt: 4,
                  mb: 2,
                  letterSpacing: '0.02em'
                }}
              >
                REQUIREMENTS
              </Typography>
              {tourDetails.requirements && tourDetails.requirements.length > 0 ? (
                tourDetails.requirements.map((req, idx) => (
                  <Typography 
                    key={idx} 
                    variant="body1" 
                    sx={{ 
                      mb: 1.5,
                      display: 'flex',
                      alignItems: 'flex-start',
                      fontSize: '1rem',
                      lineHeight: 1.5
                    }}
                  >
                    <Box component="span" sx={{ mr: 1, fontSize: '1.2rem', lineHeight: 1 }}>•</Box> {req}
                  </Typography>
                ))
              ) : (
                <Typography variant="body1">No special requirements.</Typography>
              )}

              {/* TIPS Section */}
              <Typography
                variant="h6"
                sx={{
                  color: '#4355b9',
                  fontWeight: 700,
                  fontSize: { xs: '1.3rem', md: '1.5rem' },
                  mt: 4,
                  mb: 2,
                  letterSpacing: '0.02em'
                }}
              >
                TIPS!
              </Typography>
              {tourDetails.travelerTips && tourDetails.travelerTips.length > 0 ? (
                tourDetails.travelerTips.map((tip, idx) => (
                  <Typography 
                    key={idx} 
                    variant="body1" 
                    sx={{ 
                      mb: 1.5,
                      display: 'flex',
                      alignItems: 'flex-start',
                      fontSize: '1rem',
                      lineHeight: 1.5
                    }}
                  >
                    <Box component="span" sx={{ mr: 1, fontSize: '1.2rem', lineHeight: 1 }}>•</Box> {tip}
                  </Typography>
                ))
              ) : (
                <Typography variant="body1">No tips provided.</Typography>
              )}
            </Grid>

            {/* --- RIGHT COLUMN: Mobile Booking Widget & Photo Gallery --- */}
            <Grid item xs={12} md={5}>
              {/* Mobile-only Booking Widget - Replaced with Book Now button */}
              <Box sx={{ mt: 4, mb: 4, display: { xs: 'block', md: 'none' } }}>
                <Card
                  sx={{
                    boxShadow: 4,
                    borderRadius: 2,
                    overflow: 'hidden',
                    bgcolor: 'white',
                    border: '1px solid #eaeaea',
                    p: 3
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ 
                      fontWeight: 600, 
                      color: '#4355b9',
                      mb: 2,
                      fontSize: '1.3rem',
                      textAlign: 'center'
                    }}
                  >
                  Book Your Experience Today
                  </Typography>
                  
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: 'center',
                      mb: 3,
                      color: 'text.secondary'
                    }}
                  >
                    Ready to experience this amazing tour? Book now to secure your spot!
                  </Typography>
                  
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      variant="contained"
                      onClick={handleBookNowClick}
                      endIcon={<ArrowForwardIcon />}
                      sx={{
                        backgroundColor: '#4355b9',
                        color: 'white',
                        fontWeight: 600,
                        px: 5,
                        py: 1.5,
                        fontSize: '1.1rem',
                        borderRadius: '50px',
                        boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
                        minWidth: '200px',
                        '&:hover': {
                          backgroundColor: '#3a49a0',
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: '1.2rem',
                          ml: 1
                        }
                      }}
                    >
                      Book Now
                    </Button>
                  </Box>
                </Card>
              </Box>

              {/* Removed the Photo Gallery from here as it's now a full-width section below */}
            </Grid>
          </Grid>

          {/* Lightbox for images */}
          {isOpen && (
            <Lightbox
              mainSrc={galleryImages[photoIndex]}
              nextSrc={galleryImages[(photoIndex + 1) % galleryImages.length]}
              prevSrc={
                galleryImages[
                  (photoIndex + galleryImages.length - 1) % galleryImages.length
                ]
              }
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex(
                  (photoIndex + galleryImages.length - 1) % galleryImages.length
                )
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % galleryImages.length)
              }
            />
          )}

          {/* Full-width Highlights/Gallery Section */}
          <Box 
            sx={{ 
              width: '100vw',
              position: 'relative',
              left: '50%',
              right: '50%',
              marginLeft: '-50vw',
              marginRight: '-50vw',
              mt: 8,
              mb: 8,
              overflow: 'hidden'
            }}
          >
            {/* Highlights Header */}
            <Box 
              sx={{ 
                bgcolor: '#FFFFFF',
                py: 3,
                textAlign: 'center'
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  color: '#4355b9',
                  fontWeight: 800,
                  fontSize: { xs: '2rem', md: '2.5rem' },
                  letterSpacing: '0.05em',
                  textTransform: 'uppercase'
                }}
              >
                HIGHLIGHTS
              </Typography>
            </Box>
            
            {/* Image Carousel with Highlight Overlay */}
            <Box 
              sx={{ 
                position: 'relative',
                height: { xs: '350px', md: '500px' },
                bgcolor: '#f5f5f5'
              }}
            >
              {/* Left Arrow */}
              <Box 
                onClick={handlePrevHighlight}
                sx={{ 
                  position: 'absolute',
                  left: { xs: '10px', md: '20px' },
                  top: '50%',
                  transform: 'translateY(-50%)',
                  zIndex: 10,
                  color: 'white',
                  fontSize: '3rem',
                  cursor: 'pointer',
                  textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                  width: '50px',
                  height: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  '&:hover': {
                    backgroundColor: 'rgba(0,0,0,0.5)',
                  }
                }}
              >
                ‹
              </Box>
              
              {/* Featured Image with Highlight Text Overlay */}
              <Box 
                sx={{ 
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                  overflow: 'hidden'
                }}
              >
                {galleryImages.length > 0 ? (
                  <motion.img 
                    key={highlightIndex}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    src={galleryImages[highlightIndex] || tourDetails.headerImage} 
                    alt={`Tour highlight ${highlightIndex + 1}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      display: 'block'
                    }}
                    onClick={() => handleImageClick(highlightIndex)}
                  />
                ) : (
                  <img 
                    src={tourDetails.headerImage} 
                    alt="Tour highlight"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      display: 'block'
                    }}
                  />
                )}
                
                {/* Highlight Text Overlay */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.5) 100%)',
                    padding: { xs: 3, md: 5 }
                  }}
                >
                  {tourDetails.highlights && tourDetails.highlights.length > 0 ? (
                    <motion.div
                      key={highlightIndex}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.2 }}
                    >
                      <Typography
                        variant="h2"
                        sx={{
                          color: 'white',
                          fontWeight: 800,
                          fontSize: { xs: '1.8rem', sm: '2.5rem', md: '3.5rem' },
                          letterSpacing: '0.05em',
                          textTransform: 'uppercase',
                          textAlign: 'center',
                          textShadow: '0 2px 8px rgba(0,0,0,0.5)',
                          mb: 3
                        }}
                      >
                        {tourDetails.highlights[highlightIndex % tourDetails.highlights.length].toUpperCase()}
                      </Typography>
                    </motion.div>
                  ) : (
                    <Typography
                      variant="h2"
                      sx={{
                        color: 'white',
                        fontWeight: 800,
                        fontSize: { xs: '1.8rem', sm: '2.5rem', md: '3.5rem' },
                        letterSpacing: '0.05em',
                        textTransform: 'uppercase',
                        textAlign: 'center',
                        textShadow: '0 2px 8px rgba(0,0,0,0.5)'
                      }}
                    >
                      TOUR HIGHLIGHTS
                    </Typography>
                  )}
                </Box>
              </Box>
              
              {/* Right Arrow */}
              <Box 
                onClick={handleNextHighlight}
                sx={{ 
                  position: 'absolute',
                  right: { xs: '10px', md: '20px' },
                  top: '50%',
                  transform: 'translateY(-50%)',
                  zIndex: 10,
                  color: 'white',
                  fontSize: '3rem',
                  cursor: 'pointer',
                  textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                  width: '50px',
                  height: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  '&:hover': {
                    backgroundColor: 'rgba(0,0,0,0.5)',
                  }
                }}
              >
                ›
              </Box>
            </Box>
          </Box>

          {/* Recommended Tours */}
          <RecommendedTours excludeTourId={tourId} />

          {/* Floating Booking Widget */}
          <FloatingBookingWidget 
            tourDetails={tourDetails}
            onBookNow={handleBookNowClick}
          />
        </Container>
      </Box>
    </motion.div>
  );
}

export default TemplateTourPage;