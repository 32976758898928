import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, Card, CardContent, Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box, Chip, Avatar, FormControlLabel, IconButton, Stack, Tooltip, Tabs, Tab, Divider, List, ListItem, ListItemText, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { db, functions } from '../../config/firebase/firebase'; 
import { collection, doc, setDoc, getDocs, getDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import { httpsCallable } from 'firebase/functions';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import StarIcon from '@mui/icons-material/Star';

function ActivityManager() {
  const [activities, setActivities] = useState([]);
  const [activitySelections, setActivitySelections] = useState({});
  const [rewrittenDescriptions, setRewrittenDescriptions] = useState({});
  const [editingActivity, setEditingActivity] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [openRewriteDialog, setOpenRewriteDialog] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [editTabValue, setEditTabValue] = useState(0);
  const [newListItem, setNewListItem] = useState('');
  const [displayOptions, setDisplayOptions] = useState([
    { label: 'Home Page', key: 'homePage' },
    { label: 'Most Popular', key: 'mostPopular' },
    { label: 'Transfers', key: 'transfers' },
    { label: 'Local Deals', key: 'localDeals' },
    { label: 'Special Offers', key: 'specialOffers' },
    { label: 'Menu', key: 'displayInMenu' }
  ]);

  useEffect(() => {
    const fetchActivities = async () => {
      const response = await fetch('https://us-central1-curacao-tours.cloudfunctions.net/fetchTrekksoftActivities');
      if (response.ok) {
        const data = await response.json();
        setActivities(data);
      } else {
        console.error('Network response was not ok');
      }
    };

    fetchActivities();
  }, []);

  useEffect(() => {
    const fetchSavedSelections = async () => {
      const selectionsSnapshot = await getDocs(collection(db, 'activitySelections'));
      const fetchedSelections = {};
      selectionsSnapshot.forEach(doc => {
        fetchedSelections[doc.id] = doc.data();
      });
      setActivitySelections(fetchedSelections);
    };

    if (activities.length > 0) {
      fetchSavedSelections();
    }
  }, [activities]);

  useEffect(() => {
    const fetchRewrittenDescriptions = async () => {
      const rewrittenDocs = await getDocs(collection(db, 'rewrittenTourDescriptions'));
      const descriptions = {};
      rewrittenDocs.forEach(doc => {
        descriptions[doc.id] = doc.data();
      });
      setRewrittenDescriptions(descriptions);
    };

    fetchRewrittenDescriptions();
  }, []);

  const handleRewriteDescription = async (activity) => {
    setSelectedActivity(activity);
    setOpenRewriteDialog(true);
  };

  const handleCloseRewriteDialog = () => {
    setOpenRewriteDialog(false);
    setSelectedActivity(null);
    setAdditionalInfo('');
  };

  const handleSubmitRewrite = async () => {
    const rewriteDescription = httpsCallable(functions, 'rewriteTourDescriptions');
    try {
      const activity = selectedActivity;
      const priceInfo = activity.fromPrice ? `${activity.fromPrice.amount} ${activity.fromPrice.currency}` : 'Price not available';
      const combinedContent = `Title: ${activity.title}\nShort Title: ${activity.titleShort}\nPrice: ${priceInfo}\nDescription: ${activity.description}\nShort Description: ${activity.descriptionShort || ''}\nAdditional Information: ${additionalInfo}`.replace(/<[^>]+>/g, '');

      const requestData = {
        activityId: activity.id,
        messages: [{ role: "user", content: combinedContent }]
      };

      // Send the request and wait for the response
      await rewriteDescription(requestData);
      
      // Display thank you message
      toast.success('Thank you for submitting the rewrite request!');
      handleCloseRewriteDialog();
    } catch (error) {
      console.error('Error submitting rewrite request:', error);
      // Check if it's the specific "Response is missing data field" error
      if (error.message.includes('Response is missing data field')) {
        // This error is expected, so we'll treat it as a success
        toast.success('Thank you for submitting the rewrite request!');
        handleCloseRewriteDialog();
      } else {
        // For other errors, show an error message
        toast.error('Failed to submit rewrite request. Please try again.');
      }
    }
  };

  const handleSelectActivity = (activityId, category) => {
    setActivitySelections(prev => ({
      ...prev,
      [activityId]: {
        ...prev[activityId],
        [category]: !prev[activityId]?.[category]
      }
    }));
  };

  const handleSaveSelections = async () => {
    const activityDetailsCollection = collection(db, 'activityDetails');
    const activitySelectionsCollection = collection(db, 'activitySelections');
  
    try {
      const savePromises = [];
  
      for (const [activityId, selection] of Object.entries(activitySelections)) {
        const activity = activities.find(a => a.id.toString() === activityId);
        if (activity) {
          // Prepare activity data with image URLs
          const activityData = {
            ...activity,
            headerImage: activity.images.header?.M || '', // Fallback to empty string if undefined
            teaserImage: activity.images.teaser?.M || '', // Fallback to empty string if undefined
            galleryImages: activity.images.gallery?.map(img => img.M) || [], // Fallback to empty array if undefined
            originalPrice: activity.fromPrice // Include the original price directly
          };
  
          // Save activity details with images
          const activityDetailsDoc = doc(activityDetailsCollection, activityId);
          savePromises.push(setDoc(activityDetailsDoc, activityData));
  
          // Save selection state
          const selectionDoc = doc(activitySelectionsCollection, activityId);
          savePromises.push(setDoc(selectionDoc, selection));
        }
      }
  
      await Promise.all(savePromises);
      toast.success('Website updated!');
    } catch (error) {
      console.error('Error saving activity details:', error);
      toast.error('Failed to update the website.');
    }
  };

  const handleUpdateActivityDetails = async () => {
    const response = await fetch('https://us-central1-curacao-tours.cloudfunctions.net/fetchTrekksoftActivities');
    if (response.ok) {
      const data = await response.json();
      const activityDetailsCollection = collection(db, 'activityDetails');
      const savePromises = data.map(activity => {
        const activityData = {
          ...activity,
          headerImage: activity.images.header?.M || '',
          teaserImage: activity.images.teaser?.M || '',
          galleryImages: activity.images.gallery?.map(img => img.M) || [],
          originalPrice: activity.fromPrice
        };
        const activityDetailsDoc = doc(activityDetailsCollection, activity.id.toString());
        return setDoc(activityDetailsDoc, activityData);
      });
      try {
        await Promise.all(savePromises);
        toast.success('Activity details updated!');
      } catch (error) {
        console.error('Error updating activity details:', error);
        toast.error('Failed to update activity details.');
      }
    } else {
      console.error('Network response was not ok');
    }
  };

  const handleEditActivity = async (activityId) => {
    const docRef = doc(db, 'rewrittenTourDescriptions', activityId.toString());
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const data = docSnap.data();
      
      // Handle included field that might be a string
      if (typeof data.included === 'string') {
        data.included = data.included.split(',').map(item => item.trim());
      }
      
      setEditingActivity(activityId);
      setEditedData(data);
      setEditTabValue(0);
    } else {
      // If no rewritten description exists, create a new one with default fields
      const defaultData = {
        shortTitle: '',
        shortDescription: '',
        description: '',
        duration: '',
        price: '',
        days: '',
        activityLevel: '',
        departureTime: '',
        highlights: [],
        travelerTips: [],
        requirements: [],
        included: []
      };
      setEditingActivity(activityId);
      setEditedData(defaultData);
      setEditTabValue(0);
      toast.success('Creating new tour description.');
    }
  };

  const handleSaveEdit = async () => {
    try {
      const docRef = doc(db, 'rewrittenTourDescriptions', editingActivity.toString());
      await setDoc(docRef, editedData);
      
      // Update the local state
      setRewrittenDescriptions(prev => ({
        ...prev,
        [editingActivity]: editedData
      }));
      
      toast.success('Tour details updated successfully!');
      setEditingActivity(null);
    } catch (error) {
      console.error('Error updating tour details:', error);
      toast.error('Failed to update tour details.');
    }
  };

  const handleInputChange = (field, value) => {
    // For included field, ensure we always have an array
    if (field === 'included' && typeof value === 'string') {
      value = value.split(',').map(item => item.trim());
    }
    
    setEditedData(prev => ({ ...prev, [field]: value }));
  };

  const handleEditTabChange = (event, newValue) => {
    setEditTabValue(newValue);
  };

  const handleAddListItem = (field) => {
    if (newListItem.trim()) {
      // Ensure the field is an array before trying to spread it
      const currentItems = Array.isArray(editedData[field]) ? editedData[field] : [];
      
      setEditedData(prev => ({
        ...prev,
        [field]: [...currentItems, newListItem.trim()]
      }));
      setNewListItem('');
    }
  };

  const handleRemoveListItem = (field, index) => {
    setEditedData(prev => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index)
    }));
  };

  // Function to check if a tour is selected for display
  const isTourSelected = (activityId) => {
    if (!activitySelections[activityId]) return false;
    
    // Check if any display option is true for this activity
    return displayOptions.some(option => 
      activitySelections[activityId][option.key] === true
    );
  };

  // Sort activities to show selected ones first
  const getSortedActivities = () => {
    if (!activities || activities.length === 0) return [];
    
    return [...activities].sort((a, b) => {
      const aIsSelected = isTourSelected(a.id);
      const bIsSelected = isTourSelected(b.id);
      
      if (aIsSelected && !bIsSelected) return -1; // a comes first
      if (!aIsSelected && bIsSelected) return 1;  // b comes first
      return 0; // keep original order
    });
  };

  return (
    <Box sx={{ p: 3, maxWidth: '1600px', margin: '0 auto' }}>
      <Card elevation={3}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
            <Typography variant="h4" sx={{ fontWeight: 500, color: 'primary.main' }}>
              Activity Manager
            </Typography>
            <Box>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleUpdateActivityDetails} 
                sx={{ mr: 2 }}
                startIcon={<RefreshIcon />}
              >
                Update Activities
              </Button>
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={handleSaveSelections}
                startIcon={<SaveIcon />}
              >
                Save Changes
              </Button>
            </Box>
          </Box>

          <TableContainer 
            component={Paper} 
            sx={{ 
              mb: 3,
              boxShadow: 2,
              borderRadius: 2,
              '& .MuiTableCell-head': {
                backgroundColor: 'primary.main',
                color: 'white',
                fontWeight: 'bold'
              }
            }}
          >
            <Table aria-label="activities table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ minWidth: 250 }}>Activity Details</TableCell>
                  <TableCell align="center" sx={{ minWidth: 120 }}>Category</TableCell>
                  <TableCell align="center" sx={{ minWidth: 100 }}>Price</TableCell>
                  <TableCell align="center" sx={{ minWidth: 100 }}>Images</TableCell>
                  <TableCell align="center" sx={{ minWidth: 200 }}>Display Options</TableCell>
                  <TableCell align="center" sx={{ minWidth: 150 }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getSortedActivities().map((activity) => {
                  const isSelected = isTourSelected(activity.id);
                  return (
                    <TableRow 
                      key={activity.id}
                      sx={{ 
                        backgroundColor: isSelected ? 'rgba(0, 200, 200, 0.05)' : 'inherit',
                        '&:hover': { 
                          backgroundColor: isSelected ? 'rgba(0, 200, 200, 0.1)' : 'rgba(0, 0, 0, 0.04)',
                          transition: 'background-color 0.2s ease'
                        }
                      }}
                    >
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {isSelected && (
                            <StarIcon 
                              sx={{ 
                                color: 'var(--turquoise-color, #00CED1)', 
                                mr: 1, 
                                fontSize: '1.2rem' 
                              }} 
                            />
                          )}
                          <Box>
                            <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 0.5 }}>
                              {activity.title}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              ID: {activity.id}
                            </Typography>
                            {rewrittenDescriptions[activity.id] && (
                              <Chip 
                                size="small" 
                                label="Custom Data" 
                                color="success" 
                                sx={{ ml: 1, height: 20 }} 
                              />
                            )}
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Chip 
                          label={activity.category?.name || 'No Category'} 
                          size="small"
                          sx={{ 
                            backgroundColor: 'primary.light',
                            color: 'white'
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          {activity.fromPrice ? 
                            `${activity.fromPrice.amount} ${activity.fromPrice.currency}` : 
                            'Price not set'
                          }
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                          {activity.images.header && (
                            <Tooltip title="Header Image">
                              <Avatar
                                src={activity.images.header.M}
                                alt="Header"
                                variant="rounded"
                                sx={{ width: 40, height: 40 }}
                              />
                            </Tooltip>
                          )}
                          {activity.images.teaser && (
                            <Tooltip title="Teaser Image">
                              <Avatar
                                src={activity.images.teaser.M}
                                alt="Teaser"
                                variant="rounded"
                                sx={{ width: 40, height: 40 }}
                              />
                            </Tooltip>
                          )}
                          {activity.images.gallery && (
                            <Tooltip title={`${activity.images.gallery.length} Gallery Images`}>
                              <Box sx={{ position: 'relative' }}>
                                <Avatar
                                  src={activity.images.gallery[0]?.M}
                                  alt="Gallery"
                                  variant="rounded"
                                  sx={{ width: 40, height: 40 }}
                                />
                                {activity.images.gallery.length > 1 && (
                                  <Box
                                    sx={{
                                      position: 'absolute',
                                      top: -8,
                                      right: -8,
                                      backgroundColor: 'primary.main',
                                      color: 'white',
                                      borderRadius: '50%',
                                      width: 20,
                                      height: 20,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      fontSize: '0.75rem'
                                    }}
                                  >
                                    +{activity.images.gallery.length - 1}
                                  </Box>
                                )}
                              </Box>
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
                          {displayOptions.map(({ label, key }) => (
                            <Grid item xs={6} key={key}>
                              <FormControlLabel
                                control={
                                  <Checkbox 
                                    checked={!!activitySelections[activity.id]?.[key]}
                                    onChange={() => handleSelectActivity(activity.id, key)}
                                    size="small"
                                  />
                                }
                                label={<Typography variant="caption">{label}</Typography>}
                                sx={{ margin: 0 }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </TableCell>
                      <TableCell align="center">
                        <Stack direction="row" spacing={1} justifyContent="center">
                          <Tooltip title="Rewrite Description">
                            <IconButton
                              color="primary"
                              onClick={() => handleRewriteDescription(activity)}
                              size="small"
                            >
                              <AutoFixHighIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit Tour Details">
                            <IconButton
                              color="secondary"
                              onClick={() => handleEditActivity(activity.id)}
                              size="small"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      {/* Enhanced Edit Dialog */}
      <Dialog 
        open={!!editingActivity} 
        onClose={() => setEditingActivity(null)} 
        maxWidth="lg" 
        fullWidth
        PaperProps={{
          sx: { borderRadius: 2 }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: 1, 
          borderColor: 'divider',
          backgroundColor: 'primary.main',
          color: 'white'
        }}>
          Edit Tour Details
        </DialogTitle>
        <DialogContent sx={{ pt: 2, pb: 1 }}>
          <Tabs 
            value={editTabValue} 
            onChange={handleEditTabChange} 
            variant="scrollable"
            scrollButtons="auto"
            sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}
          >
            <Tab label="Basic Information" />
            <Tab label="Description" />
            <Tab label="Highlights" />
            <Tab label="Inclusions" />
            <Tab label="Requirements" />
            <Tab label="Traveler Tips" />
          </Tabs>

          {/* Basic Information Tab */}
          {editTabValue === 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Short Title"
                  value={editedData.shortTitle || ''}
                  onChange={(e) => handleInputChange('shortTitle', e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  helperText="The main title displayed on the tour page"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Price"
                  value={editedData.price || ''}
                  onChange={(e) => handleInputChange('price', e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  helperText="Price displayed on the tour page"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Duration"
                  value={editedData.duration || ''}
                  onChange={(e) => handleInputChange('duration', e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  helperText="e.g. '2 hours', '3 hours 30 minutes'"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Days"
                  value={editedData.days || ''}
                  onChange={(e) => handleInputChange('days', e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  helperText="e.g. 'Monday, Wednesday, Friday'"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Activity Level"
                  value={editedData.activityLevel || ''}
                  onChange={(e) => handleInputChange('activityLevel', e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  helperText="e.g. 'Easy', 'Moderate', 'Challenging'"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Departure Time"
                  value={editedData.departureTime || ''}
                  onChange={(e) => handleInputChange('departureTime', e.target.value)}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  helperText="e.g. '9 am', '2:30 pm'"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Short Description"
                  value={editedData.shortDescription || ''}
                  onChange={(e) => handleInputChange('shortDescription', e.target.value)}
                  fullWidth
                  multiline
                  rows={3}
                  margin="normal"
                  variant="outlined"
                  helperText="Brief summary shown in the header"
                />
              </Grid>
            </Grid>
          )}

          {/* Description Tab */}
          {editTabValue === 1 && (
            <TextField
              label="Full Description"
              value={editedData.description || ''}
              onChange={(e) => handleInputChange('description', e.target.value)}
              fullWidth
              multiline
              rows={15}
              margin="normal"
              variant="outlined"
              helperText="Detailed description of the tour"
            />
          )}

          {/* Highlights Tab */}
          {editTabValue === 2 && (
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Tour Highlights
              </Typography>
              <List dense>
                {(editedData.highlights || []).map((item, index) => (
                  <ListItem 
                    key={index}
                    secondaryAction={
                      <IconButton edge="end" onClick={() => handleRemoveListItem('highlights', index)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
              <Box sx={{ display: 'flex', mt: 2 }}>
                <TextField
                  label="New Highlight"
                  value={newListItem}
                  onChange={(e) => setNewListItem(e.target.value)}
                  fullWidth
                  variant="outlined"
                  size="small"
                />
                <Button 
                  variant="contained" 
                  startIcon={<AddIcon />} 
                  onClick={() => handleAddListItem('highlights')}
                  sx={{ ml: 1 }}
                >
                  Add
                </Button>
              </Box>
            </Box>
          )}

          {/* Inclusions Tab */}
          {editTabValue === 3 && (
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                What's Included
              </Typography>
              <List dense>
                {(Array.isArray(editedData.included) ? editedData.included : 
                  (typeof editedData.included === 'string' ? editedData.included.split(',').map(item => item.trim()) : [])
                ).map((item, index) => (
                  <ListItem 
                    key={index}
                    secondaryAction={
                      <IconButton edge="end" onClick={() => handleRemoveListItem('included', index)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
              <Box sx={{ display: 'flex', mt: 2 }}>
                <TextField
                  label="New Inclusion"
                  value={newListItem}
                  onChange={(e) => setNewListItem(e.target.value)}
                  fullWidth
                  variant="outlined"
                  size="small"
                />
                <Button 
                  variant="contained" 
                  startIcon={<AddIcon />} 
                  onClick={() => handleAddListItem('included')}
                  sx={{ ml: 1 }}
                >
                  Add
                </Button>
              </Box>
            </Box>
          )}

          {/* Requirements Tab */}
          {editTabValue === 4 && (
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Requirements
              </Typography>
              <List dense>
                {(editedData.requirements || []).map((item, index) => (
                  <ListItem 
                    key={index}
                    secondaryAction={
                      <IconButton edge="end" onClick={() => handleRemoveListItem('requirements', index)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
              <Box sx={{ display: 'flex', mt: 2 }}>
                <TextField
                  label="New Requirement"
                  value={newListItem}
                  onChange={(e) => setNewListItem(e.target.value)}
                  fullWidth
                  variant="outlined"
                  size="small"
                />
                <Button 
                  variant="contained" 
                  startIcon={<AddIcon />} 
                  onClick={() => handleAddListItem('requirements')}
                  sx={{ ml: 1 }}
                >
                  Add
                </Button>
              </Box>
            </Box>
          )}

          {/* Traveler Tips Tab */}
          {editTabValue === 5 && (
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Traveler Tips
              </Typography>
              <List dense>
                {(editedData.travelerTips || []).map((item, index) => (
                  <ListItem 
                    key={index}
                    secondaryAction={
                      <IconButton edge="end" onClick={() => handleRemoveListItem('travelerTips', index)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
              <Box sx={{ display: 'flex', mt: 2 }}>
                <TextField
                  label="New Traveler Tip"
                  value={newListItem}
                  onChange={(e) => setNewListItem(e.target.value)}
                  fullWidth
                  variant="outlined"
                  size="small"
                />
                <Button 
                  variant="contained" 
                  startIcon={<AddIcon />} 
                  onClick={() => handleAddListItem('travelerTips')}
                  sx={{ ml: 1 }}
                >
                  Add
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ borderTop: 1, borderColor: 'divider', p: 2 }}>
          <Button onClick={() => setEditingActivity(null)} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSaveEdit} variant="contained" color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Rewrite Dialog */}
      <Dialog 
        open={openRewriteDialog} 
        onClose={handleCloseRewriteDialog}
        PaperProps={{
          sx: { borderRadius: 2 }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: 1, 
          borderColor: 'divider',
          backgroundColor: 'primary.main',
          color: 'white'
        }}>
          Additional Information for AI Rewrite
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <TextField
            autoFocus
            margin="dense"
            id="additionalInfo"
            label="Important Information"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            variant="outlined"
            placeholder="Add any specific requirements or information for the AI rewrite..."
          />
        </DialogContent>
        <DialogActions sx={{ borderTop: 1, borderColor: 'divider', p: 2 }}>
          <Button onClick={handleCloseRewriteDialog} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmitRewrite} variant="contained" color="primary">
            Submit for Rewrite
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ActivityManager;