// src/hooks/TrekksoftBookingWidget.js
import React, { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

const TrekksoftBookingWidget = ({ tourId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    // Don't create widget on mobile
    if (isMobile) return;

    const script = document.createElement('script');
    script.src = "https://bw.trekksoft.com/loader.js";
    script.async = true;
    script.dataset.owner = "trekksoft";
    script.dataset.type = "activity-booking";
    script.dataset.id = tourId;
    script.dataset.clientId = "BOOKIE-5badffc8663be9c7621fe18cf";
    script.dataset.token = "f644f78038045737f3c2da77ea17bf4521b12a1ccdc0aded";
    script.dataset.element = "embed";
    script.dataset.width = "100%";
    script.dataset.height = "900px"; // Increased height from 800px to 900px
    script.dataset.forceHeight = "true";
    script.dataset.layout = "calendar";
    script.dataset.showcalendar = "true";
    script.dataset.showprice = "true";
    script.dataset.currency = "USD";
    script.dataset.lang = "en";
    script.dataset.theme = "light";
    script.dataset.defaultView = "calendar";
    script.dataset.showAvailability = "true";
    script.dataset.calendarLayout = "month";
    script.dataset.responsive = "true";
    script.dataset.compact = "false";
    script.dataset.showHeader = "true";

    // Create a container for the widget if it doesn't exist
    let widgetDiv = document.getElementById('trekksoft-booking-widget');
    if (!widgetDiv) {
      widgetDiv = document.createElement('div');
      widgetDiv.id = 'trekksoft-booking-widget';
      document.body.appendChild(widgetDiv);
    }

    // Clear any existing content
    widgetDiv.innerHTML = '';
    widgetDiv.appendChild(script);

    return () => {
      if (widgetDiv && widgetDiv.contains(script)) {
        widgetDiv.removeChild(script);
      }
    };
  }, [tourId, isMobile]);

  // Don't render anything on mobile
  if (isMobile) return null;

  return (
    <div 
      id="trekksoft-booking-widget" 
      style={{ 
        width: '100%', 
        height: '900px', // Increased height from 800px to 900px
        maxWidth: '100%', // Changed from 700px to 100% to use full container width
        margin: '0 auto',
        border: 'none',
        borderRadius: '8px',
        overflow: 'hidden',
        padding: '0' // Reduced padding from 8px to 0 to maximize space
      }}
    />
  );
};

export default TrekksoftBookingWidget;
