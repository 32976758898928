import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Box, 
  Container, 
  Typography, 
  Button,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { motion } from 'framer-motion';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CategoryTourCarousel from '../../components/CategoryTourCarousel';
import { useOptimizedTourContext } from '../../context/OptimizedTourContext';

const CategoryPage = () => {
  const { category } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { getToursByIds, isLoading } = useOptimizedTourContext();
  const [headerImage, setHeaderImage] = useState('');
  const imageSelected = useRef(false);

  // Scroll to top when page loads
  useEffect(() => {
    window.scrollTo(0, 0);
    // Reset image selection flag when category changes
    imageSelected.current = false;
    setHeaderImage('');
  }, [category]); // Scroll to top when category changes too

  // Category data with tour IDs, titles, descriptions, and hero images
  const categoryData = {
    'island-tours': {
      title: 'Island Tours',
      description: 'Explore the natural beauty and cultural heritage of Curaçao with our guided island tours. From historic landmarks to hidden gems, experience the best of what this Caribbean paradise has to offer.',
      tourIds: [
        '296606', '301652', '304151', '326864', '326870', '327478'
      ],
      fallbackImage: 'https://images.unsplash.com/photo-1589197331516-4d84b75a5228?q=80&w=2071&auto=format&fit=crop'
    },
    'water-tours': {
      title: 'Water Tours',
      description: 'Dive into the crystal-clear waters of Curaçao with our exciting water tours. From snorkeling and diving to boat trips and water sports, experience the vibrant marine life and stunning underwater landscapes.',
      tourIds: [
        '293823', '296605', '311035', '317387'
      ],
      fallbackImage: 'https://images.unsplash.com/photo-1505118380757-91f5f5632de0?q=80&w=2581&auto=format&fit=crop'
    }
  };

  const currentCategory = categoryData[category];

  // Get a random tour image for the header - only once per category
  useEffect(() => {
    // Skip if an image has already been selected for this category or if data is still loading
    if (imageSelected.current || !currentCategory || isLoading) {
      return;
    }

    const tours = getToursByIds(currentCategory.tourIds);
    const allImages = [];
    
    // Collect all images from all tours in this category
    tours.forEach(tour => {
      if (tour) {
        // Add header image if available
        if (tour.images && tour.images.header && tour.images.header.M) {
          allImages.push(tour.images.header.M);
        }
        
        // Add gallery images if available
        if (tour.images && tour.images.gallery && tour.images.gallery.length > 0) {
          allImages.push(...tour.images.gallery);
        }
      }
    });
    
    // If we have images, randomly select one for the header
    if (allImages.length > 0) {
      const randomIndex = Math.floor(Math.random() * allImages.length);
      setHeaderImage(allImages[randomIndex]);
    } else {
      // Use fallback image
      setHeaderImage(currentCategory.fallbackImage);
    }
    
    // Mark that we've selected an image for this category
    imageSelected.current = true;
    
  }, [category, currentCategory, getToursByIds, isLoading]);

  // Redirect to all-tours page if category doesn't exist
  useEffect(() => {
    if (!isLoading && !currentCategory) {
      navigate('/all-tours');
    }
  }, [category, navigate, isLoading, currentCategory]);

  if (isLoading || !currentCategory) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '100vh' 
      }}>
        <Typography variant="h5">Loading...</Typography>
      </Box>
    );
  }

  const { title, description, tourIds, fallbackImage } = currentCategory;
  
  // Use fallback image if header image hasn't been set yet
  const backgroundImage = headerImage || fallbackImage;

  return (
    <Box component="main">
      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          height: { xs: '60vh', sm: '60vh', md: '70vh' },
          width: '100%',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* Background Image */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          }}
        />

        {/* Hero Content */}
        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Box
              sx={{
                color: 'white',
                textAlign: 'center',
                position: 'relative',
                px: { xs: 1, sm: 2, md: 4 },
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: '2rem', sm: '2.5rem', md: '4.5rem' },
                  fontWeight: 'bold',
                  mb: { xs: 1, sm: 2 },
                  textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                  lineHeight: { xs: 1.2, md: 1.3 },
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  fontSize: { xs: '1rem', sm: '1.25rem', md: '2rem' },
                  mb: { xs: 2, sm: 3, md: 4 },
                  maxWidth: '800px',
                  mx: 'auto',
                  textShadow: '1px 1px 3px rgba(0,0,0,0.5)',
                  lineHeight: { xs: 1.4, md: 1.6 },
                  display: { xs: 'none', sm: 'block' }
                }}
              >
                {description}
              </Typography>
              <Button
                variant="contained"
                size={isMobile ? "medium" : "large"}
                onClick={() => navigate('/all-tours')}
                startIcon={<ArrowBackIcon />}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: 'white',
                  px: { xs: 2, sm: 3, md: 4 },
                  py: { xs: 1, sm: 1.5 },
                  borderRadius: '30px',
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
              >
                View All Tours
              </Button>
            </Box>
          </motion.div>
        </Container>
      </Box>

      {/* Tours Section */}
      <Box sx={{ py: { xs: 4, sm: 5, md: 10 }, backgroundColor: '#f9f9f9' }}>
        <Container maxWidth="xl" sx={{ px: { xs: 1, sm: 2, md: 3 } }}>
          <CategoryTourCarousel 
            tourIds={tourIds} 
            title={title} 
            description={description} 
          />
        </Container>
      </Box>
    </Box>
  );
};

export default CategoryPage; 