import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, getDoc, getDocs, collection, query, where } from 'firebase/firestore';
import { db } from '../config/firebase/firebase';
import tourImages from '../data/tour-images.json';

const TourContext = createContext();

export const useTourContext = () => {
  const context = useContext(TourContext);
  if (!context) {
    throw new Error('useTourContext must be used within a TourProvider');
  }
  return context;
};

export const TourProvider = ({ children }) => {
  const [tourDetails, setTourDetails] = useState({
    headerImage: '',
    shortTitle: '',
    shortDescription: '',
    duration: '',
    price: '',
    highlights: [],
    travelerTips: [],
    requirements: [],
    included: [],
    days: '',
    description: ''
  });
  const [allTours, setAllTours] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [recommendedTours, setRecommendedTours] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch all tours that should be displayed on homepage
  const fetchAllTours = async () => {
    setLoading(true);
    try {
      // First, get all activities that have homePage set to true
      const activitySelectionsSnapshot = await getDocs(collection(db, 'activitySelections'));
      const homePageActivityIds = activitySelectionsSnapshot.docs
        .filter(doc => doc.data().homePage === true)
        .map(doc => doc.id);

      if (homePageActivityIds.length === 0) {
        setAllTours([]);
        return;
      }

      // Then fetch the full tour details for each of these activities
      const tourPromises = homePageActivityIds.map(async (tourId) => {
        const tourDoc = await getDoc(doc(db, 'rewrittenTourDescriptions', tourId));
        if (tourDoc.exists()) {
          return {
            id: tourDoc.id,
            ...tourDoc.data(),
            images: {
              header: {
                M: tourImages[tourDoc.id]?.header || ''
              },
              gallery: tourImages[tourDoc.id]?.gallery || []
            }
          };
        }
        return null;
      });

      const toursData = await Promise.all(tourPromises);
      // Filter out any null values from tours that might not exist
      setAllTours(toursData.filter(tour => tour !== null));
    } catch (err) {
      console.error('Error fetching homepage tours:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch single tour details
  const fetchTourDetails = async (tourId) => {
    setLoading(true);
    setError(null);
    try {
      const tourRef = doc(db, 'rewrittenTourDescriptions', tourId);
      const tourSnap = await getDoc(tourRef);

      if (tourSnap.exists()) {
        const data = tourSnap.data();
        let includedData = data.included || [];
        if (typeof includedData === 'string') {
          includedData = includedData.split(',').map(item => item.trim());
        }

        setTourDetails({
          headerImage: tourImages[tourId]?.header || '',
          shortTitle: data.shortTitle || '',
          shortDescription: data.shortDescription || '',
          duration: data.duration || '',
          price: data.price || '',
          highlights: data.highlights || [],
          travelerTips: data.travelerTips || [],
          requirements: data.requirements || [],
          included: includedData,
          days: data.days || '',
          description: data.description || ''
        });

        setGalleryImages(tourImages[tourId]?.gallery || []);
      } else {
        setError('Tour not found');
      }

      // Fetch recommended tours
      const recommendedSnap = await getDocs(collection(db, 'recommendedTours'));
      const recommendedData = recommendedSnap.docs.map(doc => doc.data());
      setRecommendedTours(recommendedData);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching tour details:', err);
    } finally {
      setLoading(false);
    }
  };

  // Fetch all tours on mount
  useEffect(() => {
    fetchAllTours();
  }, []);

  const value = {
    tourDetails,
    allTours,
    galleryImages,
    recommendedTours,
    loading,
    error,
    fetchTourDetails,
    fetchAllTours
  };

  return <TourContext.Provider value={value}>{children}</TourContext.Provider>;
};

export default TourContext; 