import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, useTheme, useMediaQuery } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { motion, useAnimation } from 'framer-motion';
import TourCardV2 from './TourCardV2';
import { useOptimizedTourContext } from '../context/OptimizedTourContext';

const CategoryTourCarousel = ({ tourIds, title, description }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const controls = useAnimation();
  const [currentPage, setCurrentPage] = useState(1);
  
  const { getToursByIds } = useOptimizedTourContext();
  const categoryTours = getToursByIds(tourIds || []);

  const CARD_WIDTH = isMobile ? 300 : 400; // Width of each card
  const CARD_GAP = isMobile ? 16 : 32; // Gap between cards
  const VISIBLE_CARDS = isMobile ? 1 : 3; // Number of visible cards

  // Create array with duplicated items for infinite scroll
  const extendedTours = [...categoryTours, ...categoryTours, ...categoryTours];
  const totalPages = Math.ceil(categoryTours.length / VISIBLE_CARDS);

  const handleNext = async () => {
    const newPage = currentPage + 1;
    setCurrentPage(newPage);
    
    await controls.start({
      x: -newPage * (VISIBLE_CARDS * (CARD_WIDTH + CARD_GAP)),
      transition: {
        type: "tween",
        duration: 0.5,
        ease: "easeInOut"
      }
    });

    // Reset to first page when reaching the end of duplicated set
    if (newPage >= totalPages * 2) {
      setCurrentPage(totalPages);
      await controls.start({
        x: -totalPages * (VISIBLE_CARDS * (CARD_WIDTH + CARD_GAP)),
        transition: {
          duration: 0
        }
      });
    }
  };

  const handlePrev = async () => {
    const newPage = currentPage - 1;
    setCurrentPage(newPage);

    await controls.start({
      x: -newPage * (VISIBLE_CARDS * (CARD_WIDTH + CARD_GAP)),
      transition: {
        type: "tween",
        duration: 0.5,
        ease: "easeInOut"
      }
    });

    // Reset to last page when reaching the start of duplicated set
    if (newPage <= 0) {
      setCurrentPage(totalPages);
      await controls.start({
        x: -totalPages * (VISIBLE_CARDS * (CARD_WIDTH + CARD_GAP)),
        transition: {
          duration: 0
        }
      });
    }
  };

  // Set initial position
  useEffect(() => {
    if (categoryTours.length > 0) {
      controls.start({
        x: -(VISIBLE_CARDS * (CARD_WIDTH + CARD_GAP)),
        transition: { duration: 0 }
      });
    }
  }, [categoryTours.length, controls, CARD_GAP, CARD_WIDTH, VISIBLE_CARDS]);

  if (!categoryTours.length) {
    return null;
  }

  return (
    <Box sx={{ px: { xs: 2, sm: 4 } }}>
      {/* Title Section */}
      {title && (
        <Typography 
          variant="h2" 
          sx={{ 
            mb: 2,
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
            fontWeight: 'bold',
            textAlign: 'center'
          }}
        >
          <span style={{ color: '#FFD700' }}>{title.split(' ')[0]}</span>{' '}
          <span style={{ color: '#1A3D8F' }}>{title.split(' ').slice(1).join(' ')}</span>
        </Typography>
      )}
      
      {description && (
        <Typography 
          variant="h6" 
          sx={{ 
            maxWidth: '1000px', 
            mx: 'auto',
            color: 'text.secondary',
            px: 2,
            lineHeight: 1.6,
            textAlign: 'center',
            mb: 4,
            fontSize: { xs: '1rem', sm: '1.25rem' }
          }}
        >
          {description}
        </Typography>
      )}

      {/* Carousel Section with Navigation */}
      <Box sx={{ 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: { xs: '8px', sm: '32px' },
        margin: '32px 0',
        width: '100%'
      }}>
        {/* Left Arrow */}
        <IconButton
          onClick={handlePrev}
          sx={{
            bgcolor: 'white',
            width: { xs: 40, sm: 48 },
            height: { xs: 40, sm: 48 },
            boxShadow: 3,
            '&:hover': { 
              bgcolor: theme.palette.grey[100],
              transform: 'scale(1.1)',
            },
            transition: 'all 0.3s ease',
          }}
        >
          <ArrowBackIosNewIcon sx={{ fontSize: { xs: 20, sm: 24 } }} />
        </IconButton>

        {/* Cards Container */}
        <Box sx={{ 
          width: (CARD_WIDTH * VISIBLE_CARDS) + (CARD_GAP * (VISIBLE_CARDS - 1)),
          overflow: 'hidden',
        }}>
          <motion.div
            animate={controls}
            style={{
              display: 'flex',
              gap: CARD_GAP,
              width: 'fit-content',
            }}
          >
            {extendedTours.map((tour, index) => (
              <motion.div
                key={`${tour.id}-${index}`}
                style={{
                  flex: 'none',
                  width: CARD_WIDTH,
                }}
              >
                <TourCardV2 tour={tour} />
              </motion.div>
            ))}
          </motion.div>
        </Box>

        {/* Right Arrow */}
        <IconButton
          onClick={handleNext}
          sx={{
            bgcolor: 'white',
            width: { xs: 40, sm: 48 },
            height: { xs: 40, sm: 48 },
            boxShadow: 3,
            '&:hover': { 
              bgcolor: theme.palette.grey[100],
              transform: 'scale(1.1)',
            },
            transition: 'all 0.3s ease',
          }}
        >
          <ArrowForwardIosIcon sx={{ fontSize: { xs: 20, sm: 24 } }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CategoryTourCarousel; 