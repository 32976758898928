import React, { useState, useEffect, useRef } from 'react';
import {
  Typography, 
  Container, 
  Button, 
  Box, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Paper,
  Divider,
  Tab,
  Tabs,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Chip,
  Switch,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText
} from '@mui/material';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-hot-toast';
import { useTheme } from '@mui/material/styles';
import SignaturePad from 'react-signature-canvas';
import WaiverTemplatesTable from './WaiverTemplatesTable';
import WaiverRequests from './WaiverRequests';

function WaiversPage() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    tourId: '',
    content: {
      title: '',
      subtitle: '',
      sections: [
        {
          type: 'personal_info',
          fields: [
            { label: 'Passenger Name', type: 'text', required: true },
            { label: 'Vessel', type: 'text', required: true },
            { label: 'Cabin', type: 'text', required: false }
          ]
        },
        {
          type: 'notice',
          content: 'Please read carefully. This form releases us from liability.'
        },
        {
          type: 'terms',
          paragraphs: [
            {
              text: '',
              emphasis: 'normal'
            }
          ]
        },
        {
          type: 'age_verification',
          title: 'Age Verification',
          required: true,
          options: [
            { value: 'adult', label: 'I am 18 years of age or older' },
            { value: 'minor', label: 'I am under 18 years of age and accompanied by a parent/guardian' }
          ]
        },
        {
          type: 'acknowledgment',
          text: 'I HEREBY ACKNOWLEDGE THAT I HAVE READ THE WAIVER AND RELEASE, UNDERSTAND THE TERMS AND THEIR LEGAL EFFECT AND MY SIGNING CONSTITUTES RELEASE OF VALUABLE RIGHTS.',
          emphasis: 'strong'
        },
        {
          type: 'signature_section',
          fields: [
            { label: 'Date', type: 'date', required: true },
            { label: 'Signature of Participant or Parent/Guardian', type: 'signature', required: true }
          ]
        }
      ]
    },
    language: 'en',
    type: 'standard',
    company: '',
    version: '1.0',
    isActive: true,
    lastModified: new Date()
  });

  const handleCreateTemplate = () => {
    setEditingTemplate(null);
    setFormData({
      name: '',
      tourId: '',
      content: {
        title: '',
        subtitle: '',
        sections: [
          {
            type: 'personal_info',
            fields: [
              { label: 'Passenger Name', type: 'text', required: true },
              { label: 'Vessel', type: 'text', required: true },
              { label: 'Cabin', type: 'text', required: false }
            ]
          },
          {
            type: 'notice',
            content: 'Please read carefully. This form releases us from liability.'
          },
          {
            type: 'terms',
            paragraphs: [
              {
                text: '',
                emphasis: 'normal'
              }
            ]
          },
          {
            type: 'age_verification',
            title: 'Age Verification',
            required: true,
            options: [
              { value: 'adult', label: 'I am 18 years of age or older' },
              { value: 'minor', label: 'I am under 18 years of age and accompanied by a parent/guardian' }
            ]
          },
          {
            type: 'acknowledgment',
            text: 'I HEREBY ACKNOWLEDGE THAT I HAVE READ THE WAIVER AND RELEASE, UNDERSTAND THE TERMS AND THEIR LEGAL EFFECT AND MY SIGNING CONSTITUTES RELEASE OF VALUABLE RIGHTS.',
            emphasis: 'strong'
          },
          {
            type: 'signature_section',
            fields: [
              { label: 'Date', type: 'date', required: true },
              { label: 'Signature of Participant or Parent/Guardian', type: 'signature', required: true }
            ]
          }
        ]
      },
      language: 'en',
      type: 'standard',
      company: '',
      version: '1.0',
      isActive: true,
      lastModified: new Date()
    });
    setOpen(true);
    setActiveTab(0);
  };

  const handleEditTemplate = (template) => {
    setEditingTemplate(template);
    setFormData({
      ...template,
      fields: template.fields || [
        { label: 'Passenger Name', type: 'text', required: true },
        { label: 'Date', type: 'date', required: true },
        { label: 'Signature', type: 'signature', required: true }
      ]
    });
    setOpen(true);
    setActiveTab(0);
  };

  const handlePreviewTemplate = (template) => {
    setEditingTemplate(template);
    setPreviewOpen(true);
  };

  const handleDuplicateTemplate = (template) => {
    const duplicatedTemplate = {
      ...template,
      name: `${template.name} (Copy)`,
      version: '1.0',
      lastModified: new Date()
    };
    delete duplicatedTemplate.id;
    setFormData(duplicatedTemplate);
    setEditingTemplate(null);
    setOpen(true);
  };

  const handleDeleteTemplate = async (templateId) => {
    if (window.confirm('Are you sure you want to delete this template?')) {
      try {
        await deleteDoc(doc(db, 'waiverTemplates', templateId));
        toast.success('Template deleted successfully');
      } catch (error) {
        console.error('Error deleting template:', error);
        toast.error('Failed to delete template');
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setEditingTemplate(null);
    setActiveTab(0);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingTemplate) {
        const templateRef = doc(db, 'waiverTemplates', editingTemplate.id);
        await updateDoc(templateRef, {
          ...formData,
          lastModified: new Date(),
        });
        toast.success('Template updated successfully');
      } else {
        await addDoc(collection(db, 'waiverTemplates'), {
          ...formData,
          lastModified: new Date(),
        });
        toast.success('Template created successfully');
      }
      handleClose();
    } catch (error) {
      console.error('Error saving template:', error);
      toast.error('Failed to save template');
    }
  };

  // Basic form handling - you'll need to expand this based on your form needs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4">Waivers Management</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setEditingTemplate(null);
            setFormData({
              name: '',
              tourId: '',
              content: {
                title: '',
                subtitle: '',
                sections: [
                  {
                    type: 'personal_info',
                    fields: [
                      { label: 'Passenger Name', type: 'text', required: true },
                      { label: 'Vessel', type: 'text', required: true },
                      { label: 'Cabin', type: 'text', required: false }
                    ]
                  },
                  {
                    type: 'notice',
                    content: 'Please read carefully. This form releases us from liability.'
                  },
                  {
                    type: 'terms',
                    paragraphs: [
                      {
                        text: '',
                        emphasis: 'normal'
                      }
                    ]
                  },
                  {
                    type: 'age_verification',
                    title: 'Age Verification',
                    required: true,
                    options: [
                      { value: 'adult', label: 'I am 18 years of age or older' },
                      { value: 'minor', label: 'I am under 18 years of age and accompanied by a parent/guardian' }
                    ]
                  },
                  {
                    type: 'acknowledgment',
                    text: 'I HEREBY ACKNOWLEDGE THAT I HAVE READ THE WAIVER AND RELEASE, UNDERSTAND THE TERMS AND THEIR LEGAL EFFECT AND MY SIGNING CONSTITUTES RELEASE OF VALUABLE RIGHTS.',
                    emphasis: 'strong'
                  },
                  {
                    type: 'signature_section',
                    fields: [
                      { label: 'Date', type: 'date', required: true },
                      { label: 'Signature of Participant or Parent/Guardian', type: 'signature', required: true }
                    ]
                  }
                ]
              },
              language: 'en',
              type: 'standard',
              company: '',
              version: '1.0',
              isActive: true,
              lastModified: new Date()
            });
            setOpen(true);
          }}
        >
          Create Template
        </Button>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="waiver management tabs">
          <Tab label="Templates" />
          <Tab label="Waiver Requests" />
        </Tabs>
      </Box>

      {activeTab === 0 && (
        <WaiverTemplatesTable
          onEdit={handleEditTemplate}
          onPreview={handlePreviewTemplate}
          onDuplicate={handleDuplicateTemplate}
          onDelete={handleDeleteTemplate}
        />
      )}

      {activeTab === 1 && (
        <WaiverRequests />
      )}

      {/* Edit/Create Dialog */}
      <Dialog 
        open={open} 
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {editingTemplate ? 'Edit Waiver Template' : 'Create New Waiver Template'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab label="Basic Info" />
              <Tab label="Content" />
              <Tab label="Display Settings" />
            </Tabs>
          </Box>

          {activeTab === 0 && (
            <Box sx={{ p: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Template Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Type</InputLabel>
                    <Select
                      name="type"
                      value={formData.type}
                      onChange={handleInputChange}
                      label="Type"
                    >
                      <MenuItem value="standard">Standard</MenuItem>
                      <MenuItem value="snorkeling">Snorkeling</MenuItem>
                      <MenuItem value="beach">Beach</MenuItem>
                      <MenuItem value="ebike">E-Bike</MenuItem>
                      <MenuItem value="catamaran">Catamaran</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Language</InputLabel>
                    <Select
                      name="language"
                      value={formData.language}
                      onChange={handleInputChange}
                      label="Language"
                    >
                      <MenuItem value="en">English</MenuItem>
                      <MenuItem value="es">Spanish</MenuItem>
                      <MenuItem value="nl">Dutch</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Company"
                    name="company"
                    value={formData.company}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Version"
                    name="version"
                    value={formData.version}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.isActive}
                        onChange={(e) => setFormData({...formData, isActive: e.target.checked})}
                        name="isActive"
                      />
                    }
                    label="Active"
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          {activeTab === 1 && (
            <Box sx={{ p: 2 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Title"
                    name="content.title"
                    value={formData.content.title}
                    onChange={(e) => setFormData({
                      ...formData,
                      content: {
                        ...formData.content,
                        title: e.target.value
                      }
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Subtitle"
                    name="content.subtitle"
                    value={formData.content.subtitle}
                    onChange={(e) => setFormData({
                      ...formData,
                      content: {
                        ...formData.content,
                        subtitle: e.target.value
                      }
                    })}
                  />
                </Grid>
                {/* Add more fields for content editing as needed */}
              </Grid>
            </Box>
          )}

          {activeTab === 2 && (
            <Box sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Display Settings
              </Typography>
              {/* Add display settings fields here */}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {editingTemplate ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Preview Dialog */}
      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            width: '100%',
            maxHeight: { xs: '90vh', sm: '85vh' },
            m: { xs: 1, sm: 2 }
          }
        }}
      >
        <DialogTitle sx={{ 
          fontSize: { xs: '1.2rem', sm: '1.5rem' },
          py: { xs: 1.5, sm: 2 }
        }}>
          Preview: {editingTemplate?.name}
        </DialogTitle>
        <DialogContent sx={{ p: { xs: 1, sm: 2, md: 3 } }}>
          <Paper 
            elevation={2} 
            sx={{ 
              p: { xs: 2, sm: 3, md: 4 }, 
              maxWidth: '100%', 
              mx: 'auto',
              backgroundColor: editingTemplate?.displaySettings?.colors?.background || 'grey.50',
              borderRadius: 2,
              border: `1px solid ${theme.palette.grey[300]}`,
              mb: 3,
              overflowX: 'hidden'
            }}
          >
            {/* Header */}
            <Box sx={{ mb: { xs: 2, sm: 3, md: 4 }, textAlign: 'center' }}>
              <Typography 
                variant="h4" 
                component="h1" 
                gutterBottom
                sx={{ 
                  color: theme.palette.primary.main,
                  fontWeight: 'bold',
                  fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2.125rem' }
                }}
              >
                {editingTemplate?.content.title}
              </Typography>
              <Typography 
                variant="h6" 
                component="h2"
                sx={{ 
                  color: theme.palette.secondary.main,
                  mb: 2,
                  fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' }
                }}
              >
                {editingTemplate?.content.subtitle}
              </Typography>
            </Box>

            {/* Render each section */}
            {editingTemplate?.content.sections.map((section, index) => (
              <Box key={index} sx={{ mb: { xs: 2, sm: 3, md: 4 } }}>
                {/* Personal Info Section */}
                {section.type === 'personal_info' && (
                  <Box>
                    <Typography 
                      variant="h6" 
                      gutterBottom 
                      sx={{ 
                        fontWeight: 'bold',
                        fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' }
                      }}
                    >
                      Personal Information
                    </Typography>
                    <Grid container spacing={{ xs: 1, sm: 2 }}>
                      {section.fields.map((field, fieldIndex) => (
                        <Grid item xs={12} sm={field.type === 'text' ? 6 : 12} key={fieldIndex}>
                          <TextField
                            fullWidth
                            label={field.label}
                            variant="outlined"
                            disabled
                            required={field.required}
                            placeholder={field.type === 'date' ? 'MM/DD/YYYY' : `Enter ${field.label}`}
                            InputProps={{
                              sx: { fontSize: { xs: '0.875rem', sm: '1rem' } }
                            }}
                            InputLabelProps={{
                              sx: { fontSize: { xs: '0.875rem', sm: '1rem' } }
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}

                {/* Notice Section */}
                {section.type === 'notice' && (
                  <Box sx={{ 
                    p: { xs: 1.5, sm: 2 }, 
                    bgcolor: 'rgba(0, 0, 0, 0.05)', 
                    borderRadius: 1,
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    mb: { xs: 2, sm: 3 }
                  }}>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        fontWeight: 'medium',
                        fontSize: { xs: '0.875rem', sm: '1rem' }
                      }}
                    >
                      {section.content}
                    </Typography>
                  </Box>
                )}

                {/* Terms Section */}
                {section.type === 'terms' && (
                  <Box>
                    {section.paragraphs.map((paragraph, pIndex) => (
                      <Typography 
                        key={pIndex} 
                        variant="body1" 
                        paragraph
                        sx={{ 
                          mb: { xs: 1.5, sm: 2 },
                          fontSize: { xs: '0.875rem', sm: '1rem' },
                          fontWeight: paragraph.emphasis === 'strong' ? 'bold' : 'normal',
                          ...(paragraph.emphasis === 'strong' && { 
                            color: theme.palette.error.main,
                            textTransform: 'uppercase'
                          })
                        }}
                      >
                        {paragraph.text}
                      </Typography>
                    ))}
                  </Box>
                )}

                {/* Age Verification Section */}
                {section.type === 'age_verification' && (
                  <Box sx={{ mt: { xs: 2, sm: 3 }, mb: { xs: 2, sm: 3 } }}>
                    <Typography 
                      variant="h6" 
                      gutterBottom
                      sx={{ fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' } }}
                    >
                      {section.title}
                    </Typography>
                    <FormControl component="fieldset">
                      <RadioGroup>
                        {section.options.map((option, optIndex) => (
                          <FormControlLabel
                            key={optIndex}
                            value={option.value}
                            control={<Radio disabled />}
                            label={
                              <Typography sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                                {option.label}
                              </Typography>
                            }
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                )}

                {/* Acknowledgment Section */}
                {section.type === 'acknowledgment' && (
                  <Box sx={{ 
                    p: { xs: 1.5, sm: 2 }, 
                    bgcolor: 'rgba(0, 0, 0, 0.05)', 
                    borderRadius: 1,
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    mb: { xs: 2, sm: 3 },
                    mt: { xs: 2, sm: 3 }
                  }}>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        fontWeight: 'bold',
                        fontSize: { xs: '0.875rem', sm: '1rem' },
                        ...(section.emphasis === 'strong' && { 
                          color: theme.palette.error.main,
                          textTransform: 'uppercase'
                        })
                      }}
                    >
                      {section.text}
                    </Typography>
                  </Box>
                )}

                {/* Signature Section */}
                {section.type === 'signature_section' && (
                  <Box sx={{ mt: { xs: 3, sm: 4 } }}>
                    <Grid container spacing={{ xs: 1, sm: 2 }}>
                      {section.fields.map((field, fieldIndex) => (
                        <Grid item xs={12} sm={field.type === 'signature' ? 12 : 6} key={fieldIndex}>
                          {field.type === 'date' ? (
                            <TextField
                              fullWidth
                              label={field.label}
                              variant="outlined"
                              disabled
                              required={field.required}
                              placeholder="MM/DD/YYYY"
                              InputProps={{
                                sx: { fontSize: { xs: '0.875rem', sm: '1rem' } }
                              }}
                              InputLabelProps={{
                                sx: { fontSize: { xs: '0.875rem', sm: '1rem' } }
                              }}
                            />
                          ) : field.type === 'signature' ? (
                            <Box>
                              <Typography 
                                variant="body2" 
                                gutterBottom
                                sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
                              >
                                {field.label} {field.required && '*'}
                              </Typography>
                              <Box 
                                sx={{ 
                                  border: '1px solid rgba(0, 0, 0, 0.23)', 
                                  borderRadius: 1,
                                  height: { xs: 80, sm: 100 },
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  bgcolor: 'white'
                                }}
                              >
                                <Typography 
                                  variant="body2" 
                                  color="text.secondary"
                                  sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
                                >
                                  Signature will appear here
                                </Typography>
                              </Box>
                            </Box>
                          ) : (
                            <TextField
                              fullWidth
                              label={field.label}
                              variant="outlined"
                              disabled
                              required={field.required}
                              InputProps={{
                                sx: { fontSize: { xs: '0.875rem', sm: '1rem' } }
                              }}
                              InputLabelProps={{
                                sx: { fontSize: { xs: '0.875rem', sm: '1rem' } }
                              }}
                            />
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}
              </Box>
            ))}
          </Paper>
        </DialogContent>
        <DialogActions sx={{ px: { xs: 2, sm: 3 }, py: { xs: 1.5, sm: 2 } }}>
          <Button 
            onClick={() => setPreviewOpen(false)}
            sx={{ fontSize: { xs: '0.8rem', sm: '0.875rem' } }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default WaiversPage; 