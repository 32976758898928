import React from 'react';
import { Box, Typography, Container, Paper } from '@mui/material';
import TourCarousel from '../../components/TourCarousel';
import DoubleTourCarousel from '../../components/DoubleTourCarousel';
import { TourProvider } from '../../context/TourContext';

const TestComponents = () => {
  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Test Components
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          This page showcases various components in development.
        </Typography>
      </Paper>

      <Box sx={{ mb: 6, '& > div': { mb: 8 } }}>
        <TourProvider>
          <TourCarousel />
          
          {/* Island Tours - Text on Left */}
          <Box sx={{ mb: 8 }}>
            <DoubleTourCarousel
              title="Island Tours"
              description="Discover the hidden gems of Curaçao with our expertly guided island tours. From historic Willemstad to the rugged northern coast, experience the island's rich culture, stunning landscapes, and vibrant communities. Our tours blend adventure with local insights, ensuring you see the authentic beauty of our Caribbean paradise."
              textPosition="left"
              tourIds={['296606', '301652', '304151', '326864', '326870', '327478']}
              actionButtonText="Explore Island Tours"
              onActionClick={() => console.log('Island tours clicked')}
            />
          </Box>

          {/* Water Tours - Text on Right */}
          <Box sx={{ mb: 8 }}>
            <DoubleTourCarousel
              title="Water Tours"
              description="Dive into the crystal-clear waters of Curaçao with our exciting water tours. Whether you're snorkeling in vibrant coral reefs, sailing along the pristine coastline, or exploring hidden beaches, our water adventures offer unforgettable experiences for all skill levels. Join us for an aquatic journey in the Caribbean's most beautiful waters."
              textPosition="right"
              tourIds={['296605', '293823', '311035', '317387', '326864', '326870']}
              actionButtonText="Discover Water Tours"
              onActionClick={() => console.log('Water tours clicked')}
            />
          </Box>
        </TourProvider>
      </Box>
    </Container>
  );
};

export default TestComponents; 