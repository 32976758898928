import React, { useState, useEffect } from 'react';
import {
  Typography,
  Container,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Chip,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { collection, query, orderBy, limit, getDocs, where, startAfter } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { toast } from 'react-hot-toast';

const WaiverRequests = () => {
  const [waiverRequests, setWaiverRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [selectedWaiver, setSelectedWaiver] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);

  useEffect(() => {
    fetchWaiverRequests();
  }, [statusFilter]);

  const fetchWaiverRequests = async (searchValue = '') => {
    try {
      setLoading(true);
      
      let waiverQuery;
      
      if (statusFilter === 'all') {
        waiverQuery = query(
          collection(db, 'waiverRequests'),
          orderBy('createdAt', 'desc'),
          limit(50)
        );
      } else {
        waiverQuery = query(
          collection(db, 'waiverRequests'),
          where('status', '==', statusFilter),
          orderBy('createdAt', 'desc'),
          limit(50)
        );
      }
      
      const snapshot = await getDocs(waiverQuery);
      
      if (snapshot.empty) {
        setWaiverRequests([]);
        setHasMore(false);
        setLoading(false);
        return;
      }
      
      const requests = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
        completedAt: doc.data().completedAt?.toDate() || null,
        emailSentAt: doc.data().emailSentAt?.toDate() || null
      }));
      
      setWaiverRequests(requests);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      setHasMore(snapshot.docs.length === 50);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching waiver requests:', error);
      toast.error('Failed to load waiver requests');
      setLoading(false);
    }
  };

  const loadMoreWaiverRequests = async () => {
    if (!lastVisible || !hasMore) return;
    
    try {
      setLoading(true);
      
      let waiverQuery;
      
      if (statusFilter === 'all') {
        waiverQuery = query(
          collection(db, 'waiverRequests'),
          orderBy('createdAt', 'desc'),
          startAfter(lastVisible),
          limit(50)
        );
      } else {
        waiverQuery = query(
          collection(db, 'waiverRequests'),
          where('status', '==', statusFilter),
          orderBy('createdAt', 'desc'),
          startAfter(lastVisible),
          limit(50)
        );
      }
      
      const snapshot = await getDocs(waiverQuery);
      
      if (snapshot.empty) {
        setHasMore(false);
        setLoading(false);
        return;
      }
      
      const newRequests = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
        completedAt: doc.data().completedAt?.toDate() || null,
        emailSentAt: doc.data().emailSentAt?.toDate() || null
      }));
      
      setWaiverRequests(prev => [...prev, ...newRequests]);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      setHasMore(snapshot.docs.length === 50);
      setLoading(false);
    } catch (error) {
      console.error('Error loading more waiver requests:', error);
      toast.error('Failed to load more waiver requests');
      setLoading(false);
    }
  };

  const handleSearch = () => {
    fetchWaiverRequests(searchTerm);
  };

  const handleRefresh = () => {
    setSearchTerm('');
    fetchWaiverRequests();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    
    if (newPage * rowsPerPage >= waiverRequests.length - 10 && hasMore) {
      loadMoreWaiverRequests();
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatusFilterChange = (status) => {
    setStatusFilter(status);
    setPage(0);
  };

  const handleViewDetails = (waiver) => {
    setSelectedWaiver(waiver);
    setDetailsOpen(true);
  };

  const handleCloseDetails = () => {
    setDetailsOpen(false);
  };

  const getStatusChip = (status) => {
    switch (status) {
      case 'pending':
        return <Chip label="Pending" color="warning" size="small" />;
      case 'completed':
        return <Chip label="Completed" color="success" size="small" />;
      default:
        return <Chip label={status} size="small" />;
    }
  };

  const formatDate = (date) => {
    if (!date) return 'N/A';
    return new Date(date).toLocaleString();
  };

  const filteredRequests = waiverRequests.filter(request => {
    if (!searchTerm) return true;
    
    const searchLower = searchTerm.toLowerCase();
    return (
      (request.participantName && request.participantName.toLowerCase().includes(searchLower)) ||
      (request.participantEmail && request.participantEmail.toLowerCase().includes(searchLower)) ||
      (request.tourName && request.tourName.toLowerCase().includes(searchLower))
    );
  });

  const paginatedRequests = filteredRequests.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Waiver Requests
      </Typography>
      
      <Box sx={{ mb: 3, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2, alignItems: 'center' }}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
          sx={{ flexGrow: 1 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearch} edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            variant={statusFilter === 'all' ? 'contained' : 'outlined'}
            onClick={() => handleStatusFilterChange('all')}
          >
            All
          </Button>
          <Button
            variant={statusFilter === 'pending' ? 'contained' : 'outlined'}
            onClick={() => handleStatusFilterChange('pending')}
          >
            Pending
          </Button>
          <Button
            variant={statusFilter === 'completed' ? 'contained' : 'outlined'}
            onClick={() => handleStatusFilterChange('completed')}
          >
            Completed
          </Button>
        </Box>
        
        <IconButton onClick={handleRefresh} color="primary">
          <RefreshIcon />
        </IconButton>
      </Box>
      
      <Paper elevation={3}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Participant</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Tour</TableCell>
                <TableCell>Tour Date</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Completed</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && waiverRequests.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <CircularProgress size={24} sx={{ my: 2 }} />
                  </TableCell>
                </TableRow>
              ) : paginatedRequests.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No waiver requests found
                  </TableCell>
                </TableRow>
              ) : (
                paginatedRequests.map((request) => (
                  <TableRow key={request.id}>
                    <TableCell>{request.participantName || 'N/A'}</TableCell>
                    <TableCell>{request.participantEmail || 'N/A'}</TableCell>
                    <TableCell>{request.tourName || 'N/A'}</TableCell>
                    <TableCell>{request.tourDate || 'N/A'}</TableCell>
                    <TableCell>{formatDate(request.createdAt)}</TableCell>
                    <TableCell>{getStatusChip(request.status)}</TableCell>
                    <TableCell>{formatDate(request.completedAt)}</TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => handleViewDetails(request)}
                        title="View Details"
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
              {loading && waiverRequests.length > 0 && (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <CircularProgress size={24} sx={{ my: 2 }} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={filteredRequests.length + (hasMore ? 1 : 0)}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      
      {/* Waiver Details Dialog */}
      <Dialog open={detailsOpen} onClose={handleCloseDetails} maxWidth="md" fullWidth>
        <DialogTitle>
          Waiver Request Details
        </DialogTitle>
        <DialogContent dividers>
          {selectedWaiver && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Participant Information
              </Typography>
              <Box sx={{ mb: 3 }}>
                <Typography><strong>Name:</strong> {selectedWaiver.participantName || 'N/A'}</Typography>
                <Typography><strong>Email:</strong> {selectedWaiver.participantEmail || 'N/A'}</Typography>
              </Box>
              
              <Typography variant="h6" gutterBottom>
                Tour Information
              </Typography>
              <Box sx={{ mb: 3 }}>
                <Typography><strong>Tour:</strong> {selectedWaiver.tourName || 'N/A'}</Typography>
                <Typography><strong>Tour ID:</strong> {selectedWaiver.tourId || 'N/A'}</Typography>
                <Typography><strong>Tour Date:</strong> {selectedWaiver.tourDate || 'N/A'}</Typography>
              </Box>
              
              <Typography variant="h6" gutterBottom>
                Waiver Information
              </Typography>
              <Box sx={{ mb: 3 }}>
                <Typography><strong>Template:</strong> {selectedWaiver.waiverTemplateName || 'N/A'}</Typography>
                <Typography><strong>Status:</strong> {selectedWaiver.status || 'N/A'}</Typography>
                <Typography><strong>Created:</strong> {formatDate(selectedWaiver.createdAt)}</Typography>
                <Typography><strong>Email Sent:</strong> {selectedWaiver.emailSent ? 'Yes' : 'No'}</Typography>
                <Typography><strong>Email Sent At:</strong> {formatDate(selectedWaiver.emailSentAt)}</Typography>
                <Typography><strong>Completed At:</strong> {formatDate(selectedWaiver.completedAt)}</Typography>
              </Box>
              
              {selectedWaiver.status === 'completed' && selectedWaiver.formData && (
                <>
                  <Typography variant="h6" gutterBottom>
                    Form Data
                  </Typography>
                  <Box sx={{ mb: 3 }}>
                    {Object.entries(selectedWaiver.formData).map(([key, value]) => (
                      <Typography key={key}>
                        <strong>{key}:</strong> {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value || 'N/A'}
                      </Typography>
                    ))}
                  </Box>
                </>
              )}
              
              {selectedWaiver.status === 'completed' && selectedWaiver.signature && (
                <>
                  <Typography variant="h6" gutterBottom>
                    Signature
                  </Typography>
                  <Box sx={{ mb: 3, border: '1px solid', borderColor: 'grey.300', p: 1 }}>
                    <img src={selectedWaiver.signature} alt="Signature" style={{ maxWidth: '100%' }} />
                  </Box>
                </>
              )}
              
              <Typography variant="h6" gutterBottom>
                Actions
              </Typography>
              <Box sx={{ mb: 3 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  href={`/waiver-form/${selectedWaiver.id}`}
                  target="_blank"
                  sx={{ mr: 1 }}
                >
                  View Waiver Form
                </Button>
                
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    navigator.clipboard.writeText(`https://curacao-tours.com/waiver-form/${selectedWaiver.id}`);
                    toast.success('Waiver link copied to clipboard');
                  }}
                >
                  Copy Waiver Link
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetails}>Close</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default WaiverRequests; 