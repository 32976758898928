import React, { useState, useEffect } from 'react';
import TourCardV2 from '../../components/TourCardV2';
import { Grid, Container, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import localDeals from '../../data/local-deals.json';
import { motion } from 'framer-motion';
import PercentIcon from '@mui/icons-material/Percent';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

const BRAND_COLORS = {
  blue: '#1A3D8F',
  yellow: '#FFD700',
  lightBlue: '#2451B3',
  lightYellow: '#FFE44D'
};

function LocalDeals() {
  const [tours, setTours] = useState([]);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = matches;

  const FeatureBox = ({ icon, text }) => (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      mb: 2,
      p: 2,
      borderRadius: 2,
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      transition: 'transform 0.2s',
      '&:hover': {
        transform: 'translateY(-5px)',
      }
    }}>
      <Box sx={{ 
        color: BRAND_COLORS.blue,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '2rem'
      }}>
        {icon}
      </Box>
      <Typography variant="body1" sx={{ color: BRAND_COLORS.blue }}>
        {text}
      </Typography>
    </Box>
  );

  useEffect(() => {
    try {
      const dealsArray = Object.entries(localDeals).map(([id, data]) => ({
        id,
        ...data
      }));
      setTours(dealsArray);
    } catch (error) {
      console.error("Error loading tour data:", error);
    }
  }, []);

  if (tours.length === 0) {
    return null;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Box sx={{ 
        width: '100vw', 
        height: isMobile ? '50vh' : '100vh', 
        position: 'relative',
        marginTop: '-64px',
        marginLeft: 'calc(-50vw + 50%)',
        marginRight: 'calc(-50vw + 50%)',
        overflow: 'hidden'
      }}>
        <motion.img 
          initial={{ scale: 1.1 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1.5 }}
          src="/images/Curacao_Tours_Local_Tours.jpg"
          alt="Local Tours" 
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }} 
        />
        <motion.div 
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 0.5 }}
          transition={{ duration: 0.8 }}
          style={{
            position: 'absolute',
            top: 0,
            left: -300,
            width: '75%',
            height: '100%',
            backgroundImage: 'url(/images/Overlay.svg)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }} 
        />
      </Box>

      <Box sx={{
        width: '100%',
        background: 'linear-gradient(180deg, rgba(26,61,143,0.1) 0%, rgba(255,255,255,1) 45%, rgba(255,255,255,1) 55%, rgba(26,61,143,0.1) 100%)',
        py: { xs: 4, md: 6 },
        mt: '-1px'
      }}>
        <Container maxWidth="lg" sx={{ mb: 6 }}>
          <Typography 
            variant={isMobile ? "h4" : "h2"} 
            component="h1" 
            align="center"
            gutterBottom 
            sx={{ 
              color: BRAND_COLORS.blue,
              fontWeight: 700,
              mb: 3,
              mt: 4,
              lineHeight: 1.2,
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3.5rem' }
            }}
          >
            Local Deals & Discounts
          </Typography>
          <Typography 
            variant={isMobile ? "h5" : "h3"} 
            component="h2" 
            align="center"
            gutterBottom 
            sx={{ 
              color: BRAND_COLORS.blue,
              fontWeight: 500,
              mb: 6,
              opacity: 0.9,
              fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2.5rem' }
            }}
          >
            Exclusive Offers for Local Residents
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <FeatureBox 
                icon={<LocalOfferIcon fontSize="inherit" />}
                text="Special discounts for local residents"
              />
              <FeatureBox 
                icon={<PercentIcon fontSize="inherit" />}
                text="Exclusive local pricing on popular tours"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography 
                variant="body1" 
                sx={{ 
                  mb: 4,
                  fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
                  color: 'text.secondary',
                  lineHeight: 1.6
                }}
              >
                Experience the best of Curaçao with our exclusive local resident deals. We're proud to offer special rates and packages designed specifically for our local community.
              </Typography>
            </Grid>
          </Grid>

          <Grid 
            container 
            spacing={4} 
            sx={{ mt: 4, mb: { xs: 4, sm: 6, md: 8 } }} 
            justifyContent="center"
          >
            {tours.map((tour) => (
              <Grid 
                item 
                xs={12} 
                sm={6} 
                md={4} 
                key={tour.id}
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <TourCardV2 tour={tour} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </motion.div>
  );
}

export default LocalDeals;
