import React, { useState } from 'react';
import { Box, Typography, Link, IconButton, Button } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from 'react-router-dom';

const Reviews = () => {
  const navigate = useNavigate();
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  const reviews = [
    {
      rating: 5,
      reviewText: "Amazing day trip! Beautiful Island and Catamaran. Had an amazing barbecue lunch and the crew was outstanding - so friendly and fun. Highly recommend this trip",
      author: "wilkinc2018",
      location: "Cleveland, OH"
    },
    {
      rating: 4,
      reviewText: "Curacao beach escape tour it's a must. Had such a great time on this tour it was all I expected and more. The guides were friendly and knowledgable and kept us engage the entire trip. The waters were calm relaxing and blue. For the first time my girlfriend and I got to swim with green turtles just wawww we held hands and just admired their beauty. The guides even reserved private area for us to have lunch ( which were included). Perfect tour for anyone looking for a relaxing day at the beach",
      author: "Anonymous",
      location: ""
    },
    {
      rating: 5,
      reviewText: "Curaçao Island tour with Hetty and Daniel. Our tour guide Hetty was absolutely amazing. Very informative and interesting. Her knowledge of the history of the island was extensive. She spoke very clearly and answered any question asked. Daniel our driver was an experienced driver for sure. He too was pleasant and accommodating. Highly recommend this tour.",
      author: "Anonymous",
      location: ""
    },
    {
      rating: 5,
      reviewText: "Under Water Seaworld Explorer, a Great Option for Non-Snorkelers!! We traveled to Curacao the week after Easter. The coral reefs were beautifully untouched and well taken care of. The types of fish seen on this trip was endless!! The captain and first mate were fun, accommodating and kept your attention. The kids and older generation enjoys it equally. Would highly recommend for those who are too afraid or have issues snorkeling or diving. The boat was well air conditioned too, so no one was uncomfortable at any time.",
      author: "Anonymous",
      location: ""
    },
  ];

  const handlePrevReview = () => {
    setCurrentReviewIndex((prevIndex) => 
      prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };

  const handleNextReview = () => {
    setCurrentReviewIndex((prevIndex) => 
      prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
    );
  };

  const currentReview = reviews[currentReviewIndex];

  return (
    <Box sx={{
      width: '100%',
      backgroundColor: 'transparent',
      pt: { xs: 0, md: 8 },
      pb: { xs: 0, md: 12 },
      position: 'relative',
      zIndex: 1,
      mt: { xs: -4, md: 0 },
    }}>
      {/* Reviews Section */}
      <Box sx={{
        maxWidth: '1400px',
        margin: '0 auto',
        px: { xs: 0, md: 2 },
        position: 'relative',
        mb: { xs: 0, md: 12 },
      }}>
        <Typography
          variant="h2"
          sx={{
            color: { xs: 'white', md: '#1a237e' },
            textAlign: 'center',
            fontSize: { xs: '2rem', md: '3rem' },
            fontWeight: 'bold',
            mb: { xs: 3, md: 8 },
            display: { xs: 'none', md: 'block' }
          }}
        >
          What our clients say
        </Typography>

        <Box sx={{
          display: 'flex',
          position: 'relative',
          backgroundColor: { xs: '#FFD700', md: '#FFD700' },
          borderRadius: { xs: 0, md: 0 },
          p: { xs: 3, md: 6 },
          overflow: 'visible',
          height: { xs: 'auto', md: '400px' },
          flexDirection: 'column',
          alignItems: 'center',
          '&::before, &::after': {
            display: { xs: 'none', md: 'block' },
            content: '""',
            position: 'absolute',
            width: '80px',
            height: '80px',
            backgroundColor: '#FFD700',
          },
          '&::before': {
            top: 0,
            right: 0,
            borderLeft: '2px solid white',
            clipPath: 'polygon(100% 0, 0 0, 100% 100%)',
          },
          '&::after': {
            bottom: 0,
            left: 0,
            borderTop: '2px solid white',
            clipPath: 'polygon(0 100%, 0 0, 100% 100%)',
          },
        }}>
          {/* Main Content */}
          <Box sx={{
            flex: 1,
            pr: { xs: 0, md: 20 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: { xs: 'center', md: 'flex-start' },
            justifyContent: 'center',
            width: '100%',
          }}>
            {/* Stars */}
            <Box sx={{ 
              display: 'flex',
              mb: { xs: 2, md: 4 },
              justifyContent: { xs: 'center', md: 'flex-start' }
            }}>
              {[...Array(5)].map((_, index) => (
                <StarIcon key={index} sx={{
                  color: 'black',
                  fontSize: { xs: '1.5rem', md: '2rem' },
                }} />
              ))}
            </Box>

            {/* Review Text */}
            <Typography 
              variant="h5" 
              sx={{ 
                color: 'black',
                fontSize: { xs: '1rem', md: '1.4rem' },
                lineHeight: 1.5,
                mb: { xs: 2, md: 4 },
                fontWeight: 'normal',
                maxWidth: '100%',
                textAlign: { xs: 'center', md: 'left' },
                px: { xs: 2, md: 0 }
              }}
            >
              "{currentReview.reviewText}"
            </Typography>

            {/* Author */}
            <Typography 
              variant="subtitle1" 
              sx={{ 
                color: 'black',
                fontWeight: 'bold',
                fontSize: { xs: '0.9rem', md: '1.1rem' },
                textAlign: { xs: 'center', md: 'left' },
                mb: { xs: 2, md: 0 }
              }}
            >
              {currentReview.author}{currentReview.location && ` - ${currentReview.location}`}
            </Typography>

            {/* Mobile TripAdvisor Logo */}
            <Box
              component="img"
              src="/images/tripadvisor-icon.png"
              alt="TripAdvisor"
              sx={{
                width: '50px',
                height: '50px',
                display: { xs: 'block', md: 'none' },
                mt: 1
              }}
            />
          </Box>

          {/* Desktop TripAdvisor Logo */}
          <Link 
            href="https://www.tripadvisor.com/Attraction_Review-g147278-d15700888-Reviews-FBTT_Travel_BV-Willemstad_Curacao.html" 
            target="_blank" 
            rel="noopener noreferrer"
            sx={{ 
              position: 'absolute',
              right: '2rem',
              top: '50%',
              transform: 'translateY(-50%)',
              width: { xs: '100px', md: '120px' },
              height: { xs: '100px', md: '120px' },
              backgroundColor: '#00B388',
              borderRadius: '50%',
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
              justifyContent: 'center',
              textDecoration: 'none',
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'translateY(-50%) scale(1.05)',
              }
            }}
          >
            <Box
              component="img"
              src="/images/tripadvisor-icon.png"
              alt="TripAdvisor"
              sx={{
                width: { xs: '70px', md: '80px' },
                height: { xs: '70px', md: '80px' },
              }}
            />
          </Link>

          {/* Navigation Buttons */}
          <Box sx={{ 
            position: { xs: 'static', md: 'absolute' },
            bottom: '1.5rem',
            right: '1.5rem',
            display: 'flex',
            gap: 1,
            mt: { xs: 2, md: 0 }
          }}>
            <IconButton 
              onClick={handlePrevReview} 
              sx={{ 
                color: 'black',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.1)',
                }
              }}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
            <IconButton 
              onClick={handleNextReview} 
              sx={{ 
                color: 'black',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.1)',
                }
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {/* About Section */}
      <Box 
        component="section"
        sx={{
          maxWidth: '1400px',
          margin: '0 auto',
          px: { xs: 0, md: 2 },
          position: 'relative',
          zIndex: 2,
          width: '100%',
        }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: { xs: 'center', md: 'flex-start' },
          gap: { xs: 4, md: 8 },
          background: '#FFFFFF',
          borderRadius: { xs: 0, md: '16px' },
          p: { xs: 0, md: 4 },
          textAlign: { xs: 'center', md: 'left' },
          width: '100%',
          mt: { xs: 0, md: 4 },
        }}>
          {/* Image */}
          <Box sx={{
            width: '100%',
            height: { xs: '300px', md: '400px' },
            position: 'relative',
            borderRadius: { xs: 0, md: '16px' },
            overflow: 'hidden',
          }}>
            <Box
              component="img"
              src="/images/curacaou_tours_beach_contact_page.jpg"
              alt="Beautiful Curaçao Beach"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            />
          </Box>

          {/* Content */}
          <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            alignItems: 'center',
            px: { xs: 3, md: 0 },
            py: { xs: 5, md: 0 },
            pb: { xs: 8, md: 0 },
            background: { xs: '#1A3D8F', md: 'transparent' },
            borderRadius: { xs: 0, md: 0 },
            mx: { xs: 0, md: 0 },
            mt: { xs: -20, md: 0 },
          }}>
            <Typography
              variant="h2"
              sx={{
                color: { xs: 'white', md: '#1A3D8F' },
                fontSize: { xs: '2rem', md: '2.5rem' },
                fontWeight: 'bold',
                lineHeight: 1.2,
                textAlign: 'center',
                maxWidth: { xs: '300px', md: 'none' },
                mt: { xs: 2, md: 0 },
              }}
            >
              About Curaçao Tours
            </Typography>
            
            <Typography
              variant="body1"
              sx={{
                color: { xs: 'white', md: 'text.secondary' },
                fontSize: { xs: '1rem', md: '1.1rem' },
                lineHeight: 1.6,
                textAlign: 'center',
                maxWidth: { xs: '300px', md: 'none' },
                mb: { xs: 2, md: 0 },
              }}
            >
              At Curaçao Tours, we specialize in creating unforgettable experiences that showcase everything Curaçao has to offer. Whether you're visiting for relaxation, adventure, or cultural exploration, we have the perfect tour to make your trip to Curaçao truly memorable.
            </Typography>

            <Button
              variant="contained"
              onClick={() => {
                navigate('/contact');
                window.scrollTo(0, 0);
              }}
              sx={{
                width: 'fit-content',
                bgcolor: { xs: '#FFD700', md: '#1A3D8F' },
                color: { xs: '#1A3D8F', md: 'white' },
                px: 4,
                py: 1.5,
                borderRadius: '25px',
                fontSize: '1rem',
                textTransform: 'none',
                fontWeight: 'bold',
                '&:hover': {
                  bgcolor: { xs: '#FFE44D', md: '#2A4DA0' },
                },
                mt: { xs: 1, md: 0 },
              }}
            >
              Contact Us
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Reviews;
