import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import TourCarousel from '../../components/TourCarousel';
import DoubleTourCarousel from '../../components/DoubleTourCarousel';
import { OptimizedTourProvider } from '../../context/OptimizedTourContext';
import HeaderVideo from '../../components/HeaderVideo';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const theme = useTheme();
  const navigate = useNavigate();

  const scrollToSection = () => {
    const section = document.getElementById('target-section');
    section.scrollIntoView({ behavior: 'smooth' });
  };

  const navigateToTours = (category) => {
    navigate(`/categories/${category}-tours`);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Helmet>
        <title>Curacao Tours - Discover the Beauty of Curacao</title>
        <meta name="description" content="Join Curacao Tours to explore the stunning landscapes and vibrant culture of Curacao. Book your next adventure today and create unforgettable memories." />
        <meta name="keywords" content="Curacao tours, Curacao travel, Curacao adventures, island tours, snorkeling tours, cultural tours, Curacao excursions" />
        <link rel="canonical" href="http://curacao-tours.com" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "TravelAgency",
              "name": "Curacao Tours",
              "description": "Join Curacao Tours to explore the stunning landscapes and vibrant culture of Curacao.",
              "url": "http://curacao-tours.com",
              "logo": "http://curacao-tours.com/logo192.png",
              "telephone": "+59998699559",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Seru di Mahuma #18 - 20",
                "addressLocality": "Willemstad",
                "addressRegion": "Curaçao",
                "postalCode": "0000",
                "addressCountry": "CW"
              },
              "sameAs": [
                "https://www.facebook.com/curacao.tours",
                "https://www.instagram.com/curacao.tours",
                "https://www.twitter.com/curacao.tours"
              ]
            }
          `}
        </script>
      </Helmet>

      <Box sx={{
        width: '100%',
        position: 'relative',
        height: '100vh',
        marginBottom: '-1px'
      }}>
        <HeaderVideo />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 2,
            textAlign: 'center',
            width: '100%',
            maxWidth: '90%',
            padding: { xs: 2, md: 3 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              color: 'white',
              fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' },
              fontWeight: 700,
              textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
              mb: 2,
              lineHeight: 1.2,
            }}
          >
            Curaçao Tours
          </Typography>
          <Typography
            variant="h2"
            sx={{
              color: 'white',
              fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },
              fontWeight: 500,
              textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
              mb: 4,
              maxWidth: '800px',
              mx: 'auto',
              lineHeight: 1.3,
            }}
          >
            Discover the Best Water and Island Activities to do in Curaçao
          </Typography>
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.98 }}
            style={{ marginTop: '15vh' }}
          >
            <Button
              variant="contained"
              onClick={scrollToSection}
              sx={{
                backgroundColor: '#FFD700',
                color: '#1A3D8F',
                fontWeight: 600,
                px: { xs: 3, md: 4 },
                py: { xs: 0.75, md: 1 },
                fontSize: { xs: '0.85rem', md: '0.95rem' },
                borderRadius: 50,
                textTransform: 'none',
                boxShadow: '0 4px 14px rgba(0, 0, 0, 0.15)',
                '&:hover': {
                  backgroundColor: '#FFE44D',
                  boxShadow: '0 6px 20px rgba(0, 0, 0, 0.2)',
                },
                minWidth: 'auto',
                height: { xs: '32px', md: '36px' },
              }}
              endIcon={<Box component="span" sx={{ ml: 0.5, fontSize: '1rem' }}>↓</Box>}
            >
              Explore More
            </Button>
          </motion.div>
        </Box>
      </Box>

      <Box 
        id="target-section"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: 'linear-gradient(180deg, rgba(26,61,143,0.1) 0%, rgba(26,61,143,0.05) 100%)',
          py: 0
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '100%',
            position: 'relative',
          }}
        >
          <OptimizedTourProvider>
            <Box sx={{ 
              width: '100%', 
              background: 'white',
              pt: { xs: 6, md: 8 },
              pb: { xs: 6, md: 8 }
            }}>
              <TourCarousel />
            </Box>

            <Box sx={{ 
              width: '100%',
              background: 'linear-gradient(180deg, rgba(26,61,143,0.1) 0%, rgba(255,255,255,1) 45%, rgba(255,255,255,1) 55%, rgba(26,61,143,0.1) 100%)',
              py: { xs: 4, md: 6 }
            }}>
              {/* Island Tours - Text on Left */}
              <DoubleTourCarousel
                title="Island Tours"
                description="Discover the hidden gems of Curaçao with our expertly guided island tours. From historic Willemstad to the rugged northern coast, experience the island's rich culture, stunning landscapes, and vibrant communities."
                textPosition="left"
                tourIds={['296606', '301652', '304151', '326864', '326870', '327478', '330458']}
                actionButtonText="Explore Island Tours"
                onActionClick={() => navigateToTours('island')}
              />

              {/* Water Tours - Text on Right */}
              <DoubleTourCarousel
                title="Water Tours"
                description="Dive into the crystal-clear waters of Curaçao with our exciting water tours. Whether you're snorkeling in vibrant coral reefs, sailing along the pristine coastline, or exploring hidden beaches, our water adventures offer unforgettable experiences for all skill levels."
                textPosition="right"
                tourIds={['293823', '296605', '311035', '317387']}
                actionButtonText="Explore Water Tours"
                onActionClick={() => navigateToTours('water')}
              />
            </Box>
          </OptimizedTourProvider>
        </Box>
      </Box>
    </motion.div>
  );
}