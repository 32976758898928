import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from 'config/firebase/firebase';
import { 
  Container, Paper, Typography, Box, CircularProgress, TextField,
  Tabs, Tab, useTheme, Grid, Divider, Card, Badge, ToggleButton, ToggleButtonGroup
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, addDays, subDays, isToday, isSameDay, startOfDay } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';
import useMediaQuery from '@mui/material/useMediaQuery';
import RestaurantIcon from '@mui/icons-material/Restaurant';

const TourMenu = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [menuChoices, setMenuChoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState({});
  const [selectedTab, setSelectedTab] = useState(3); // Index for today
  const [selectedRestaurants, setSelectedRestaurants] = useState([]);
  const [availableRestaurants, setAvailableRestaurants] = useState([]);

  // Generate dates for tabs (3 days before and 7 days after today)
  const dates = [...Array(11)].map((_, index) => addDays(new Date(), index - 3));

  const fetchMenuChoices = async (date) => {
    if (!date) return;

    setLoading(true);
    try {
      // Format date to match the stored format (YYYY-MM-DD)
      const startDate = startOfDay(date);
      const endDate = addDays(startDate, 1);
      
      console.log('Fetching menus between:', startDate, 'and', endDate); // Debug log

      const q = query(
        collection(db, 'guestChoices'),
        where('tourDate', '>=', format(startDate, 'yyyy-MM-dd')),
        where('tourDate', '<', format(endDate, 'yyyy-MM-dd'))
      );

      const querySnapshot = await getDocs(q);
      const choices = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        console.log('Found menu choice:', data); // Debug log
        choices.push({ id: doc.id, ...data });
      });

      setMenuChoices(choices);
      generateSummary(choices);
    } catch (error) {
      console.error('Error fetching menu choices:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMenuChoices(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    // Extract unique restaurants from the menu choices
    const restaurants = new Set();
    menuChoices.forEach(booking => {
      Object.values(booking.guestChoices).forEach(selections => {
        Object.keys(selections).forEach(restaurant => {
          restaurants.add(restaurant);
        });
      });
    });
    setAvailableRestaurants(Array.from(restaurants));
  }, [menuChoices]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const tabIndex = dates.findIndex(d => isSameDay(d, date));
    if (tabIndex !== -1) {
      setSelectedTab(tabIndex);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedDate(dates[newValue]);
  };

  const handleRestaurantToggle = (event, newRestaurants) => {
    setSelectedRestaurants(newRestaurants);
  };

  const filterBookingsByRestaurant = (booking) => {
    if (selectedRestaurants.length === 0) return true;
    
    return Object.values(booking.guestChoices).some(selections => 
      Object.keys(selections).some(restaurant => 
        selectedRestaurants.includes(restaurant)
      )
    );
  };

  const generateSummary = (choices) => {
    const summary = {};
    
    choices.forEach(booking => {
      const { guestChoices } = booking;
      Object.entries(guestChoices).forEach(([guestNum, selections]) => {
        Object.entries(selections).forEach(([restaurant, categories]) => {
          if (!summary[restaurant]) {
            summary[restaurant] = {};
          }
          
          Object.entries(categories).forEach(([category, choice]) => {
            if (!summary[restaurant][category]) {
              summary[restaurant][category] = {};
            }
            
            if (!summary[restaurant][category][choice]) {
              summary[restaurant][category][choice] = 1;
            } else {
              summary[restaurant][category][choice]++;
            }
          });
        });
      });
    });

    setSummary(summary);
  };

  return (
    <Box sx={{ 
      minHeight: '100vh',
      background: 'linear-gradient(180deg, rgba(26,61,143,0.02) 0%, rgba(26,61,143,0.05) 100%)',
      py: { xs: 2, md: 4 },
      pb: { xs: 8, md: 10 }
    }}>
      <Container maxWidth="xl">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography 
            variant="h4" 
            sx={{ 
              color: theme.brandColors.blue,
              fontWeight: 800,
              mb: { xs: 3, md: 4 },
              fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' },
              letterSpacing: '-0.02em',
              textAlign: { xs: 'center', sm: 'left' }
            }}
          >
            Tour Menu Dashboard
          </Typography>

          <Paper 
            elevation={0} 
            sx={{ 
              mb: 3,
              borderRadius: 2,
              overflow: 'hidden',
              backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}
          >
            <Tabs 
              value={selectedTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                '& .MuiTab-root': {
                  minWidth: 'auto',
                  px: 2,
                  py: 1.5,
                  color: theme.brandColors.blue,
                  opacity: 0.7,
                  '&.Mui-selected': {
                    color: theme.brandColors.blue,
                    opacity: 1,
                    fontWeight: 600
                  }
                }
              }}
            >
              {dates.map((date, index) => (
                <Tab 
                  key={index}
                  label={
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="caption" display="block" sx={{ opacity: 0.8 }}>
                        {format(date, 'EEE')}
                      </Typography>
                      <Badge 
                        color="primary" 
                        variant="dot" 
                        invisible={!isToday(date)}
                      >
                        <Typography variant="body2">
                          {format(date, 'MMM d')}
                        </Typography>
                      </Badge>
                    </Box>
                  }
                />
              ))}
            </Tabs>

            <Box sx={{ p: { xs: 2, md: 3 } }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Or select a specific date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  slotProps={{ 
                    textField: { 
                      fullWidth: true,
                      sx: {
                        '& .MuiOutlinedInput-root': {
                          borderColor: theme.brandColors.blue
                        }
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Paper>

          <AnimatePresence mode="wait">
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
                <CircularProgress sx={{ color: theme.brandColors.blue }} />
              </Box>
            ) : (
              <motion.div
                key={selectedDate.toString()}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Grid container spacing={4}>
                  <Grid item xs={12} lg={6}>
                    <Card sx={{ 
                      p: { xs: 3, md: 4 },
                      height: '100%',
                      maxHeight: { xs: 'calc(100vh - 400px)', md: 'calc(100vh - 450px)' },
                      background: 'linear-gradient(145deg, rgba(255,255,255,0.95) 0%, rgba(255,255,255,0.98) 100%)',
                      boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
                      borderRadius: 3,
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                      <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" sx={{ 
                          color: theme.brandColors.blue,
                          fontWeight: 800,
                          mb: 3,
                          fontSize: { xs: '1.5rem', md: '1.75rem' }
                        }}>
                          Summary
                        </Typography>
                        
                        <ToggleButtonGroup
                          value={selectedRestaurants}
                          onChange={handleRestaurantToggle}
                          aria-label="restaurant filter"
                          size="small"
                          sx={{ 
                            flexWrap: 'wrap',
                            gap: 1,
                            '& .MuiToggleButton-root': {
                              border: `1.5px solid ${theme.brandColors.blue}`,
                              color: theme.brandColors.blue,
                              borderRadius: '8px !important',
                              px: 2,
                              py: 1,
                              '&.Mui-selected': {
                                backgroundColor: theme.brandColors.blue,
                                color: 'white',
                                '&:hover': {
                                  backgroundColor: theme.brandColors.blue,
                                  opacity: 0.9
                                }
                              },
                              '&:hover': {
                                backgroundColor: 'rgba(26,61,143,0.05)'
                              }
                            }
                          }}
                        >
                          {availableRestaurants.map((restaurant) => (
                            <ToggleButton 
                              key={restaurant} 
                              value={restaurant}
                              aria-label={restaurant}
                            >
                              <RestaurantIcon sx={{ mr: 1, fontSize: '1.2rem' }} />
                              {restaurant}
                            </ToggleButton>
                          ))}
                        </ToggleButtonGroup>
                      </Box>

                      <Box sx={{ 
                        flexGrow: 1,
                        overflowY: 'auto',
                        pr: 2,
                        mr: -2,
                        '&::-webkit-scrollbar': {
                          width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                          background: 'rgba(0,0,0,0.05)',
                          borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: theme.brandColors.blue,
                          borderRadius: '4px',
                          opacity: 0.8,
                        }
                      }}>
                        {Object.entries(summary)
                          .filter(([restaurant]) => selectedRestaurants.length === 0 || selectedRestaurants.includes(restaurant))
                          .map(([restaurant, categories]) => (
                            <Paper
                              key={restaurant}
                              elevation={0}
                              sx={{ 
                                mb: 3,
                                p: 3,
                                backgroundColor: 'rgba(255,255,255,0.7)',
                                border: '1px solid rgba(26,61,143,0.1)',
                                borderRadius: 2,
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                  backgroundColor: 'rgba(255,255,255,0.9)',
                                  transform: 'translateY(-2px)',
                                }
                              }}
                            >
                              <Typography variant="h6" sx={{ 
                                color: theme.brandColors.blue,
                                fontWeight: 700,
                                mb: 2,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1
                              }}>
                                <RestaurantIcon sx={{ fontSize: '1.4rem' }} />
                                {restaurant}
                              </Typography>
                              {Object.entries(categories).map(([category, choices]) => (
                                <Box key={category} sx={{ mb: 2.5, ml: 2 }}>
                                  <Typography variant="subtitle1" sx={{ 
                                    fontWeight: 600,
                                    color: theme.brandColors.blue,
                                    opacity: 0.9,
                                    mb: 1.5,
                                    borderBottom: '1px solid rgba(26,61,143,0.1)',
                                    pb: 0.5
                                  }}>
                                    {category}
                                  </Typography>
                                  {Object.entries(choices).map(([choice, count]) => (
                                    <Box
                                      key={choice}
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        py: 0.5,
                                        px: 2,
                                        mb: 0.5,
                                        borderRadius: 1,
                                        '&:hover': {
                                          backgroundColor: 'rgba(255,255,255,0.5)'
                                        }
                                      }}
                                    >
                                      <Typography sx={{ 
                                        color: theme.brandColors.blue,
                                        opacity: 0.8,
                                        fontSize: '0.95rem'
                                      }}>
                                        {choice}
                                      </Typography>
                                      <Typography sx={{
                                        color: theme.brandColors.blue,
                                        fontWeight: 600,
                                        fontSize: '0.95rem',
                                        backgroundColor: 'rgba(26,61,143,0.1)',
                                        px: 1.5,
                                        py: 0.5,
                                        borderRadius: 1,
                                        minWidth: '32px',
                                        textAlign: 'center'
                                      }}>
                                        {count}
                                      </Typography>
                                    </Box>
                                  ))}
                                </Box>
                              ))}
                            </Paper>
                          ))}
                      </Box>
                    </Card>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <Card sx={{ 
                      p: { xs: 3, md: 4 },
                      maxHeight: { xs: 'calc(100vh - 400px)', md: 'calc(100vh - 450px)' },
                      background: 'linear-gradient(145deg, rgba(255,255,255,0.95) 0%, rgba(255,255,255,0.98) 100%)',
                      boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
                      borderRadius: 3,
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                      <Typography variant="h5" sx={{ 
                        color: theme.brandColors.blue,
                        fontWeight: 800,
                        mb: 4,
                        fontSize: { xs: '1.5rem', md: '1.75rem' }
                      }}>
                        Individual Bookings
                      </Typography>

                      <Box sx={{ 
                        flexGrow: 1,
                        overflowY: 'auto',
                        pr: 2,
                        mr: -2,
                        '&::-webkit-scrollbar': {
                          width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                          background: 'rgba(0,0,0,0.05)',
                          borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: theme.brandColors.blue,
                          borderRadius: '4px',
                          opacity: 0.8,
                        }
                      }}>
                        {menuChoices.map((booking) => (
                          <Paper 
                            key={booking.id} 
                            elevation={0}
                            sx={{ 
                              p: 3,
                              mb: 3,
                              backgroundColor: 'rgba(255,255,255,0.7)',
                              border: '1px solid rgba(26,61,143,0.1)',
                              borderRadius: 2,
                              transition: 'all 0.3s ease',
                              '&:hover': {
                                backgroundColor: 'rgba(255,255,255,0.9)',
                                transform: 'translateY(-2px)',
                              }
                            }}
                          >
                            <Box sx={{ 
                              display: 'flex', 
                              justifyContent: 'space-between',
                              alignItems: 'flex-start',
                              mb: 3,
                              pb: 2,
                              borderBottom: '1px solid rgba(26,61,143,0.1)'
                            }}>
                              <Box>
                                <Typography variant="h6" sx={{ 
                                  color: theme.brandColors.blue,
                                  fontWeight: 700,
                                  mb: 0.5,
                                  fontSize: '1.2rem'
                                }}>
                                  {booking.customerName}
                                </Typography>
                                <Typography sx={{ 
                                  color: theme.brandColors.blue,
                                  opacity: 0.8,
                                  fontSize: '0.9rem',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1
                                }}>
                                  {booking.customerEmail} • {booking.numGuests} guest{booking.numGuests > 1 ? 's' : ''}
                                </Typography>
                              </Box>
                            </Box>

                            {Object.entries(booking.guestChoices).map(([guestNum, selections], index) => (
                              <Box key={guestNum} sx={{ mb: 3 }}>
                                <Typography sx={{ 
                                  fontWeight: 700,
                                  color: theme.brandColors.blue,
                                  mb: 2,
                                  pb: 1,
                                  borderBottom: `1px solid rgba(26,61,143,0.1)`,
                                  fontSize: '1.1rem'
                                }}>
                                  Guest {index + 1}
                                </Typography>
                                {Object.entries(selections).map(([restaurant, categories]) => (
                                  <Box 
                                    key={restaurant} 
                                    sx={{ 
                                      ml: 2, 
                                      mb: 2,
                                      p: 2,
                                      backgroundColor: 'rgba(255,255,255,0.5)',
                                      borderRadius: 2,
                                      border: '1px solid rgba(26,61,143,0.05)'
                                    }}
                                  >
                                    <Typography sx={{ 
                                      color: theme.brandColors.blue,
                                      fontWeight: 600,
                                      mb: 1.5,
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1,
                                      pb: 1,
                                      borderBottom: '1px solid rgba(26,61,143,0.1)'
                                    }}>
                                      <RestaurantIcon sx={{ fontSize: '1.2rem' }} />
                                      {restaurant}
                                    </Typography>
                                    {Object.entries(categories).map(([category, choice]) => (
                                      <Box
                                        key={category}
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          py: 0.75,
                                          px: 1,
                                          '&:hover': {
                                            backgroundColor: 'rgba(255,255,255,0.5)',
                                            borderRadius: 1
                                          }
                                        }}
                                      >
                                        <Typography sx={{ 
                                          color: theme.brandColors.blue,
                                          opacity: 0.9,
                                          fontWeight: 500,
                                          fontSize: '0.95rem'
                                        }}>
                                          {category}:
                                        </Typography>
                                        <Typography sx={{ 
                                          color: theme.brandColors.blue,
                                          ml: 2,
                                          fontSize: '0.95rem'
                                        }}>
                                          {choice}
                                        </Typography>
                                      </Box>
                                    ))}
                                  </Box>
                                ))}
                              </Box>
                            ))}
                          </Paper>
                        ))}
                        {menuChoices.length === 0 && (
                          <Typography sx={{ 
                            textAlign: 'center',
                            color: theme.brandColors.blue,
                            opacity: 0.7,
                            py: 4
                          }}>
                            No bookings found for this date
                          </Typography>
                        )}
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </Container>
    </Box>
  );
};

export default TourMenu; 