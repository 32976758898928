import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, Paper, Grid, Chip, CircularProgress, Card, 
  CardContent, CardActions, IconButton, Tooltip, Badge, Divider,
  Tabs, Tab, FormControl, Select, MenuItem, InputLabel, ToggleButtonGroup,
  ToggleButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  InputAdornment, TextField
} from '@mui/material';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SendIcon from '@mui/icons-material/Send';
import TodayIcon from '@mui/icons-material/Today';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { format, addDays, startOfDay, endOfDay, isWithinInterval, parseISO, isToday, isSameDay } from 'date-fns';
import { collection, query, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import { useTheme } from '@mui/material/styles';

const WeekView = ({ onViewDetails, onViewChoices, onResendEmail, guestChoices }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [weekData, setWeekData] = useState([]);
  const [toursByDay, setToursByDay] = useState({});
  const [activeDay, setActiveDay] = useState(0); // Track the active day tab
  const [viewMode, setViewMode] = useState('table');
  const [selectedTourType, setSelectedTourType] = useState('all');
  const [tourTypes, setTourTypes] = useState(['all']);
  const [searchTerm, setSearchTerm] = useState('');
  
  useEffect(() => {
    fetchWeekData();
  }, []);
  
  // Generate days for the next week
  const generateWeekDays = () => {
    const today = new Date();
    const days = [];
    
    for (let i = 0; i < 7; i++) {
      const day = addDays(today, i);
      days.push({
        date: day,
        dayOfWeek: format(day, 'EEEE'),
        displayDate: format(day, 'MMM d'),
        isToday: isToday(day),
        shortDay: format(day, 'EEE'),
        tours: []
      });
    }
    
    return days;
  };
  
  const fetchWeekData = async () => {
    try {
      setLoading(true);
      
      // Generate week days
      const weekDays = generateWeekDays();
      setWeekData(weekDays);
      
      // Get today's date and one week ahead
      const today = startOfDay(new Date());
      const oneWeekAhead = endOfDay(addDays(today, 6));
      
      // Query all menu requests
      const requestsQuery = query(
        collection(db, 'menuRequests')
      );
      
      const querySnapshot = await getDocs(requestsQuery);
      
      // Process the tours and group by day
      const tempToursByDay = {};
      const uniqueTourTypes = new Set(['all']);
      
      // Initialize each day with an empty array
      weekDays.forEach(day => {
        const dateKey = format(day.date, 'yyyy-MM-dd');
        tempToursByDay[dateKey] = [];
      });
      
      // Populate with tour data
      querySnapshot.forEach((doc) => {
        const tourData = {
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || new Date()
        };
        
        // Skip if no tour date or invalid date
        if (!tourData.tourDate || tourData.tourDate === 'Invalid Date') {
          return;
        }
        
        // Add to tour types if it's a new type
        if (tourData.tourType) {
          uniqueTourTypes.add(tourData.tourType);
        }
        
        // Try to parse the tour date
        try {
          let tourDate;
          
          if (typeof tourData.tourDate === 'string') {
            tourDate = new Date(tourData.tourDate);
          } else if (tourData.tourDate instanceof Timestamp) {
            tourDate = tourData.tourDate.toDate();
          } else {
            tourDate = tourData.tourDate;
          }
          
          // Skip if invalid after parsing
          if (isNaN(tourDate.getTime())) {
            return;
          }
          
          // Check if tour is in this week
          for (const day of weekDays) {
            if (isSameDay(day.date, tourDate)) {
              const dateKey = format(day.date, 'yyyy-MM-dd');
              tempToursByDay[dateKey].push({
                ...tourData,
                tourDateObj: tourDate
              });
              break;
            }
          }
        } catch (err) {
          console.error('Error processing tour date:', err);
        }
      });
      
      // Sort tours by time if available
      Object.keys(tempToursByDay).forEach(key => {
        tempToursByDay[key].sort((a, b) => a.tourDateObj - b.tourDateObj);
      });
      
      setToursByDay(tempToursByDay);
      setTourTypes(Array.from(uniqueTourTypes));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching week data:', error);
      setLoading(false);
    }
  };
  
  const handleTabChange = (event, newValue) => {
    setActiveDay(newValue);
  };
  
  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };
  
  const handleTourTypeChange = (event) => {
    setSelectedTourType(event.target.value);
  };
  
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  
  // Filter tours based on selected tour type and search term
  const getFilteredTours = (tours) => {
    return tours.filter(tour => {
      const matchesTourType = selectedTourType === 'all' || tour.tourType === selectedTourType;
      
      const matchesSearch = !searchTerm || 
        tour.participantName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tour.participantEmail?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tour.bookingNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tour.tourName?.toLowerCase().includes(searchTerm.toLowerCase());
      
      return matchesTourType && matchesSearch;
    });
  };
  
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
        <CircularProgress />
      </Box>
    );
  }
  
  return (
    <Box>
      {/* Filter Controls */}
      <Paper sx={{ p: 2, mb: 3, borderRadius: '8px' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <FormControl fullWidth size="small">
              <InputLabel id="tour-type-label">Filter by Tour Type</InputLabel>
              <Select
                labelId="tour-type-label"
                value={selectedTourType}
                onChange={handleTourTypeChange}
                label="Filter by Tour Type"
              >
                {tourTypes.map(type => (
                  <MenuItem key={type} value={type}>
                    {type === 'all' ? 'All Tour Types' : type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              size="small"
              placeholder="Search by name, email, booking..."
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={handleViewModeChange}
              aria-label="view mode"
              size="small"
            >
              <ToggleButton value="cards" aria-label="cards view">
                <ViewModuleIcon />
              </ToggleButton>
              <ToggleButton 
                value="table" 
                aria-label="table view"
                sx={{ 
                  fontWeight: 'bold',
                  '&.Mui-selected': {
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark,
                      color: 'white'
                    }
                  }
                }}
              >
                <ViewListIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </Paper>
      
      {/* Day Tabs */}
      <Paper sx={{ mb: 3, borderRadius: '8px', overflow: 'hidden' }}>
        <Tabs 
          value={activeDay}
          onChange={handleTabChange}
          variant="fullWidth"
          sx={{
            '& .MuiTabs-indicator': {
              height: '3px'
            }
          }}
        >
          {weekData.map((day, index) => {
            const dateKey = format(day.date, 'yyyy-MM-dd');
            const allTours = toursByDay[dateKey] || [];
            const filteredTours = getFilteredTours(allTours);
            
            return (
              <Tab 
                key={dateKey}
                label={
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center',
                    py: 1
                  }}>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      mb: 0.5,
                      color: day.isToday ? theme.palette.primary.main : 'inherit',
                      fontWeight: day.isToday ? 'bold' : 'normal'
                    }}>
                      {day.isToday && <TodayIcon fontSize="small" sx={{ mr: 0.5 }} />}
                      <Typography variant="subtitle2" sx={{ fontWeight: day.isToday ? 'bold' : 'normal' }}>
                        {day.shortDay}
                      </Typography>
                    </Box>
                    <Typography variant="caption" sx={{ fontWeight: 'medium' }}>
                      {day.displayDate}
                    </Typography>
                    <Badge 
                      badgeContent={filteredTours.length} 
                      color="secondary"
                      showZero
                      sx={{ 
                        '& .MuiBadge-badge': { 
                          fontSize: '0.7rem', 
                          height: '16px', 
                          minWidth: '16px',
                          top: '2px',
                          right: '-12px'
                        } 
                      }}
                    >
                      <Box sx={{ width: '10px', height: '10px' }} />
                    </Badge>
                  </Box>
                }
                sx={{
                  minHeight: '72px',
                  backgroundColor: day.isToday ? 'rgba(25, 118, 210, 0.08)' : 'transparent',
                  '&.Mui-selected': {
                    color: theme.palette.primary.main,
                    fontWeight: 'bold'
                  }
                }}
              />
            );
          })}
        </Tabs>
      </Paper>
      
      {/* Active Day Content */}
      {weekData.map((day, index) => {
        if (index !== activeDay) return null;
        
        const dateKey = format(day.date, 'yyyy-MM-dd');
        const allTours = toursByDay[dateKey] || [];
        const filteredTours = getFilteredTours(allTours);
        
        return (
          <Box key={dateKey}>
            <Paper 
              elevation={1} 
              sx={{ 
                p: 0, 
                overflow: 'hidden',
                borderRadius: '8px'
              }}
            >
              <Box 
                sx={{ 
                  p: 2, 
                  backgroundColor: theme.palette.primary.main,
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Typography variant="h6" component="div">
                  <strong>{day.dayOfWeek}</strong> - {day.displayDate}
                </Typography>
                
                <Badge 
                  badgeContent={filteredTours.length} 
                  color="secondary"
                  showZero
                  sx={{ '& .MuiBadge-badge': { fontSize: '0.9rem', height: '22px', minWidth: '22px' } }}
                >
                  <Typography variant="body1" sx={{ fontWeight: 'bold', mr: 1 }}>
                    Tours
                  </Typography>
                </Badge>
              </Box>
              
              <Divider />
              
              <Box sx={{ p: 2 }}>
                {filteredTours.length === 0 ? (
                  <Box sx={{ 
                    p: 5, 
                    textAlign: 'center', 
                    color: theme.palette.text.secondary,
                    backgroundColor: 'rgba(0,0,0,0.02)',
                    borderRadius: '4px' 
                  }}>
                    <Typography variant="h6">No tours match your filters</Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Try changing your tour type filter or search term
                    </Typography>
                  </Box>
                ) : viewMode === 'table' ? (
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }} size="small">
                      <TableHead>
                        <TableRow sx={{ 
                          backgroundColor: theme.palette.grey[100],
                          '& th': { 
                            fontWeight: 'bold',
                            whiteSpace: 'nowrap',
                            fontSize: '0.875rem',
                            py: 1.5
                          }
                        }}>
                          <TableCell width="8%"><strong>Time</strong></TableCell>
                          <TableCell width="20%"><strong>Tour</strong></TableCell>
                          <TableCell width="10%"><strong>Type</strong></TableCell>
                          <TableCell width="16%"><strong>Guest</strong></TableCell>
                          <TableCell width="22%"><strong>Email</strong></TableCell>
                          <TableCell width="10%"><strong>Booking #</strong></TableCell>
                          <TableCell width="8%"><strong>Status</strong></TableCell>
                          <TableCell width="6%" align="center"><strong>Actions</strong></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredTours.map((tour) => {
                          const hasSubmitted = guestChoices[tour.bookingNumber] !== undefined;
                          
                          return (
                            <TableRow key={tour.id} sx={{ 
                              '&:nth-of-type(odd)': { backgroundColor: 'rgba(0,0,0,0.02)' },
                              backgroundColor: hasSubmitted ? 'rgba(76, 175, 80, 0.05)' : 'inherit',
                              '&:hover': {
                                backgroundColor: 'rgba(0,0,0,0.04)'
                              },
                              '& td': {
                                py: 1,
                                fontSize: '0.9rem'
                              }
                            }}>
                              <TableCell>
                                <Typography 
                                  variant="body2" 
                                  sx={{ 
                                    fontWeight: 'medium',
                                    backgroundColor: theme.palette.primary.main,
                                    color: 'white',
                                    py: 0.5,
                                    px: 1,
                                    borderRadius: '16px',
                                    display: 'inline-block',
                                    fontSize: '0.75rem',
                                    textAlign: 'center'
                                  }}
                                >
                                  {format(tour.tourDateObj, 'h:mm a')}
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ fontWeight: 'medium' }}>{tour.tourName}</TableCell>
                              <TableCell>
                                <Chip 
                                  size="small" 
                                  label={tour.tourType || 'No Type'} 
                                  color="primary"
                                />
                              </TableCell>
                              <TableCell>{tour.participantName}</TableCell>
                              <TableCell>
                                <Typography 
                                  variant="body2" 
                                  sx={{ 
                                    maxWidth: '100%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                  }}
                                >
                                  {tour.participantEmail}
                                </Typography>
                              </TableCell>
                              <TableCell>{tour.bookingNumber}</TableCell>
                              <TableCell>
                                {hasSubmitted ? (
                                  <Chip 
                                    size="small" 
                                    label="Completed" 
                                    color="success"
                                  />
                                ) : (
                                  <Chip 
                                    size="small" 
                                    label="Pending" 
                                    color="warning"
                                  />
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                  <Tooltip title="View Details">
                                    <IconButton 
                                      size="small" 
                                      onClick={() => onViewDetails(tour)}
                                      color="primary"
                                      sx={{ mx: 0.5 }}
                                    >
                                      <VisibilityIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                  {hasSubmitted ? (
                                    <Tooltip title="View Menu Choices">
                                      <IconButton 
                                        size="small" 
                                        onClick={() => onViewChoices(tour)}
                                        color="secondary"
                                        sx={{ mx: 0.5 }}
                                      >
                                        <RestaurantMenuIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Resend Email">
                                      <IconButton 
                                        size="small" 
                                        onClick={() => onResendEmail(tour)}
                                        color="info"
                                        sx={{ mx: 0.5 }}
                                      >
                                        <SendIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Grid container spacing={3}>
                    {filteredTours.map(tour => {
                      const hasSubmitted = guestChoices[tour.bookingNumber] !== undefined;
                      
                      return (
                        <Grid item xs={12} md={6} lg={4} key={tour.id}>
                          <Card 
                            variant="outlined" 
                            sx={{
                              backgroundColor: hasSubmitted ? 'rgba(76, 175, 80, 0.1)' : 'inherit',
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              borderRadius: '8px',
                              transition: 'box-shadow 0.3s ease',
                              '&:hover': {
                                boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                              }
                            }}
                          >
                            <CardContent sx={{ flexGrow: 1 }}>
                              <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'space-between', 
                                mb: 1,
                                borderBottom: `1px solid ${theme.palette.divider}`,
                                pb: 1
                              }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                  {tour.tourName}
                                </Typography>
                                <Typography 
                                  variant="body2" 
                                  sx={{ 
                                    fontWeight: 'medium',
                                    backgroundColor: theme.palette.primary.main,
                                    color: 'white',
                                    py: 0.5,
                                    px: 1,
                                    borderRadius: '16px',
                                    fontSize: '0.75rem'
                                  }}
                                >
                                  {format(tour.tourDateObj, 'h:mm a')}
                                </Typography>
                              </Box>
                              
                              <Box sx={{ mb: 2 }}>
                                <Chip 
                                  size="small" 
                                  label={tour.tourType || 'No Type'} 
                                  color="primary"
                                  sx={{ mr: 1, mb: 1 }}
                                />
                                {hasSubmitted ? (
                                  <Chip 
                                    size="small" 
                                    label="Menu Completed" 
                                    color="success"
                                    sx={{ mb: 1 }}
                                  />
                                ) : (
                                  <Chip 
                                    size="small" 
                                    label="Menu Pending" 
                                    color="warning"
                                    sx={{ mb: 1 }}
                                  />
                                )}
                              </Box>
                              
                              <Box sx={{
                                p: 1.5,
                                backgroundColor: 'rgba(0,0,0,0.02)',
                                borderRadius: '4px',
                                mb: 2
                              }}>
                                <Typography variant="body2" sx={{ fontWeight: 'medium', mb: 1 }}>
                                  <strong>Guest:</strong> {tour.participantName}
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 1 }}>
                                  <strong>Email:</strong> {tour.participantEmail}
                                </Typography>
                                <Typography variant="body2">
                                  <strong>Booking:</strong> {tour.bookingNumber}
                                </Typography>
                              </Box>
                            </CardContent>
                            
                            <CardActions sx={{ justifyContent: 'flex-end', pt: 0, borderTop: `1px solid ${theme.palette.divider}` }}>
                              <Tooltip title="View Details">
                                <IconButton 
                                  size="small" 
                                  onClick={() => onViewDetails(tour)}
                                  color="primary"
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                              {hasSubmitted && (
                                <Tooltip title="View Menu Choices">
                                  <IconButton 
                                    size="small" 
                                    onClick={() => onViewChoices(tour)}
                                    color="secondary"
                                  >
                                    <RestaurantMenuIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {!hasSubmitted && (
                                <Tooltip title="Resend Email">
                                  <IconButton 
                                    size="small" 
                                    onClick={() => onResendEmail(tour)}
                                    color="info"
                                  >
                                    <SendIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </CardActions>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Box>
            </Paper>
          </Box>
        );
      })}
    </Box>
  );
};

export default WeekView; 