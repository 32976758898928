import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Global, css } from '@emotion/react';

const HeaderVideo = () => {
  const videoRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && videoRef.current) {
          // Start loading the video when it comes into view
          videoRef.current.load();
          observer.unobserve(entry.target);
        }
      });
    }, options);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <>
      <Global
        styles={css`
          .header-video {
            width: 100%;
            height: 100vh;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }
          
          .header-video::-webkit-media-controls {
            display: none !important;
          }
          
          @media (max-width: 600px) {
            .header-video {
              height: 100vh;
            }
          }
        `}
      />
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100vh',
          overflow: 'hidden',
          backgroundColor: '#000', // Dark background while video loads
        }}
      >
        <video
          ref={videoRef}
          className="header-video"
          autoPlay
          loop
          muted
          playsInline
          preload="metadata"
          onLoadedData={() => setIsLoaded(true)}
          style={{
            opacity: isLoaded ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out'
          }}
        >
          <source 
            src="/videos/Curacao-tours-headerfeb2025.mov" 
            type="video/quicktime"
          />
          <source 
            src="/videos/Curacao-tours-headerfeb2025.mov" 
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </Box>
    </>
  );
};

export default HeaderVideo; 