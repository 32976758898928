import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  Container,
  Box,
  Paper,
  TextField,
  Button,
  Grid,
  Divider,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  CircularProgress,
  Alert,
  Checkbox,
  FormGroup
} from '@mui/material';
import { doc, getDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import SignaturePad from 'react-signature-canvas';
import { toast } from 'react-hot-toast';

const WaiverForm = () => {
  const { waiverId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [waiver, setWaiver] = useState(null);
  const [template, setTemplate] = useState(null);
  const [formData, setFormData] = useState({});
  const [signature, setSignature] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const sigPad = useRef(null);

  useEffect(() => {
    const fetchWaiverData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Fetch waiver request from Firestore
        const waiverDoc = await getDoc(doc(db, 'waiverRequests', waiverId));
        
        if (!waiverDoc.exists()) {
          setError('Waiver request not found. The link may be invalid or expired.');
          setLoading(false);
          return;
        }

        const waiverData = waiverDoc.data();
        setWaiver(waiverData);

        // Check if waiver is already completed
        if (waiverData.status === 'completed') {
          setSubmitted(true);
          setLoading(false);
          return;
        }

        // Fetch waiver template from Firestore
        const templateDoc = await getDoc(doc(db, 'waiverTemplates', waiverData.waiverTemplateId));
        
        if (!templateDoc.exists()) {
          setError('Waiver template not found. Please contact support.');
          setLoading(false);
          return;
        }

        const templateData = templateDoc.data();
        setTemplate(templateData);

        // Initialize form data with default values
        const initialFormData = {};
        templateData.content.sections.forEach(section => {
          if (section.type === 'personal_info' && section.fields) {
            section.fields.forEach(field => {
              initialFormData[field.label] = '';
            });
          } else if (section.type === 'age_verification') {
            initialFormData.ageVerification = '';
          }
        });

        // Pre-fill participant name if available
        if (waiverData.participantName) {
          initialFormData['Passenger Name'] = waiverData.participantName;
        }

        setFormData(initialFormData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching waiver data:', err);
        setError('An error occurred while loading the waiver form. Please try again later.');
        setLoading(false);
      }
    };

    fetchWaiverData();
  }, [waiverId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error for this field if it exists
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: null
      }));
    }
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error for this field if it exists
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: null
      }));
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: checked
    }));
    
    // Clear error for this field if it exists
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: null
      }));
    }
  };

  const clearSignature = () => {
    sigPad.current.clear();
    setSignature(null);
    setFormErrors(prev => ({
      ...prev,
      signature: 'Signature is required'
    }));
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    // Validate personal info fields
    template.content.sections.forEach(section => {
      if (section.type === 'personal_info' && section.fields) {
        section.fields.forEach(field => {
          if (field.required && !formData[field.label]) {
            errors[field.label] = `${field.label} is required`;
            isValid = false;
          }
        });
      } else if (section.type === 'age_verification' && section.required) {
        if (!formData.ageVerification) {
          errors.ageVerification = 'Age verification is required';
          isValid = false;
        }
      }
    });

    // Validate acknowledgment
    if (!formData.acknowledgment) {
      errors.acknowledgment = 'You must acknowledge the waiver terms';
      isValid = false;
    }

    // Validate signature
    if (!signature) {
      errors.signature = 'Signature is required';
      isValid = false;
    }

    // Validate date
    if (!formData.date) {
      errors.date = 'Date is required';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Save signature data
    if (sigPad.current && !sigPad.current.isEmpty()) {
      setSignature(sigPad.current.toDataURL());
    }

    if (!validateForm()) {
      toast.error('Please fill in all required fields');
      return;
    }

    try {
      setLoading(true);

      // Update waiver request in Firestore
      const waiverRef = doc(db, 'waiverRequests', waiverId);
      await updateDoc(waiverRef, {
        status: 'completed',
        completedAt: Timestamp.now(),
        formData: formData,
        signature: signature,
        updatedAt: Timestamp.now()
      });

      setSubmitted(true);
      toast.success('Waiver submitted successfully!');
      setLoading(false);
    } catch (err) {
      console.error('Error submitting waiver:', err);
      toast.error('An error occurred while submitting the waiver. Please try again.');
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
        <Button variant="contained" onClick={() => navigate('/')}>
          Return to Home
        </Button>
      </Container>
    );
  }

  if (submitted) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h4" align="center" gutterBottom color="primary">
            Waiver Submitted
          </Typography>
          <Typography variant="body1" paragraph align="center">
            Thank you for completing your waiver for {waiver.tourName}.
          </Typography>
          <Typography variant="body1" paragraph align="center">
            Your waiver has been successfully submitted and recorded in our system.
          </Typography>
          <Typography variant="body1" paragraph align="center">
            Tour Date: {waiver.tourDate}
          </Typography>
          <Box display="flex" justifyContent="center" mt={3}>
            <Button variant="contained" onClick={() => navigate('/')}>
              Return to Home
            </Button>
          </Box>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: { xs: 2, md: 4 } }}>
        {template && (
          <form onSubmit={handleSubmit}>
            <Typography variant="h4" align="center" gutterBottom color="primary">
              {template.content.title}
            </Typography>
            
            <Typography variant="h5" align="center" gutterBottom color="secondary">
              {template.content.subtitle}
            </Typography>
            
            <Typography variant="body1" paragraph align="center">
              Tour Date: {waiver.tourDate}
            </Typography>
            
            <Divider sx={{ my: 3 }} />
            
            {/* Render sections based on template */}
            {template.content.sections.map((section, sectionIndex) => (
              <Box key={`section-${sectionIndex}`} mb={4}>
                {section.type === 'personal_info' && (
                  <Grid container spacing={2}>
                    {section.fields.map((field, fieldIndex) => (
                      <Grid item xs={12} sm={6} key={`field-${fieldIndex}`}>
                        <TextField
                          fullWidth
                          label={field.label}
                          name={field.label}
                          value={formData[field.label] || ''}
                          onChange={handleInputChange}
                          required={field.required}
                          error={!!formErrors[field.label]}
                          helperText={formErrors[field.label]}
                          margin="normal"
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
                
                {section.type === 'notice' && (
                  <Alert severity="info" sx={{ mb: 2 }}>
                    {section.content}
                  </Alert>
                )}
                
                {section.type === 'terms' && (
                  <Box mb={3}>
                    {section.paragraphs.map((paragraph, paragraphIndex) => (
                      <Typography 
                        key={`paragraph-${paragraphIndex}`}
                        variant="body1" 
                        paragraph
                        fontWeight={paragraph.emphasis === 'strong' ? 'bold' : 'normal'}
                        sx={{ 
                          textAlign: 'justify',
                          ...(paragraph.emphasis === 'strong' && { 
                            backgroundColor: 'rgba(255, 0, 0, 0.05)',
                            p: 1,
                            borderRadius: 1
                          })
                        }}
                      >
                        {paragraph.text}
                      </Typography>
                    ))}
                  </Box>
                )}
                
                {section.type === 'age_verification' && (
                  <Box mb={3}>
                    <Typography variant="h6" gutterBottom>
                      {section.title}
                    </Typography>
                    <FormControl component="fieldset" error={!!formErrors.ageVerification}>
                      <RadioGroup
                        name="ageVerification"
                        value={formData.ageVerification || ''}
                        onChange={handleRadioChange}
                      >
                        {section.options.map((option, optionIndex) => (
                          <FormControlLabel
                            key={`option-${optionIndex}`}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                      {formErrors.ageVerification && (
                        <Typography color="error" variant="caption">
                          {formErrors.ageVerification}
                        </Typography>
                      )}
                    </FormControl>
                  </Box>
                )}
                
                {section.type === 'acknowledgment' && (
                  <Box mb={3}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="acknowledgment"
                            checked={formData.acknowledgment || false}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={
                          <Typography 
                            variant="body1"
                            fontWeight={section.emphasis === 'strong' ? 'bold' : 'normal'}
                          >
                            {section.text}
                          </Typography>
                        }
                      />
                    </FormGroup>
                    {formErrors.acknowledgment && (
                      <Typography color="error" variant="caption">
                        {formErrors.acknowledgment}
                      </Typography>
                    )}
                  </Box>
                )}
                
                {section.type === 'signature_section' && (
                  <Box mb={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Date"
                          name="date"
                          type="date"
                          value={formData.date || ''}
                          onChange={handleInputChange}
                          required
                          error={!!formErrors.date}
                          helperText={formErrors.date}
                          InputLabelProps={{ shrink: true }}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom>
                          Signature:
                        </Typography>
                        <Box 
                          sx={{ 
                            border: '1px solid',
                            borderColor: formErrors.signature ? 'error.main' : 'grey.300',
                            borderRadius: 1,
                            backgroundColor: 'white',
                            height: 200,
                            mb: 1
                          }}
                        >
                          <SignaturePad
                            ref={sigPad}
                            canvasProps={{
                              width: '100%',
                              height: 200,
                              className: 'signature-canvas'
                            }}
                            onEnd={() => {
                              setSignature(sigPad.current.toDataURL());
                              setFormErrors(prev => ({
                                ...prev,
                                signature: null
                              }));
                            }}
                          />
                        </Box>
                        {formErrors.signature && (
                          <Typography color="error" variant="caption" display="block" mb={1}>
                            {formErrors.signature}
                          </Typography>
                        )}
                        <Button variant="outlined" size="small" onClick={clearSignature}>
                          Clear Signature
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            ))}
            
            <Box mt={4} display="flex" justifyContent="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Submit Waiver'}
              </Button>
            </Box>
          </form>
        )}
      </Paper>
    </Container>
  );
};

export default WaiverForm; 