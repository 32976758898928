import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

/**
 * Role-based protected route component
 * @param {Object} props
 * @param {JSX.Element} props.children - The child component to render if authorized
 * @param {string|string[]} props.allowedRoles - Role(s) that can access this route
 * @param {string} [props.redirectPath='/'] - Path to redirect to if unauthorized
 */
const RoleBasedRoute = ({ 
  children, 
  allowedRoles, 
  redirectPath = '/' 
}) => {
  const { currentUser, userRoles, hasAnyRole } = useAuth();
  
  // If not authenticated, redirect to homepage
  if (!currentUser) {
    return <Navigate to={redirectPath} />;
  }
  
  // Check if user has required role
  const roles = Array.isArray(allowedRoles) ? allowedRoles : [allowedRoles];
  
  if (!hasAnyRole(roles)) {
    return <Navigate to={redirectPath} />;
  }
  
  // User is authenticated and has required role
  return children;
};

export default RoleBasedRoute; 