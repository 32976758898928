import React, { useRef } from 'react';
import { Grid, Container, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { motion } from 'framer-motion';
import TourCardV2 from '../../components/TourCardV2';
import { useOptimizedTourContext } from '../../context/OptimizedTourContext';
import ExploreIcon from '@mui/icons-material/Explore';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';

const BRAND_COLORS = {
  blue: '#1A3D8F',
  yellow: '#FFD700',
  lightBlue: '#2451B3',
  lightYellow: '#FFE44D'
};

function AllTours() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = matches;
  const { getToursByIds } = useOptimizedTourContext();

  // Add refs for sections
  const islandToursRef = useRef(null);
  const waterToursRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  // Separate tour IDs by category
  const islandTourIds = ['296606', '301652', '304151', '326864', '326870', '327478'];
  const waterTourIds = ['293823', '296605', '311035', '317387'];

  const islandTours = getToursByIds(islandTourIds);
  const waterTours = getToursByIds(waterTourIds);

  const FeatureBox = ({ icon, text, onClick }) => (
    <Box 
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        mb: 2,
        p: 2,
        borderRadius: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.2s, background-color 0.2s',
        cursor: 'pointer',
        '&:hover': {
          transform: 'translateY(-5px)',
          backgroundColor: 'rgba(255, 255, 255, 1)',
        }
      }}
    >
      <Box sx={{ 
        color: BRAND_COLORS.blue,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '2rem'
      }}>
        {icon}
      </Box>
      <Typography variant="body1" sx={{ color: BRAND_COLORS.blue }}>
        {text}
      </Typography>
    </Box>
  );

  const TourSection = ({ title, description, tours }) => (
    <Box sx={{ mb: 8 }}>
      <Typography 
        variant={isMobile ? "h4" : "h3"} 
        component="h2" 
        align="center"
        sx={{ 
          color: BRAND_COLORS.blue,
          fontWeight: 700,
          mb: 3,
          lineHeight: 1.2,
          fontSize: { xs: '1.8rem', sm: '2.2rem', md: '2.8rem' }
        }}
      >
        {title}
      </Typography>
      <Typography 
        variant="body1" 
        align="center"
        sx={{ 
          mb: 6,
          maxWidth: '800px',
          mx: 'auto',
          color: 'text.secondary',
          fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
          lineHeight: 1.6
        }}
      >
        {description}
      </Typography>
      <Grid 
        container 
        spacing={4} 
        justifyContent="center"
      >
        {tours.map((tour) => (
          <Grid 
            item 
            xs={12} 
            sm={6} 
            md={4} 
            key={tour.id}
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <TourCardV2 tour={tour} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Box sx={{ 
        width: '100vw', 
        height: isMobile ? '50vh' : '100vh', 
        position: 'relative',
        marginTop: '-64px',
        marginLeft: 'calc(-50vw + 50%)',
        marginRight: 'calc(-50vw + 50%)',
        overflow: 'hidden'
      }}>
        <motion.img 
          initial={{ scale: 1.1 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1.5 }}
          src="/images/Curacao_Tours_Contact.jpg"
          alt="All Curaçao Tours" 
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }} 
        />
        <motion.div 
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 0.5 }}
          transition={{ duration: 0.8 }}
          style={{
            position: 'absolute',
            top: 0,
            left: -300,
            width: '75%',
            height: '100%',
            backgroundImage: 'url(/images/Overlay.svg)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }} 
        />
      </Box>

      <Box sx={{
        width: '100%',
        background: 'linear-gradient(180deg, rgba(26,61,143,0.1) 0%, rgba(255,255,255,1) 45%, rgba(255,255,255,1) 55%, rgba(26,61,143,0.1) 100%)',
        py: { xs: 4, md: 6 },
        mt: '-1px'
      }}>
        <Container maxWidth="lg" sx={{ mb: 6 }}>
          <Typography 
            variant={isMobile ? "h4" : "h2"} 
            component="h1" 
            align="center"
            gutterBottom 
            sx={{ 
              color: BRAND_COLORS.blue,
              fontWeight: 700,
              mb: 3,
              mt: 4,
              lineHeight: 1.2,
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3.5rem' }
            }}
          >
            All Curaçao Tours
          </Typography>
          <Typography 
            variant={isMobile ? "h5" : "h3"} 
            component="h2" 
            align="center"
            gutterBottom 
            sx={{ 
              color: BRAND_COLORS.blue,
              fontWeight: 500,
              mb: 6,
              opacity: 0.9,
              fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2.5rem' }
            }}
          >
            Discover All Our Amazing Experiences
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <FeatureBox 
                icon={<ExploreIcon fontSize="inherit" />}
                text="Explore our Island Tours"
                onClick={() => scrollToSection(islandToursRef)}
              />
              <FeatureBox 
                icon={<BeachAccessIcon fontSize="inherit" />}
                text="Discover our Water Tours"
                onClick={() => scrollToSection(waterToursRef)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography 
                variant="body1" 
                sx={{ 
                  mb: 4,
                  fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
                  color: 'text.secondary',
                  lineHeight: 1.6
                }}
              >
                Browse through our complete collection of tours and experiences in Curaçao. Each tour is carefully crafted to provide you with authentic and memorable experiences, whether you're seeking adventure, relaxation, or cultural immersion.
              </Typography>
            </Grid>
          </Grid>

          <Box sx={{ mt: 8 }}>
            <Box 
              ref={islandToursRef} 
              id="islandToursSection"
              sx={{ scrollMarginTop: '100px' }}
            >
              <TourSection 
                title="Island Tours"
                description="Discover the hidden gems of Curaçao with our expertly guided island tours. From historic Willemstad to the rugged northern coast, experience the island's rich culture, stunning landscapes, and vibrant communities."
                tours={islandTours}
              />
            </Box>

            <Box 
              ref={waterToursRef} 
              id="waterToursSection"
              sx={{ scrollMarginTop: '100px' }}
            >
              <TourSection 
                title="Water Tours"
                description="Dive into the crystal-clear waters of Curaçao with our exciting water tours. Whether you're snorkeling in vibrant coral reefs, sailing along the pristine coastline, or exploring hidden beaches, our water adventures offer unforgettable experiences for all skill levels."
                tours={waterTours}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </motion.div>
  );
}

export default AllTours;