import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Grid,
  Paper,
  Divider,
  Tab,
  Tabs,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Chip,
  Switch,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText,
} from '@mui/material';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import PreviewIcon from '@mui/icons-material/Preview';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-hot-toast';
import { useTheme } from '@mui/material/styles';
import SignaturePad from 'react-signature-canvas';
import { useParams, useNavigate } from 'react-router-dom';

function WaiverTemplates() {
  const theme = useTheme();
  const { templateId } = useParams();
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [open, setOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    tourId: '',
    content: {
      title: '',
      subtitle: '',
      sections: [
        {
          type: 'personal_info',
          fields: [
            { label: 'Passenger Name', type: 'text', required: true },
            { label: 'Vessel', type: 'text', required: true },
            { label: 'Cabin', type: 'text', required: false }
          ]
        },
        {
          type: 'notice',
          content: 'Please read carefully. This form releases us from liability.'
        },
        {
          type: 'terms',
          paragraphs: [
            {
              text: '',
              emphasis: 'normal'
            }
          ]
        },
        {
          type: 'age_verification',
          title: 'Age Verification',
          required: true,
          options: [
            { value: 'adult', label: 'I am 18 years of age or older' },
            { value: 'minor', label: 'I am under 18 years of age and accompanied by a parent/guardian' }
          ]
        },
        {
          type: 'acknowledgment',
          text: 'I HEREBY ACKNOWLEDGE THAT I HAVE READ THE WAIVER AND RELEASE, UNDERSTAND THE TERMS AND THEIR LEGAL EFFECT AND MY SIGNING CONSTITUTES RELEASE OF VALUABLE RIGHTS.',
          emphasis: 'strong'
        },
        {
          type: 'signature_section',
          fields: [
            { label: 'Date', type: 'date', required: true },
            { label: 'Signature of Participant or Parent/Guardian', type: 'signature', required: true }
          ]
        }
      ]
    },
    language: 'en',
    type: 'standard',
    company: '',
    version: '1.0',
    isActive: true,
    fields: [
      { label: 'Passenger Name', type: 'text', required: true },
      { label: 'Date', type: 'date', required: true },
      { label: 'Signature', type: 'signature', required: true }
    ],
    lastModified: new Date()
  });
  const sigPadRef = useRef();
  const [signatureError, setSignatureError] = useState(false);
  const [formValues, setFormValues] = useState({
    passengerName: '',
    vessel: '',
    cabin: '',
    isAdult: '',
    date: new Date().toISOString().split('T')[0]
  });

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    // If templateId is provided in the URL, open the edit dialog for that template
    if (templateId && templates.length > 0) {
      if (templateId === 'new') {
        // Create a new template
        handleOpen(null);
      } else {
        // Edit existing template
        const template = templates.find(t => t.id === templateId);
        if (template) {
          handleOpen(template);
        }
      }
    }
  }, [templateId, templates]);

  const fetchTemplates = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'waiverTemplates'));
      const templatesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setTemplates(templatesData);
      
      // If templateId is provided but templates haven't been loaded yet
      if (templateId) {
        if (templateId === 'new') {
          // Create a new template
          handleOpen(null);
        } else if (templatesData.length > 0) {
          // Edit existing template
          const template = templatesData.find(t => t.id === templateId);
          if (template) {
            handleOpen(template);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
      toast.error('Failed to load templates');
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleOpen = (template = null) => {
    if (template) {
      setEditingTemplate(template);
      setFormData({
        ...template,
        fields: template.fields || [
          { label: 'Passenger Name', type: 'text', required: true },
          { label: 'Date', type: 'date', required: true },
          { label: 'Signature', type: 'signature', required: true }
        ]
      });
    } else {
      setEditingTemplate(null);
      setFormData({
        name: '',
        tourId: '',
        content: {
          title: '',
          subtitle: '',
          sections: [
            {
              type: 'personal_info',
              fields: [
                { label: 'Passenger Name', type: 'text', required: true },
                { label: 'Vessel', type: 'text', required: true },
                { label: 'Cabin', type: 'text', required: false }
              ]
            },
            {
              type: 'notice',
              content: 'Please read carefully. This form releases us from liability.'
            },
            {
              type: 'terms',
              paragraphs: [
                {
                  text: '',
                  emphasis: 'normal'
                }
              ]
            },
            {
              type: 'age_verification',
              title: 'Age Verification',
              required: true,
              options: [
                { value: 'adult', label: 'I am 18 years of age or older' },
                { value: 'minor', label: 'I am under 18 years of age and accompanied by a parent/guardian' }
              ]
            },
            {
              type: 'acknowledgment',
              text: 'I HEREBY ACKNOWLEDGE THAT I HAVE READ THE WAIVER AND RELEASE, UNDERSTAND THE TERMS AND THEIR LEGAL EFFECT AND MY SIGNING CONSTITUTES RELEASE OF VALUABLE RIGHTS.',
              emphasis: 'strong'
            },
            {
              type: 'signature_section',
              fields: [
                { label: 'Date', type: 'date', required: true },
                { label: 'Signature of Participant or Parent/Guardian', type: 'signature', required: true }
              ]
            }
          ]
        },
        language: 'en',
        type: 'standard',
        company: '',
        version: '1.0',
        isActive: true,
        fields: [
          { label: 'Passenger Name', type: 'text', required: true },
          { label: 'Date', type: 'date', required: true },
          { label: 'Signature', type: 'signature', required: true }
        ],
        lastModified: new Date()
      });
    }
    setOpen(true);
    setActiveTab(0);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingTemplate(null);
    setActiveTab(0);
    
    // If we came from a direct edit URL, navigate back to the templates list
    if (templateId) {
      navigate('/waivers/templates');
    }
  };

  const handlePreview = (template) => {
    setEditingTemplate(template);
    setPreviewOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingTemplate) {
        const templateRef = doc(db, 'waiverTemplates', editingTemplate.id);
        await updateDoc(templateRef, {
          ...formData,
          lastModified: new Date(),
        });
        setTemplates(templates.map(template => 
          template.id === editingTemplate.id 
            ? { ...template, ...formData, lastModified: new Date() }
            : template
        ));
        toast.success('Template updated successfully');
      } else {
        const docRef = await addDoc(collection(db, 'waiverTemplates'), {
          ...formData,
          lastModified: new Date(),
        });
        setTemplates([...templates, { id: docRef.id, ...formData }]);
        toast.success('Template created successfully');
      }
      handleClose();
    } catch (error) {
      console.error('Error saving template:', error);
      toast.error('Failed to save template');
    }
  };

  const handleDelete = async (templateId) => {
    if (window.confirm('Are you sure you want to delete this template?')) {
      try {
        await deleteDoc(doc(db, 'waiverTemplates', templateId));
        setTemplates(templates.filter(template => template.id !== templateId));
        toast.success('Template deleted successfully');
      } catch (error) {
        console.error('Error deleting template:', error);
        toast.error('Failed to delete template');
      }
    }
  };

  const handleDuplicate = (template) => {
    const duplicatedTemplate = {
      ...template,
      name: `${template.name} (Copy)`,
      version: '1.0',
      lastModified: new Date()
    };
    delete duplicatedTemplate.id;
    setFormData(duplicatedTemplate);
    setEditingTemplate(null);
    setOpen(true);
  };

  const clearSignature = () => {
    if (sigPadRef.current) {
      sigPadRef.current.clear();
      setSignatureError(false);
    }
  };

  const validateSignature = () => {
    if (sigPadRef.current && sigPadRef.current.isEmpty()) {
      setSignatureError(true);
      return false;
    }
    setSignatureError(false);
    return true;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const validateForm = () => {
    if (!formValues.passengerName) {
      toast.error('Please enter passenger name');
      return false;
    }
    if (!formValues.vessel) {
      toast.error('Please enter vessel name');
      return false;
    }
    if (!formValues.isAdult) {
      toast.error('Please select age verification option');
      return false;
    }
    if (!formValues.date) {
      toast.error('Please select date');
      return false;
    }
    if (!validateSignature()) {
      return false;
    }
    return true;
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      const signatureData = sigPadRef.current.toDataURL();
      
      // Create the signed waiver document
      const signedWaiver = {
        templateId: editingTemplate.id,
        templateName: editingTemplate.name,
        templateVersion: editingTemplate.version,
        passengerName: formValues.passengerName,
        vessel: formValues.vessel,
        cabin: formValues.cabin,
        isAdult: formValues.isAdult === 'adult',
        date: formValues.date,
        signature: signatureData,
        signedAt: new Date(),
        language: editingTemplate.language
      };

      // Store in Firestore
      await addDoc(collection(db, 'signedWaivers'), signedWaiver);
      
      toast.success('Waiver signed successfully');
      setPreviewOpen(false);
      
      // Reset form
      setFormValues({
        passengerName: '',
        vessel: '',
        cabin: '',
        isAdult: '',
        date: new Date().toISOString().split('T')[0]
      });
      clearSignature();
      
    } catch (error) {
      console.error('Error saving signed waiver:', error);
      toast.error('Failed to save signed waiver');
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 15, mb: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" component="h1" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
          Waiver Templates
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpen()}
          sx={{ bgcolor: 'primary.main' }}
        >
          New Template
        </Button>
      </Box>

      <Grid container spacing={3}>
        {templates.map((template) => (
          <Grid item xs={12} md={6} key={template.id}>
            <Paper 
              elevation={3} 
              sx={{ 
                height: '100%',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 6
                }
              }}
            >
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                    <Box>
                      <Typography variant="h6" component="h2" gutterBottom>
                        {template.name}
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <Chip 
                          label={template.language.toUpperCase()} 
                          size="small" 
                          color="primary" 
                          variant="outlined"
                        />
                        <Chip 
                          label={template.type} 
                          size="small" 
                          color="secondary" 
                          variant="outlined"
                        />
                        {template.company && (
                          <Chip 
                            label={template.company} 
                            size="small" 
                            color="default" 
                            variant="outlined"
                          />
                        )}
                      </Stack>
                    </Box>
                    <Box>
                      <IconButton onClick={() => handlePreview(template)} color="primary" title="Preview">
                        <PreviewIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDuplicate(template)} color="primary" title="Duplicate">
                        <ContentCopyIcon />
                      </IconButton>
                      <IconButton onClick={() => handleOpen(template)} color="primary" title="Edit">
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(template.id)} color="error" title="Delete">
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                    Version: {template.version}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    Last modified: {template.lastModified?.toDate().toLocaleDateString()}
                  </Typography>
                  <Typography variant="body2" sx={{ 
                    maxHeight: '100px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 4,
                    WebkitBoxOrient: 'vertical',
                  }}>
                    {template.content.sections.find(s => s.type === 'terms')?.paragraphs[0].text}
                  </Typography>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Edit/Create Dialog */}
      <Dialog 
        open={open} 
        onClose={handleClose} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          sx: {
            minHeight: '80vh'
          }
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          {editingTemplate ? 'Edit Template' : 'New Template'}
        </DialogTitle>
        <Divider />
        <form onSubmit={handleSubmit}>
          <DialogContent sx={{ pt: 2 }}>
            <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 3 }}>
              <Tab label="Basic Info" />
              <Tab label="Content" />
              <Tab label="Fields" />
            </Tabs>

            {activeTab === 0 && (
              <Stack spacing={3}>
                <TextField
                  label="Template Name"
                  fullWidth
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  required
                />
                <TextField
                  label="Tour ID"
                  fullWidth
                  value={formData.tourId || ''}
                  onChange={(e) => setFormData({ ...formData, tourId: e.target.value })}
                  required
                  helperText="Enter the unique tour identifier"
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Language</InputLabel>
                      <Select
                        value={formData.language}
                        label="Language"
                        onChange={(e) => setFormData({ ...formData, language: e.target.value })}
                      >
                        <MenuItem value="en">English</MenuItem>
                        <MenuItem value="es">Spanish</MenuItem>
                        <MenuItem value="nl">Dutch</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Type</InputLabel>
                      <Select
                        value={formData.type}
                        label="Type"
                        onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                      >
                        <MenuItem value="standard">Standard</MenuItem>
                        <MenuItem value="snorkeling">Snorkeling</MenuItem>
                        <MenuItem value="diving">Diving</MenuItem>
                        <MenuItem value="boat">Boat Tour</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <TextField
                  label="Company"
                  fullWidth
                  value={formData.company}
                  onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Version"
                      fullWidth
                      value={formData.version}
                      onChange={(e) => setFormData({ ...formData, version: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.isActive}
                          onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })}
                        />
                      }
                      label="Active"
                    />
                  </Grid>
                </Grid>
              </Stack>
            )}

            {activeTab === 1 && (
              <Stack spacing={3}>
                <TextField
                  label="Title"
                  fullWidth
                  value={formData.content?.title || ''}
                  onChange={(e) => setFormData({ 
                    ...formData, 
                    content: { 
                      ...formData.content,
                      title: e.target.value 
                    } 
                  })}
                  required
                />
                <TextField
                  label="Subtitle"
                  fullWidth
                  value={formData.content?.subtitle || ''}
                  onChange={(e) => setFormData({ 
                    ...formData, 
                    content: { 
                      ...formData.content,
                      subtitle: e.target.value 
                    } 
                  })}
                />
                <Typography variant="h6" sx={{ mt: 2 }}>Terms and Conditions</Typography>
                {formData.content?.sections?.find(s => s.type === 'terms')?.paragraphs.map((paragraph, index) => (
                  <TextField
                    key={index}
                    label={`Paragraph ${index + 1}${paragraph.emphasis === 'strong' ? ' (Emphasized)' : ''}`}
                    multiline
                    rows={4}
                    fullWidth
                    value={paragraph.text}
                    onChange={(e) => {
                      const newSections = [...formData.content.sections];
                      const termsSection = newSections.find(s => s.type === 'terms');
                      termsSection.paragraphs[index].text = e.target.value;
                      setFormData({
                        ...formData,
                        content: {
                          ...formData.content,
                          sections: newSections
                        }
                      });
                    }}
                  />
                ))}
              </Stack>
            )}

            {activeTab === 2 && (
              <Stack spacing={3}>
                {formData.fields.map((field, index) => (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        label="Field Label"
                        fullWidth
                        value={field.label}
                        onChange={(e) => {
                          const newFields = [...formData.fields];
                          newFields[index].label = e.target.value;
                          setFormData({ ...formData, fields: newFields });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <InputLabel>Field Type</InputLabel>
                        <Select
                          value={field.type}
                          label="Field Type"
                          onChange={(e) => {
                            const newFields = [...formData.fields];
                            newFields[index].type = e.target.value;
                            setFormData({ ...formData, fields: newFields });
                          }}
                        >
                          <MenuItem value="text">Text</MenuItem>
                          <MenuItem value="date">Date</MenuItem>
                          <MenuItem value="signature">Signature</MenuItem>
                          <MenuItem value="checkbox">Checkbox</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={field.required}
                            onChange={(e) => {
                              const newFields = [...formData.fields];
                              newFields[index].required = e.target.checked;
                              setFormData({ ...formData, fields: newFields });
                            }}
                          />
                        }
                        label="Required"
                      />
                    </Grid>
                  </Grid>
                ))}
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setFormData({
                      ...formData,
                      fields: [...formData.fields, { label: '', type: 'text', required: false }]
                    });
                  }}
                >
                  Add Field
                </Button>
              </Stack>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant="contained">
              {editingTemplate ? 'Update' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Preview Dialog */}
      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            minHeight: '90vh',
            maxHeight: '90vh',
            overflowY: 'auto',
            m: 0,
            position: 'fixed',
            bottom: 0,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            '@media (min-width: 600px)': {
              position: 'relative',
              borderRadius: 2,
              m: 3
            }
          }
        }}
      >
        <Box sx={{ bgcolor: 'background.paper' }}>
          {/* Logo Section */}
          <Box sx={{ 
            bgcolor: 'primary.main',
            pt: 4,
            pb: 3,
            px: 2,
            color: 'white',
            textAlign: 'center'
          }}>
            <Box sx={{ 
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              mb: 3
            }}>
              <img 
                src="/images/curacao_tours_white_logo.png" 
                alt="Curacao Tours Logo" 
                style={{ 
                  width: 180,
                  height: 'auto'
                }} 
              />
            </Box>
            <Typography variant="h4" component="h1" sx={{ 
              fontWeight: 'bold',
              mb: 1
            }}>
              {editingTemplate?.content.title}
            </Typography>
            <Typography variant="h6" sx={{ opacity: 0.9 }}>
              {editingTemplate?.content.subtitle}
            </Typography>
          </Box>

          {/* Form Content */}
          <Box sx={{ p: 3 }}>
            <Paper elevation={0} sx={{ p: 3, bgcolor: 'grey.50' }}>
              <Stack spacing={4}>
                {/* Notice */}
                <Typography variant="subtitle1" sx={{ 
                  color: 'text.primary',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  bgcolor: 'grey.100',
                  p: 2,
                  borderRadius: 1,
                  border: 1,
                  borderColor: 'grey.300'
                }}>
                  {editingTemplate?.content?.sections.find(s => s.type === 'notice')?.content}
                </Typography>

                {/* Personal Info Fields */}
                <Box>
                  <Typography variant="h6" sx={{ mb: 2, color: 'primary.main' }}>
                    Personal Information
                  </Typography>
                  <Grid container spacing={2}>
                    {editingTemplate?.content?.sections
                      .find(s => s.type === 'personal_info')
                      ?.fields.map((field, index) => (
                        <Grid item xs={field.label === 'Cabin' ? 6 : 12} key={index}>
                          <TextField
                            name={field.label.toLowerCase().replace(/\s+/g, '')}
                            label={field.label}
                            fullWidth
                            required={field.required}
                            variant="outlined"
                            value={formValues[field.label.toLowerCase().replace(/\s+/g, '')]}
                            onChange={handleInputChange}
                            InputProps={{
                              sx: { bgcolor: 'white' }
                            }}
                          />
                        </Grid>
                    ))}
                  </Grid>
                </Box>

                {/* Terms Sections */}
                <Box>
                  <Typography variant="h6" sx={{ mb: 2, color: 'primary.main' }}>
                    Terms and Conditions
                  </Typography>
                  <Stack spacing={2.5}>
                    {editingTemplate?.content?.sections
                      .find(s => s.type === 'terms')
                      ?.paragraphs.map((paragraph, index) => (
                        <Typography 
                          key={index}
                          sx={{ 
                            bgcolor: paragraph.emphasis === 'strong' ? 'grey.100' : 'white',
                            p: 3,
                            borderRadius: 1,
                            border: 1,
                            borderColor: 'grey.300',
                            color: 'text.primary',
                            fontSize: '1rem',
                            lineHeight: 1.8,
                            letterSpacing: '0.01em',
                            fontWeight: paragraph.emphasis === 'strong' ? 600 : 400,
                            boxShadow: paragraph.emphasis === 'strong' ? 
                              '0 1px 3px rgba(0,0,0,0.12)' : 'none',
                            '& strong': {
                              color: 'text.primary',
                              fontWeight: 600
                            }
                          }}
                        >
                          {paragraph.text}
                        </Typography>
                    ))}
                  </Stack>
                </Box>

                {/* Age Verification */}
                <Box>
                  <Typography variant="h6" sx={{ mb: 2, color: 'primary.main' }}>
                    {editingTemplate?.content?.sections.find(s => s.type === 'age_verification')?.title}
                  </Typography>
                  <Paper sx={{ p: 2, bgcolor: 'white' }}>
                    <FormControl component="fieldset" required error={signatureError && !formValues.isAdult}>
                      <RadioGroup
                        name="isAdult"
                        value={formValues.isAdult}
                        onChange={handleInputChange}
                      >
                        {editingTemplate?.content?.sections
                          .find(s => s.type === 'age_verification')
                          ?.options.map((option, index) => (
                            <FormControlLabel
                              key={index}
                              value={option.value}
                              control={<Radio />}
                              label={option.label}
                            />
                        ))}
                      </RadioGroup>
                      {signatureError && !formValues.isAdult && (
                        <FormHelperText>Please select an option</FormHelperText>
                      )}
                    </FormControl>
                  </Paper>
                </Box>

                {/* Acknowledgment */}
                <Typography 
                  variant="body1"
                  sx={{ 
                    fontWeight: 600,
                    bgcolor: 'grey.100',
                    color: 'text.primary',
                    p: 3,
                    borderRadius: 1,
                    textAlign: 'center',
                    fontSize: '1rem',
                    lineHeight: 1.8,
                    letterSpacing: '0.01em',
                    border: 1,
                    borderColor: 'grey.300',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.12)'
                  }}
                >
                  {editingTemplate?.content?.sections.find(s => s.type === 'acknowledgment')?.text}
                </Typography>

                {/* Signature Section */}
                <Box>
                  <Typography variant="h6" sx={{ mb: 2, color: 'primary.main' }}>
                    Signature
                  </Typography>
                  <Stack spacing={3}>
                    <TextField
                      name="date"
                      label="Date"
                      type="date"
                      fullWidth
                      required
                      value={formValues.date}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      InputProps={{
                        sx: { bgcolor: 'white' }
                      }}
                    />
                    
                    <Box>
                      <Paper 
                        variant="outlined" 
                        sx={{ 
                          bgcolor: 'white',
                          p: 2,
                          border: signatureError ? '1px solid #f44336' : undefined
                        }}
                      >
                        <Box sx={{ 
                          border: '1px solid #e0e0e0',
                          borderRadius: 1,
                          overflow: 'hidden',
                          touchAction: 'none'
                        }}>
                          <SignaturePad
                            ref={sigPadRef}
                            canvasProps={{
                              width: 500,
                              height: 200,
                              style: { 
                                width: '100%', 
                                height: '200px'
                              }
                            }}
                          />
                        </Box>
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                          <Button 
                            variant="outlined"
                            size="small"
                            onClick={clearSignature}
                            startIcon={<DeleteIcon />}
                          >
                            Clear Signature
                          </Button>
                        </Box>
                      </Paper>
                      {signatureError && (
                        <Typography color="error" variant="caption" sx={{ mt: 1, display: 'block' }}>
                          Please provide your signature
                        </Typography>
                      )}
                    </Box>
                  </Stack>
                </Box>

                {/* Submit Button */}
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={handleSubmitForm}
                  sx={{ 
                    mt: 2,
                    py: 2,
                    fontSize: '1.1rem',
                    fontWeight: 'bold'
                  }}
                >
                  I Agree & Sign
                </Button>
              </Stack>
            </Paper>
          </Box>
        </Box>

        <Box sx={{ 
          p: 2, 
          borderTop: 1, 
          borderColor: 'divider',
          position: 'sticky',
          bottom: 0,
          bgcolor: 'background.paper',
          textAlign: 'center'
        }}>
          <Button onClick={() => setPreviewOpen(false)}>
            Close Preview
          </Button>
        </Box>
      </Dialog>
    </Container>
  );
}

export default WaiverTemplates; 