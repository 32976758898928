// src/components/FooterMobile.js
import React from 'react';
import { Box, Typography, Link, Button } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Reviews from '../components/reviews';
import '../App.css';
import { useAuth } from '../context/AuthContext';

function FooterMobile() {
  const { currentUser, logout, hasRole } = useAuth();
  const navigate = useNavigate();
  const isAdmin = currentUser && hasRole('admin');
  
  const handleAuthClick = async () => {
    if (currentUser) {
      // Log out 
      try {
        await logout();
        navigate('/');
      } catch (error) {
        console.error('Logout error:', error);
      }
    } else {
      // Go to login
      navigate('/login');
    }
  };
  
  // Determine button text, color, and icon based on auth state
  const buttonText = currentUser 
    ? isAdmin 
      ? 'Admin Logout' 
      : 'Logout'
    : 'Admin Login';
    
  const buttonIcon = currentUser 
    ? <LogoutIcon sx={{ mr: 1, fontSize: '1.2rem' }} /> 
    : <LoginIcon sx={{ mr: 1, fontSize: '1.2rem' }} />;
    
  const buttonColor = currentUser 
    ? 'rgba(211, 60, 60, 0.5)' // Red with opacity for logout
    : 'rgba(211, 211, 211, 0.35)'; // Light grey for login
    
  return (
    <>
      {/* Reviews component outside of footer structure */}
      <Box sx={{ 
        width: '100%',
        background: 'white',
        position: 'relative',
        zIndex: 2,
        marginBottom: { xs: 0, md: 6 } // No margin on mobile, keep margin on desktop
      }}>
        <Reviews />
      </Box>
      
      {/* Main footer */}
      <Box 
        component="footer" 
        sx={{
          background: 'linear-gradient(135deg, #1A3D8F 0%, #2451B3 100%)',
          color: 'white',
          padding: '2rem 1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        {/* Authentication Buttons */}
        <Box sx={{ 
          width: '100%', 
          maxWidth: '300px', 
          display: 'flex', 
          flexDirection: 'column',
          gap: '0.5rem'
        }}>
          <Button
            variant="contained"
            onClick={handleAuthClick}
            startIcon={buttonIcon}
            sx={{
              backgroundColor: buttonColor,
              color: 'rgba(255, 255, 255, 0.8)',
              fontSize: '1rem',
              fontWeight: 'bold',
              py: 1.5,
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: currentUser 
                  ? 'rgba(211, 60, 60, 0.7)' // Darker red on hover for logout
                  : 'rgba(169, 169, 169, 0.5)', // Darker grey on hover for login
              },
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              textTransform: 'none',
            }}
          >
            {buttonText}
          </Button>
          
          {isAdmin && (
            <Button
              variant="contained"
              component={RouterLink}
              to="/admin"
              startIcon={<DashboardIcon sx={{ fontSize: '1.2rem' }} />}
              sx={{
                backgroundColor: 'rgba(26, 61, 143, 0.5)', // Blue with opacity
                color: 'rgba(255, 255, 255, 0.8)', 
                fontSize: '1rem',
                fontWeight: 'bold',
                py: 1.5,
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: 'rgba(26, 61, 143, 0.7)', // Darker blue on hover
                },
                width: '100%',
                mt: 0.5,
                display: 'flex',
                justifyContent: 'center',
                textTransform: 'none',
              }}
            >
              Admin Dashboard
            </Button>
          )}
        </Box>

        {/* Tours */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
          <Link href="/tour/293823" sx={{ color: 'white', textDecoration: 'none', fontSize: '1.1rem' }}>Beach Escape</Link>
          <Link href="/tour/296605" sx={{ color: 'white', textDecoration: 'none', fontSize: '1.1rem' }}>SeaWorld Explorer</Link>
          <Link href="/tour/296606" sx={{ color: 'white', textDecoration: 'none', fontSize: '1.1rem' }}>Trolley Train City Center</Link>
          <Link href="/tour/301652" sx={{ color: 'white', textDecoration: 'none', fontSize: '1.1rem' }}>Curaçao Island Tour</Link>
          <Link href="/tour/304151" sx={{ color: 'white', textDecoration: 'none', fontSize: '1.1rem' }}>Historic Walking Tour</Link>
        </Box>

        {/* Contact */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%', maxWidth: '300px' }}>
          <Typography variant="h6" sx={{ 
            fontSize: '2.5rem', 
            fontWeight: 800,
            color: 'white',
            mb: 2,
            letterSpacing: '0.5px'
          }}>
            Stay in touch
          </Typography>
          
          <Link 
            href="https://maps.google.com/?q=Seru+di+Mahuma+18-20,+Willemstad,+Curaçao"
            target="_blank"
            sx={{ 
              color: 'white', 
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.5rem'
            }}
          >
            <LocationOnIcon />
            <Typography>Seru di Mahuma #18 - 20</Typography>
          </Link>

          <Link 
            href="tel:+59998699559"
            sx={{ 
              color: 'white', 
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.5rem'
            }}
          >
            <PhoneIcon />
            <Typography>+5999 869 9559</Typography>
          </Link>

          <Link 
            href="mailto:info@fb-tt.com"
            sx={{ 
              color: 'white', 
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.5rem'
            }}
          >
            <EmailIcon />
            <Typography>info@fb-tt.com</Typography>
          </Link>
        </Box>

        {/* Copyright */}
        <Box sx={{ 
          borderTop: '1px solid rgba(255,255,255,0.2)', 
          paddingTop: '1rem',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '0.5rem'
        }}>
          <Typography variant="body2">
            © {new Date().getFullYear()} Curaçao Tours
          </Typography>
          <Typography variant="body2" sx={{ opacity: 0.7 }}>
            Developed by <Link href="https://www.aimakers.co" target="_blank" sx={{ color: 'white' }}>AI Makers</Link>
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default FooterMobile;
