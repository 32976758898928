import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Button,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Alert,
  TextField,
  Avatar,
  Chip,
  IconButton,
  Tooltip,
  Container,
  Card,
  CardContent,
  Divider,
  InputLabel
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BadgeIcon from '@mui/icons-material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import { collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import { useAuth } from '../../context/AuthContext';
import { Link } from 'react-router-dom';

// Role display settings 
const roleConfig = {
  admin: { 
    color: '#d32f2f', 
    label: 'Admin',
    icon: <AdminPanelSettingsIcon fontSize="small" />
  },
  staff: { 
    color: '#1976d2', 
    label: 'Staff',
    icon: <BadgeIcon fontSize="small" />
  },
  external: { 
    color: '#2e7d32', 
    label: 'External',
    icon: <PersonIcon fontSize="small" />
  }
};

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editName, setEditName] = useState('');
  const [editRole, setEditRole] = useState('');
  const { currentUser, updateUserRole } = useAuth();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const usersList = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setUsers(usersList);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to load users. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleRoleChange = async (userId, newRole) => {
    try {
      await updateUserRole(userId, newRole);
      
      // Update the local state
      setUsers(prevUsers =>
        prevUsers.map(user =>
          user.id === userId ? { ...user, role: newRole } : user
        )
      );
    } catch (error) {
      console.error('Error updating role:', error);
      setError('Failed to update user role.');
    }
  };

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setOpenDeleteDialog(true);
  };

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setEditName(user.displayName || '');
    setEditRole(user.role || 'external');
    setOpenEditDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (!selectedUser) return;
      
      await deleteDoc(doc(db, 'users', selectedUser.id));
      setUsers(prevUsers => prevUsers.filter(user => user.id !== selectedUser.id));
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error('Error deleting user:', error);
      setError('Failed to delete user.');
    }
  };

  const handleEditConfirm = async () => {
    try {
      if (!selectedUser) return;
      
      const userRef = doc(db, 'users', selectedUser.id);
      await updateDoc(userRef, {
        displayName: editName,
        role: editRole
      });
      
      // Update the local state
      setUsers(prevUsers =>
        prevUsers.map(user =>
          user.id === selectedUser.id 
            ? { ...user, displayName: editName, role: editRole } 
            : user
        )
      );
      
      setOpenEditDialog(false);
    } catch (error) {
      console.error('Error updating user:', error);
      setError('Failed to update user.');
    }
  };

  // Create an avatar with initial letters of the user's name
  const createAvatar = (name) => {
    if (!name) return '';
    const nameParts = name.split(' ');
    if (nameParts.length > 1) {
      return `${nameParts[0][0]}${nameParts[1][0]}`.toUpperCase();
    }
    return name.substring(0, 2).toUpperCase();
  };

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '60vh' 
      }}>
        <CircularProgress size={60} thickness={4} />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
      <Card elevation={3} sx={{ overflow: 'visible', borderRadius: 2 }}>
        <CardContent sx={{ p: 0 }}>
          <Box sx={{ 
            p: 3, 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8
          }}>
            <Typography variant="h4" component="h1" sx={{ fontWeight: 500 }}>
              User Management
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              component={Link} 
              to="/register"
              startIcon={<PersonAddIcon />}
              sx={{ 
                borderRadius: 2,
                px: 3,
                py: 1,
                boxShadow: 2
              }}
            >
              Add New User
            </Button>
          </Box>

          {error && <Alert severity="error" sx={{ mx: 3, mt: 2 }}>{error}</Alert>}

          <TableContainer sx={{ px: 3, py: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map(user => {
                  const userRole = user.role || 'external';
                  const isCurrentUser = user.id === currentUser?.uid;
                  
                  return (
                    <TableRow 
                      key={user.id} 
                      sx={{ 
                        '&:hover': { 
                          backgroundColor: 'rgba(0, 0, 0, 0.04)' 
                        }
                      }}
                    >
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar 
                            sx={{ 
                              bgcolor: roleConfig[userRole].color,
                              width: 40,
                              height: 40,
                              mr: 2
                            }}
                          >
                            {createAvatar(user.displayName)}
                          </Avatar>
                          <Box>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                              {user.displayName || 'Unnamed User'}
                              {isCurrentUser && (
                                <Chip 
                                  label="You" 
                                  size="small" 
                                  sx={{ ml: 1, height: 20, fontSize: '0.7rem' }}
                                />
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {user.email}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
                          <InputLabel id={`role-label-${user.id}`}>Role</InputLabel>
                          <Select
                            labelId={`role-label-${user.id}`}
                            value={userRole}
                            onChange={(e) => handleRoleChange(user.id, e.target.value)}
                            disabled={isCurrentUser} // Can't change own role
                            label="Role"
                            startAdornment={roleConfig[userRole].icon}
                            sx={{
                              '& .MuiSelect-startAdornment': {
                                marginRight: 1,
                                color: roleConfig[userRole].color
                              }
                            }}
                          >
                            <MenuItem value="admin">Admin</MenuItem>
                            <MenuItem value="staff">Staff</MenuItem>
                            <MenuItem value="external">External</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        {(() => {
                          if (!user.createdAt) return 'N/A';
                          
                          try {
                            const date = new Date(user.createdAt);
                            // Check if date is valid
                            return isNaN(date.getTime()) ? 'N/A' : date.toLocaleDateString();
                          } catch (error) {
                            return 'N/A';
                          }
                        })()}
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Edit User">
                          <IconButton 
                            color="primary"
                            onClick={() => handleEditClick(user)}
                            sx={{ mr: 1 }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={isCurrentUser ? "You cannot delete yourself" : "Delete User"}>
                          <span> {/* Wrapper needed for disabled tooltip */}
                            <IconButton 
                              color="error"
                              disabled={isCurrentUser} // Can't delete self
                              onClick={() => handleDeleteClick(user)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete user <strong>{selectedUser?.displayName || selectedUser?.email}</strong>? 
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleDeleteConfirm} 
            color="error" 
            variant="contained"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit User Dialog */}
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Edit User</DialogTitle>
        <Divider />
        <DialogContent sx={{ pt: 3 }}>
          <TextField
            margin="normal"
            fullWidth
            label="Display Name"
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="edit-role-label">User Role</InputLabel>
            <Select
              labelId="edit-role-label"
              value={editRole}
              onChange={(e) => setEditRole(e.target.value)}
              label="User Role"
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="staff">Staff</MenuItem>
              <MenuItem value="external">External</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2 }}>
          <Button 
            onClick={() => setOpenEditDialog(false)} 
            variant="outlined"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleEditConfirm} 
            color="primary" 
            variant="contained"
            startIcon={<EditIcon />}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UserManagement; 