import React from 'react';
import { 
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, 
  Box, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, 
  Paper
} from '@mui/material';
import { format, isValid } from 'date-fns';
import { useTheme } from '@mui/material/styles';

// Helper function to safely format dates
const safeFormatDate = (dateValue, formatString) => {
  if (!dateValue) return 'N/A';
  
  try {
    const date = typeof dateValue === 'string' ? new Date(dateValue) : dateValue;
    
    // Check if date is valid
    if (!isValid(date)) return 'Date pending';
    
    return format(date, formatString);
  } catch (error) {
    console.warn('Date formatting error:', error, dateValue);
    return 'Date pending';
  }
};

const MenuChoicesDialog = ({ 
  open, 
  onClose, 
  selectedRequest, 
  guestChoices
}) => {
  const theme = useTheme();
  
  if (!selectedRequest || !guestChoices[selectedRequest.bookingNumber]) {
    return null;
  }

  const choiceData = guestChoices[selectedRequest.bookingNumber];

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ bgcolor: theme.brandColors?.green || theme.palette.success.main, color: 'white' }}>
        Guest Menu Choices
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: theme.brandColors?.blue || theme.palette.primary.main }}>
            Booking: {selectedRequest.bookingNumber} - {selectedRequest.participantName}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Submitted on {safeFormatDate(choiceData.submittedAt, 'MMMM d, yyyy h:mm a')}
          </Typography>
        </Box>
        
        <TableContainer component={Paper} variant="outlined">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.grey[100] }}>Guest</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.grey[100] }}>Restaurant</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.grey[100] }}>Category</TableCell>
                <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.grey[100] }}>Selection</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(choiceData.guestChoices || {}).map(([guestNumber, choices]) => (
                Object.entries(choices).map(([restaurantCategory, selection]) => {
                  const [restaurant, category] = restaurantCategory.split('|');
                  return (
                    <TableRow key={`${guestNumber}-${restaurantCategory}`}>
                      <TableCell>Guest {parseInt(guestNumber) + 1}</TableCell>
                      <TableCell>{restaurant}</TableCell>
                      <TableCell>{category}</TableCell>
                      <TableCell>{selection}</TableCell>
                    </TableRow>
                  );
                })
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MenuChoicesDialog; 