import React, { useState, useEffect } from 'react';
import { 
  Container, Typography, Paper, Box, Grid, TextField, Select, MenuItem, 
  FormControl, InputLabel, Button, Tabs, Tab, IconButton
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { collection, query, where, orderBy, getDocs, limit, startAfter } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterListIcon from '@mui/icons-material/FilterList';
import { subDays } from 'date-fns';
import { useTheme } from '@mui/material/styles';

// Import our components
import MenuRequestsTable from './MenuRequestsTable';
import UpcomingToursView from './UpcomingToursView';
import WeekView from './WeekView';
import MenuRequestDetail from './MenuRequestDetail';
import MenuChoicesDialog from './MenuChoicesDialog';

const MenuRequests = () => {
  const theme = useTheme();
  const [menuRequests, setMenuRequests] = useState([]);
  const [guestChoices, setGuestChoices] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [openChoicesDialog, setOpenChoicesDialog] = useState(false);
  
  // Tab state
  const [activeTab, setActiveTab] = useState(0);
  
  // Filters
  const [tourType, setTourType] = useState('');
  const [startDate, setStartDate] = useState(subDays(new Date(), 30));
  const [endDate, setEndDate] = useState(new Date());
  const [completionStatus, setCompletionStatus] = useState('all');
  const [showFilters, setShowFilters] = useState(false);
  
  // Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  // Load menu requests from Firestore
  useEffect(() => {
    fetchMenuRequests();
  }, []);

  const fetchMenuRequests = async (filterApplied = false) => {
    try {
      setLoading(true);
      
      let menuRequestsQuery = query(
        collection(db, 'menuRequests'),
        orderBy('createdAt', 'desc'),
        limit(50)
      );
      
      // Apply filters if requested
      if (filterApplied) {
        // Reset pagination when filters change
        setPage(0);
        setLastVisible(null);
        
        const constraints = [];
        
        if (tourType) {
          constraints.push(where('tourType', '==', tourType));
        }
        
        if (startDate) {
          constraints.push(where('createdAt', '>=', startDate));
        }
        
        if (endDate) {
          // Set end date to end of day
          const endOfDay = new Date(endDate);
          endOfDay.setHours(23, 59, 59, 999);
          constraints.push(where('createdAt', '<=', endOfDay));
        }
        
        if (completionStatus !== 'all') {
          constraints.push(where('status', '==', completionStatus));
        }
        
        // Create a new query with filters
        menuRequestsQuery = query(
          collection(db, 'menuRequests'),
          ...constraints,
          orderBy('createdAt', 'desc'),
          limit(rowsPerPage)
        );
      }
      
      const querySnapshot = await getDocs(menuRequestsQuery);
      
      // Check if we have more results
      setHasMore(querySnapshot.docs.length === rowsPerPage);
      
      // Store the last document for pagination
      if (querySnapshot.docs.length > 0) {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      } else {
        setLastVisible(null);
      }
      
      const requests = [];
      querySnapshot.forEach((doc) => {
        requests.push({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || new Date()
        });
      });
      
      setMenuRequests(requests);
      
      // Fetch all associated guest choices
      await fetchGuestChoices(requests);
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching menu requests:', error);
      setLoading(false);
    }
  };

  const fetchGuestChoices = async (requests) => {
    try {
      // Get booking numbers for all requests
      const bookingNumbers = requests.map(req => req.bookingNumber);
      const choicesMap = {};
      
      // Skip if no booking numbers
      if (bookingNumbers.length === 0) {
        return;
      }
      
      // Query all guest choices for the given booking IDs
      const choicesQuery = query(
        collection(db, 'guestChoices'),
        where('bookingId', 'in', bookingNumbers)
      );
      
      const choicesSnapshot = await getDocs(choicesQuery);
      
      choicesSnapshot.forEach((doc) => {
        const choiceData = doc.data();
        choicesMap[choiceData.bookingId] = {
          id: doc.id,
          ...choiceData,
          submittedAt: choiceData.submittedAt?.toDate() || new Date()
        };
      });
      
      setGuestChoices(choicesMap);
    } catch (error) {
      console.error('Error fetching guest choices:', error);
    }
  };

  const handleViewDetails = (request) => {
    setSelectedRequest(request);
    setOpenDetailDialog(true);
  };

  const handleViewChoices = (request) => {
    setSelectedRequest(request);
    setOpenChoicesDialog(true);
  };

  const handleApplyFilters = () => {
    fetchMenuRequests(true);
    setShowFilters(false);
  };

  const handleResetFilters = () => {
    setTourType('');
    setStartDate(subDays(new Date(), 30));
    setEndDate(new Date());
    setCompletionStatus('all');
    fetchMenuRequests();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    
    // If going forward and we have a lastVisible cursor
    if (newPage > page && lastVisible) {
      loadMoreResults();
    }
  };

  const loadMoreResults = async () => {
    try {
      setLoading(true);
      
      let nextQuery = query(
        collection(db, 'menuRequests'),
        orderBy('createdAt', 'desc'),
        startAfter(lastVisible),
        limit(rowsPerPage)
      );
      
      const querySnapshot = await getDocs(nextQuery);
      
      // Check if we have more results
      setHasMore(querySnapshot.docs.length === rowsPerPage);
      
      // Store the last document for pagination
      if (querySnapshot.docs.length > 0) {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      } else {
        setLastVisible(null);
      }
      
      const newRequests = [];
      querySnapshot.forEach((doc) => {
        newRequests.push({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || new Date()
        });
      });
      
      setMenuRequests([...menuRequests, ...newRequests]);
      
      // Fetch all associated guest choices
      await fetchGuestChoices(newRequests);
      
      setLoading(false);
    } catch (error) {
      console.error('Error loading more results:', error);
      setLoading(false);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchMenuRequests(true);
  };

  const resendMenuEmail = async (request) => {
    try {
      const response = await fetch(`https://us-central1-curacao-tours.cloudfunctions.net/testMenuSelectionEmailForBooking?bookingId=${request.bookingNumber}&testEmail=${request.participantEmail}`);
      const result = await response.json();
      
      if (result.message === 'Test menu selection email sent successfully') {
        alert('Menu selection email resent successfully!');
      } else {
        alert('Error resending menu selection email.');
      }
    } catch (error) {
      console.error('Error resending menu email:', error);
      alert('Error resending menu selection email.');
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: theme.brandColors?.blue || theme.palette.primary.main, fontWeight: 'bold' }}>
          Menu Selection Requests
        </Typography>
        <Box>
          <Button 
            startIcon={<FilterListIcon />} 
            onClick={() => setShowFilters(!showFilters)}
            variant={showFilters ? "contained" : "outlined"}
            sx={{ mr: 1 }}
          >
            Filters
          </Button>
          <Button 
            startIcon={<RefreshIcon />} 
            onClick={() => fetchMenuRequests()}
            variant="outlined"
          >
            Refresh
          </Button>
        </Box>
      </Box>

      {/* Tabs Navigation */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange} 
          aria-label="menu requests tabs"
          variant="fullWidth"
        >
          <Tab label="All Requests" id="tab-0" />
          <Tab label="Upcoming Tours" id="tab-1" />
          <Tab label="Week View" id="tab-2" />
        </Tabs>
      </Box>

      {/* Filters Panel */}
      {showFilters && activeTab === 0 && (
        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Filter Options</Typography>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Tour Type</InputLabel>
                <Select
                  value={tourType}
                  onChange={(e) => setTourType(e.target.value)}
                  label="Tour Type"
                >
                  <MenuItem value="">All Tours</MenuItem>
                  <MenuItem value="beach-escape">Beach Escape</MenuItem>
                  <MenuItem value="island-tour">Island Tour</MenuItem>
                  <MenuItem value="culinary-tour">Culinary Tour</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="From Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="To Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  value={completionStatus}
                  onChange={(e) => setCompletionStatus(e.target.value)}
                  label="Status"
                >
                  <MenuItem value="all">All Statuses</MenuItem>
                  <MenuItem value="sent">Sent</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="outlined" onClick={handleResetFilters} sx={{ mr: 1 }}>
                Reset
              </Button>
              <Button variant="contained" onClick={handleApplyFilters}>
                Apply Filters
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}

      {/* Tab Content */}
      <Box role="tabpanel" hidden={activeTab !== 0}>
        {activeTab === 0 && (
          <MenuRequestsTable 
            menuRequests={menuRequests}
            guestChoices={guestChoices}
            loading={loading}
            page={page}
            rowsPerPage={rowsPerPage}
            hasMore={hasMore}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onViewDetails={handleViewDetails}
            onViewChoices={handleViewChoices}
            onResendEmail={resendMenuEmail}
          />
        )}
      </Box>

      <Box role="tabpanel" hidden={activeTab !== 1}>
        {activeTab === 1 && (
          <UpcomingToursView 
            guestChoices={guestChoices}
            onViewDetails={handleViewDetails}
            onViewChoices={handleViewChoices}
            onResendEmail={resendMenuEmail}
          />
        )}
      </Box>
      
      <Box role="tabpanel" hidden={activeTab !== 2}>
        {activeTab === 2 && (
          <WeekView 
            guestChoices={guestChoices}
            onViewDetails={handleViewDetails}
            onViewChoices={handleViewChoices}
            onResendEmail={resendMenuEmail}
          />
        )}
      </Box>

      {/* Dialogs */}
      <MenuRequestDetail 
        open={openDetailDialog}
        onClose={() => setOpenDetailDialog(false)}
        selectedRequest={selectedRequest}
        guestChoices={guestChoices}
        onResendEmail={resendMenuEmail}
      />
      
      <MenuChoicesDialog 
        open={openChoicesDialog}
        onClose={() => setOpenChoicesDialog(false)}
        selectedRequest={selectedRequest}
        guestChoices={guestChoices}
      />
    </Container>
  );
};

export default MenuRequests; 