import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Tabs,
  Tab,
  TextField,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import {
  Search as SearchIcon,
  Refresh as RefreshIcon,
  PieChart as PieChartIcon,
  ViewList as ViewListIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { collection, query, where, orderBy, limit, getDocs, startAfter, Timestamp, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import { format, isAfter, isBefore, parseISO, addDays, subDays } from 'date-fns';
import { toast } from 'react-hot-toast';
import WaiverDetailView from './WaiverDetailView';
import WaiverStatsCards from './WaiverStatsCards';
import WaiverFilterBar from './WaiverFilterBar';
import WaiverTable from './WaiverTable';
import WaiverCharts from './WaiverCharts';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`waiver-tabpanel-${index}`}
      aria-labelledby={`waiver-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const WaiverDashboard = () => {
  const [waiverRequests, setWaiverRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [tourFilter, setTourFilter] = useState('');
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [selectedWaiver, setSelectedWaiver] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [stats, setStats] = useState({
    total: 0,
    completed: 0,
    pending: 0,
    completionRate: 0,
    tourStats: [],
    dailyStats: []
  });
  const [uniqueTours, setUniqueTours] = useState([]);
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });
  const theme = useMediaQuery(useTheme().breakpoints.down('md'));

  useEffect(() => {
    fetchWaiverRequests();
  }, [statusFilter, tourFilter, startDateFilter, endDateFilter]);

  useEffect(() => {
    if (waiverRequests.length > 0) {
      calculateStats();
      extractUniqueTours();
    }
  }, [waiverRequests]);

  const fetchWaiverRequests = async () => {
    try {
      setLoading(true);
      
      let waiverQuery = collection(db, 'waiverRequests');
      let queryConstraints = [];
      
      // Add filters
      if (statusFilter !== 'all') {
        queryConstraints.push(where('status', '==', statusFilter));
      }
      
      if (tourFilter) {
        queryConstraints.push(where('tourId', '==', tourFilter));
      }
      
      if (startDateFilter) {
        const startTimestamp = Timestamp.fromDate(startDateFilter);
        queryConstraints.push(where('createdAt', '>=', startTimestamp));
      }
      
      if (endDateFilter) {
        const endTimestamp = Timestamp.fromDate(endDateFilter);
        queryConstraints.push(where('createdAt', '<=', endTimestamp));
      }
      
      // Add ordering and limit
      queryConstraints.push(orderBy('createdAt', 'desc'));
      queryConstraints.push(limit(50));
      
      const q = query(waiverQuery, ...queryConstraints);
      const snapshot = await getDocs(q);
      
      if (snapshot.empty) {
        setWaiverRequests([]);
        setHasMore(false);
        setLoading(false);
        return;
      }
      
      const requests = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
        completedAt: doc.data().completedAt?.toDate() || null,
        emailSentAt: doc.data().emailSentAt?.toDate() || null
      }));
      
      setWaiverRequests(requests);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      setHasMore(snapshot.docs.length === 50);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching waiver requests:', error);
      toast.error('Failed to load waiver requests');
      setLoading(false);
    }
  };

  const loadMoreWaiverRequests = async () => {
    if (!lastVisible || !hasMore) return;
    
    try {
      setLoading(true);
      
      let waiverQuery = collection(db, 'waiverRequests');
      let queryConstraints = [];
      
      // Add filters
      if (statusFilter !== 'all') {
        queryConstraints.push(where('status', '==', statusFilter));
      }
      
      if (tourFilter) {
        queryConstraints.push(where('tourId', '==', tourFilter));
      }
      
      if (startDateFilter) {
        const startTimestamp = Timestamp.fromDate(startDateFilter);
        queryConstraints.push(where('createdAt', '>=', startTimestamp));
      }
      
      if (endDateFilter) {
        const endTimestamp = Timestamp.fromDate(endDateFilter);
        queryConstraints.push(where('createdAt', '<=', endTimestamp));
      }
      
      // Add ordering, start after and limit
      queryConstraints.push(orderBy('createdAt', 'desc'));
      queryConstraints.push(startAfter(lastVisible));
      queryConstraints.push(limit(50));
      
      const q = query(waiverQuery, ...queryConstraints);
      const snapshot = await getDocs(q);
      
      if (snapshot.empty) {
        setHasMore(false);
        setLoading(false);
        return;
      }
      
      const newRequests = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
        completedAt: doc.data().completedAt?.toDate() || null,
        emailSentAt: doc.data().emailSentAt?.toDate() || null
      }));
      
      setWaiverRequests(prev => [...prev, ...newRequests]);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      setHasMore(snapshot.docs.length === 50);
      setLoading(false);
    } catch (error) {
      console.error('Error loading more waiver requests:', error);
      toast.error('Failed to load more waiver requests');
      setLoading(false);
    }
  };

  const calculateStats = () => {
    const total = waiverRequests.length;
    const completed = waiverRequests.filter(req => req.status === 'completed').length;
    const pending = total - completed;
    const completionRate = total > 0 ? Math.round((completed / total) * 100) : 0;
    
    // Calculate stats by tour
    const tourMap = new Map();
    waiverRequests.forEach(req => {
      if (!tourMap.has(req.tourName)) {
        tourMap.set(req.tourName, { total: 0, completed: 0, pending: 0 });
      }
      
      const tourStat = tourMap.get(req.tourName);
      tourStat.total += 1;
      
      if (req.status === 'completed') {
        tourStat.completed += 1;
      } else {
        tourStat.pending += 1;
      }
    });
    
    const tourStats = Array.from(tourMap.entries()).map(([tourName, stats]) => ({
      tourName,
      total: stats.total,
      completed: stats.completed,
      pending: stats.pending,
      completionRate: Math.round((stats.completed / stats.total) * 100)
    }));
    
    // Calculate daily stats for the last 7 days
    const dailyMap = new Map();
    const today = new Date();
    
    // Initialize the map with the last 7 days
    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(date.getDate() - i);
      const dateStr = format(date, 'yyyy-MM-dd');
      dailyMap.set(dateStr, { date: dateStr, sent: 0, completed: 0 });
    }
    
    // Fill in the data
    waiverRequests.forEach(req => {
      if (req.createdAt) {
        const dateStr = format(req.createdAt, 'yyyy-MM-dd');
        if (dailyMap.has(dateStr)) {
          const dailyStat = dailyMap.get(dateStr);
          dailyStat.sent += 1;
        }
      }
      
      if (req.completedAt) {
        const dateStr = format(req.completedAt, 'yyyy-MM-dd');
        if (dailyMap.has(dateStr)) {
          const dailyStat = dailyMap.get(dateStr);
          dailyStat.completed += 1;
        }
      }
    });
    
    const dailyStats = Array.from(dailyMap.values());
    
    setStats({
      total,
      completed,
      pending,
      completionRate,
      tourStats,
      dailyStats
    });
  };

  const extractUniqueTours = () => {
    const tourSet = new Set();
    waiverRequests.forEach(req => {
      if (req.tourId && req.tourName) {
        tourSet.add(JSON.stringify({ id: req.tourId, name: req.tourName }));
      }
    });
    
    const tours = Array.from(tourSet).map(tourStr => JSON.parse(tourStr));
    setUniqueTours(tours);
  };

  const handleSearch = () => {
    fetchWaiverRequests();
  };

  const handleRefresh = () => {
    setSearchTerm('');
    setTourFilter('');
    setStartDateFilter(null);
    setEndDateFilter(null);
    setStatusFilter('all');
    fetchWaiverRequests();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    
    if (newPage * rowsPerPage >= waiverRequests.length - 10 && hasMore) {
      loadMoreWaiverRequests();
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
    setPage(0);
  };

  const handleTourFilterChange = (event) => {
    setTourFilter(event.target.value);
    setPage(0);
  };

  const handleViewDetails = (waiver) => {
    setSelectedWaiver(waiver);
    setDetailsOpen(true);
  };

  const handleCloseDetails = () => {
    setDetailsOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAlertClose = () => {
    setAlertInfo({ ...alertInfo, open: false });
  };

  const filteredRequests = waiverRequests.filter(request => {
    if (!searchTerm) return true;
    
    const searchLower = searchTerm.toLowerCase();
    return (
      (request.participantName && request.participantName.toLowerCase().includes(searchLower)) ||
      (request.participantEmail && request.participantEmail.toLowerCase().includes(searchLower)) ||
      (request.tourName && request.tourName.toLowerCase().includes(searchLower)) ||
      (request.bookingNumber && request.bookingNumber.toLowerCase().includes(searchLower))
    );
  });

  const paginatedRequests = filteredRequests.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Waiver Management Dashboard
      </Typography>
      
      <Box sx={{ mb: 4 }}>
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="Overview" icon={<PieChartIcon />} iconPosition="start" />
          <Tab label="Waiver Requests" icon={<ViewListIcon />} iconPosition="start" />
        </Tabs>
      </Box>
      
      {tabValue === 0 && (
        <Box>
          <WaiverStatsCards stats={stats} loading={loading} />
          
          <WaiverCharts 
            stats={stats} 
            tourStats={stats.tourStats} 
            loading={loading} 
          />
          
          <Box sx={{ mb: 3 }}>
            <Typography variant="h5" gutterBottom>
              Recent Waiver Requests
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <WaiverTable 
              waivers={waiverRequests.slice(0, 5)} 
              loading={loading}
              onViewWaiver={handleViewDetails}
              onResendEmail={() => {}}
              onMarkCompleted={() => {}}
              onDeleteWaiver={() => {}}
              hasMore={false}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button 
                variant="text" 
                color="primary" 
                onClick={() => setTabValue(1)}
              >
                View All Waivers
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      
      {tabValue === 1 && (
        <Box>
          <WaiverFilterBar 
            filters={{
              status: statusFilter,
              tourId: tourFilter,
              startDate: startDateFilter,
              endDate: endDateFilter,
              searchTerm: searchTerm
            }}
            setFilters={(filters) => {
              setStatusFilter(filters.status);
              setTourFilter(filters.tourId);
              setStartDateFilter(filters.startDate);
              setEndDateFilter(filters.endDate);
              setSearchTerm(filters.searchTerm);
            }}
            tours={uniqueTours}
            onSearch={handleSearch}
            onClearFilters={() => {
              setStatusFilter('all');
              setTourFilter('');
              setStartDateFilter(null);
              setEndDateFilter(null);
              setSearchTerm('');
            }}
          />
          
          <WaiverTable 
            waivers={filteredRequests} 
            loading={loading}
            onViewWaiver={handleViewDetails}
            onResendEmail={() => {}}
            onMarkCompleted={() => {}}
            onDeleteWaiver={() => {}}
            onLoadMore={loadMoreWaiverRequests}
            hasMore={hasMore}
          />
        </Box>
      )}
      
      <Dialog
        open={detailsOpen}
        onClose={handleCloseDetails}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Waiver Request Details
        </DialogTitle>
        <DialogContent dividers>
          {selectedWaiver && (
            <WaiverDetailView waiver={selectedWaiver} onClose={handleCloseDetails} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetails}>Close</Button>
        </DialogActions>
      </Dialog>
      
      <Snackbar 
        open={alertInfo.open} 
        autoHideDuration={6000} 
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleAlertClose} 
          severity={alertInfo.severity} 
          sx={{ width: '100%' }}
        >
          {alertInfo.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default WaiverDashboard; 