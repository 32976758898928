import React from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip, 
  IconButton, Paper, Tooltip, CircularProgress, TablePagination
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import SendIcon from '@mui/icons-material/Send';
import { format, isValid } from 'date-fns';
import { useTheme } from '@mui/material/styles';

// Helper function to safely format dates
const safeFormatDate = (dateValue, formatString) => {
  if (!dateValue) return 'N/A';
  
  // Special case for "Invalid Date" string
  if (dateValue === 'Invalid Date') {
    return 'Date pending';
  }
  
  try {
    const date = typeof dateValue === 'string' ? new Date(dateValue) : dateValue;
    
    // Check if date is valid
    if (!isValid(date)) return 'Date pending';
    
    return format(date, formatString);
  } catch (error) {
    console.warn('Date formatting error:', error, dateValue);
    return 'Date pending';
  }
};

const MenuRequestsTable = ({ 
  menuRequests, 
  guestChoices,
  loading,
  page,
  rowsPerPage,
  hasMore,
  onPageChange,
  onRowsPerPageChange,
  onViewDetails,
  onViewChoices,
  onResendEmail
}) => {
  const theme = useTheme();

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.primary.light, color: 'white' }}>Booking ID</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.primary.light, color: 'white' }}>Guest</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.primary.light, color: 'white' }}>Tour</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.primary.light, color: 'white' }}>Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.primary.light, color: 'white' }}>Sent Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.primary.light, color: 'white' }}>Status</TableCell>
              <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.primary.light, color: 'white' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && menuRequests.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : menuRequests.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No menu requests found
                </TableCell>
              </TableRow>
            ) : (
              menuRequests.map((request) => {
                const hasSubmitted = guestChoices[request.bookingNumber] !== undefined;
                return (
                  <TableRow key={request.id} hover sx={{ 
                    backgroundColor: hasSubmitted ? 'rgba(76, 175, 80, 0.1)' : 'inherit'
                  }}>
                    <TableCell>{request.bookingNumber || 'N/A'}</TableCell>
                    <TableCell>{request.participantName || 'N/A'}</TableCell>
                    <TableCell>{request.tourName || 'N/A'}</TableCell>
                    <TableCell>{request.tourDate ? safeFormatDate(request.tourDate, 'MMM d, yyyy') : 'N/A'}</TableCell>
                    <TableCell>{request.createdAt ? safeFormatDate(request.createdAt, 'MMM d, yyyy h:mm a') : 'N/A'}</TableCell>
                    <TableCell>
                      {hasSubmitted ? (
                        <Chip 
                          label="Completed" 
                          color="success" 
                          size="small"
                          sx={{ fontWeight: 'bold' }}
                        />
                      ) : (
                        <Chip 
                          label={request.status === 'completed' ? 'Completed' : 'Sent'}
                          color={request.status === 'completed' ? 'success' : 'warning'} 
                          size="small"
                          sx={{ fontWeight: 'bold' }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="View Details">
                        <IconButton 
                          size="small" 
                          onClick={() => onViewDetails(request)}
                          color="primary"
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      {hasSubmitted && (
                        <Tooltip title="View Menu Choices">
                          <IconButton 
                            size="small" 
                            onClick={() => onViewChoices(request)}
                            color="secondary"
                            sx={{ ml: 1 }}
                          >
                            <RestaurantMenuIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {!hasSubmitted && (
                        <Tooltip title="Resend Email">
                          <IconButton 
                            size="small" 
                            onClick={() => onResendEmail(request)}
                            color="info"
                            sx={{ ml: 1 }}
                          >
                            <SendIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={-1} // Unknown count for server-side pagination
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        nextIconButtonProps={{
          disabled: !hasMore
        }}
      />
    </Paper>
  );
};

export default MenuRequestsTable; 