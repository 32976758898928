import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Box, Button, Menu, MenuItem, useTheme, useMediaQuery, Typography, IconButton } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { db } from 'config/firebase/firebase';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { createUrlSlug } from '../utils/urlUtils';

const BRAND_COLORS = {
  blue: '#1A3D8F',
  yellow: '#FFD700',
  lightBlue: '#2451B3',
  lightYellow: '#FFE44D'
};

// Styles object to keep the component clean
const styles = {
  appBar: {
    background: 'white',
    height: '100px',
    top: 0,
    zIndex: 1100,
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
  },
  toolbar: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    height: '100px'
  },
  logo: {
    maxHeight: '250px',
    height: 'auto',
    position: 'absolute',
    top: '-50px',
    zIndex: 2,
    left: '50px'
  },
  navLink: (isActive, theme) => ({
    textDecoration: 'none',
    margin: '0 10px',
    padding: '8px 16px',
    borderRadius: '4px',
    transition: 'all 0.3s ease',
    color: isActive ? 'white' : BRAND_COLORS.blue,
    fontWeight: 600,
    backgroundColor: isActive ? BRAND_COLORS.blue : 'transparent',
    '&:hover': {
      backgroundColor: isActive ? BRAND_COLORS.lightBlue : 'rgba(26,61,143,0.05)',
      transform: 'translateY(-2px)'
    }
  }),
  dropdownButton: (isOpen, theme) => ({
    textDecoration: 'none',
    margin: '0 10px',
    padding: '8px 16px',
    borderRadius: '4px',
    transition: 'all 0.3s ease',
    color: isOpen ? 'white' : BRAND_COLORS.blue,
    backgroundColor: isOpen ? BRAND_COLORS.blue : 'transparent',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: isOpen ? BRAND_COLORS.lightBlue : 'rgba(26,61,143,0.05)',
      transform: 'translateY(-2px)'
    }
  }),
  menuPaper: {
    maxWidth: '1200px',
    width: '90vw',
    marginTop: '8px',
    background: 'linear-gradient(180deg, rgba(26,61,143,0.98) 0%, rgba(36,81,179,0.98) 100%)',
    boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
    borderRadius: '8px',
    backdropFilter: 'blur(10px)'
  },
  menuList: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '16px',
    padding: '24px',
    justifyContent: 'center'
  },
  menuColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  categoryHeader: (theme) => ({
    color: BRAND_COLORS.yellow,
    fontSize: '1.2rem',
    fontWeight: 600,
    padding: '12px 16px',
    borderBottom: `2px solid ${BRAND_COLORS.yellow}`,
    marginBottom: '8px',
    backgroundColor: 'transparent',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'default',
      color: BRAND_COLORS.lightYellow,
      borderBottom: `2px solid ${BRAND_COLORS.lightYellow}`
    }
  }),
  menuItem: {
    color: 'white',
    backgroundColor: 'transparent',
    padding: '10px 16px',
    borderRadius: '4px',
    fontSize: '0.95rem',
    fontWeight: 500,
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.15)',
      transform: 'translateX(8px)',
      color: BRAND_COLORS.yellow
    }
  },
  groupsSection: {
    gridColumn: '1 / -1',
    padding: '24px',
    marginTop: '16px',
    borderTop: '1px solid rgba(255,255,255,0.1)',
    background: 'linear-gradient(180deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.05) 100%)',
    borderRadius: '0 0 8px 8px',
    textAlign: 'center',
    transition: 'all 0.3s ease',
    '&:hover': {
      background: 'linear-gradient(180deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0.08) 100%)'
    },
    '& p': {
      color: 'white'
    }
  }
};

function Header({ margin = '0', height = '100px' }) {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [menuCategories, setMenuCategories] = useState({});

  useEffect(() => {
    const fetchMenuItems = async () => {
      const q = query(collection(db, 'activitySelections'), where('homePage', '==', true));
      const querySnapshot = await getDocs(q);
      const items = await Promise.all(querySnapshot.docs.map(async docSnapshot => {
        const activityDoc = await getDoc(doc(db, 'activityDetails', docSnapshot.id));
        const activityData = activityDoc.exists() ? activityDoc.data() : { title: 'No Title' };
        const titleSlug = createUrlSlug(activityData.titleShort || activityData.title);
        return {
          title: activityData.title,
          link: `/tour/${titleSlug}-${docSnapshot.id}`
        };
      }));

      const categories = {
        'Explore': ['Beach Escape', 'Curaçao Island Tour', 'Historic Walking Tour', 'Trolley Train City Centre'],
        'Water Tours': ['SeaWorld Explorer', 'Half Day Snorkel Tour by Boat', 'Sunset Cruise'],
        'Experiential': ['Punda Vibes', 'Punda Vibes E-Bike experience', 'City e-Bike Tour', 'Shopping Tour', 'Culinary Tour']
      };

      const categorizedItems = Object.keys(categories).reduce((acc, category) => {
        acc[category] = items.filter(item => categories[category].includes(item.title));
        return acc;
      }, {});

      setMenuCategories(categorizedItems);
    };

    fetchMenuItems();
  }, []);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const scrollToTours = () => {
    if (location.pathname !== '/') {
      window.location.href = '/#target-section';
    } else {
      const section = document.getElementById('target-section');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const renderNavLinks = () => (
    <Box sx={{ gridColumn: '2 / 3', display: 'flex', justifyContent: 'center' }}>
      <Button 
        component={Link} 
        to="/" 
        sx={styles.navLink(location.pathname === '/', theme)}
      >
        HOME
      </Button>
      <Button
        onClick={handleClick}
        sx={styles.dropdownButton(isMenuOpen, theme)}
      >
        ALL TOURS {isMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        sx={{
          '.MuiMenu-paper': {
            left: '50% !important',
            transform: 'translateX(-50%) !important',
            maxHeight: 'calc(100vh - 100px)',
            overflowY: 'auto',
            marginTop: '8px'
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ 
          sx: styles.menuPaper
        }}
        MenuListProps={{ 
          sx: styles.menuList 
        }}
      >
        {Object.entries(menuCategories).map(([category, items]) => (
          <Box key={category} sx={styles.menuColumn}>
            <MenuItem sx={{ ...styles.menuItem, ...styles.categoryHeader(theme) }} disableRipple>
              {category}
            </MenuItem>
            {items.map(item => (
              <MenuItem 
                key={item.title} 
                sx={styles.menuItem} 
                onClick={handleClose} 
                component={Link} 
                to={item.link}
              >
                {item.title}
              </MenuItem>
            ))}
          </Box>
        ))}
        <Box sx={styles.groupsSection}>
          <Typography variant="h6" sx={{ 
            color: BRAND_COLORS.yellow,
            fontWeight: 600,
            mb: 2,
            fontSize: '1.4rem',
            textTransform: 'uppercase',
            letterSpacing: '0.5px'
          }}>
            Group Bookings
          </Typography>
          <Typography variant="body1" sx={{ 
            mb: 3,
            opacity: 0.9,
            lineHeight: 1.6,
            fontSize: '1.1rem',
            maxWidth: '800px',
            margin: '0 auto'
          }}>
            Plan your perfect group experience in Curaçao! Whether it's a corporate event, family reunion, or special celebration, 
            we'll create a custom tour package that exceeds your expectations.
          </Typography>
          <Box sx={{ 
            display: 'flex', 
            gap: 3, 
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2
          }}>
            <Typography 
              component="a" 
              href="mailto:groups@fb-tt.com"
              sx={{ 
                color: BRAND_COLORS.yellow,
                textDecoration: 'none',
                fontWeight: 500,
                fontSize: '1.1rem',
                transition: 'all 0.3s ease',
                '&:hover': {
                  color: BRAND_COLORS.lightYellow,
                  transform: 'translateY(-2px)'
                }
              }}
            >
              groups@fb-tt.com
            </Typography>
            <Typography sx={{ color: 'rgba(255,255,255,0.5)' }}>•</Typography>
            <Typography 
              component="a"
              href="tel:+59998699559"
              sx={{ 
                color: BRAND_COLORS.yellow,
                textDecoration: 'none',
                fontWeight: 500,
                fontSize: '1.1rem',
                transition: 'all 0.3s ease',
                '&:hover': {
                  color: BRAND_COLORS.lightYellow,
                  transform: 'translateY(-2px)'
                }
              }}
            >
              +5999 869 9559
            </Typography>
          </Box>
        </Box>
      </Menu>
      <Button
        component={Link}
        to="/local-deals"
        sx={styles.navLink(location.pathname === '/local-deals', theme)}
      >
        LOCAL DEALS
      </Button>
      <Button
        component={Link}
        to="/transfers"
        sx={styles.navLink(location.pathname === '/transfers', theme)}
      >
        TRANSFERS
      </Button>
      <Button
        component={Link}
        to="/contact"
        sx={styles.navLink(location.pathname === '/contact', theme)}
      >
        CONTACT
      </Button>
    </Box>
  );

  return (
    <AppBar position="sticky" color="default" elevation={1} sx={styles.appBar}>
      <Toolbar sx={styles.toolbar}>
        {location.pathname !== '/dashboard' && (
          <Box sx={{ gridColumn: '1 / 2' }}>
            <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
              <img
                src="/images/Curacao_Tours_logo.png"
                alt="Curacao Tours Logo"
                style={styles.logo}
              />
            </Link>
          </Box>
        )}
        {isDesktop && renderNavLinks()}
        {isDesktop && (
          <Box sx={{ gridColumn: '3 / 4', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginRight: '50px' }}>
            <Button
              variant="contained"
              onClick={scrollToTours}
              sx={{
                backgroundColor: '#FFD700',
                color: '#1A3D8F',
                fontWeight: 600,
                px: 3,
                py: 1,
                borderRadius: 50,
                textTransform: 'none',
                fontSize: '0.95rem',
                boxShadow: '0 4px 14px rgba(0, 0, 0, 0.15)',
                '&:hover': {
                  backgroundColor: '#FFE44D',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 20px rgba(0, 0, 0, 0.2)',
                },
                transition: 'all 0.3s ease',
              }}
            >
              Book Now
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;