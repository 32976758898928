import React from 'react';
import { Box, Typography, Button, Card } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createUrlSlug } from '../utils/urlUtils';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const TourCardV2 = ({ tour }) => {
  const navigate = useNavigate();

  if (!tour) return null;

  const { 
    title, 
    titleShort, 
    shortTitle,
    id, 
    images,
    price = 'Price on request',
    shortDescription
  } = tour;

  // Use shortTitle if available, otherwise use title
  const displayTitle = shortTitle || titleShort || title || 'Tour Title';
  
  // Format price to show only the number if it's in "$X per person" format
  const displayPrice = price.includes('per person') 
    ? `$${price.split(' ')[0].replace('$', '')}`
    : price;

  const titleSlug = createUrlSlug(titleShort || title);

  const handleNavigation = () => {
    navigate(`/tour/${titleSlug}-${id}`);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: { xs: '300px', sm: '350px', md: '400px' },
        height: { xs: '300px', sm: '350px', md: '400px' },
        margin: '0 auto', // Center the card
      }}
    >
      <Card
        onClick={handleNavigation}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: { xs: '6px', sm: '8px' },
          overflow: 'hidden',
          cursor: 'pointer',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.02)',
          },
        }}
      >
        {/* Background Image */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${images?.header?.M || ''})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%)',
            },
          }}
        />

        {/* Price Tag */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            bgcolor: 'var(--turquoise-color, #00CED1)',
            p: { xs: 1, sm: 1.5 },
            minWidth: { xs: '60px', sm: '80px' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: '#1A3D8F',
              fontWeight: 'bold',
              fontSize: { xs: '1rem', sm: '1.25rem' },
            }}
          >
            {displayPrice}
          </Typography>
        </Box>

        {/* Title */}
        <Box
          sx={{
            position: 'absolute',
            top: { xs: '50%', sm: '20%' }, // Center vertically on mobile
            left: { xs: '15px', sm: '20px' },
            right: { xs: '15px', sm: '20px' },
            transform: { xs: 'translateY(-50%)', sm: 'none' }, // Center vertically on mobile
            display: 'flex',
            flexDirection: 'column',
            alignItems: { xs: 'center', sm: 'flex-start' }, // Center on mobile
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: 'white',
              fontWeight: 'bold',
              textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
              mb: { xs: 3, sm: 2 },
              fontSize: { xs: '1.6rem', sm: '1.25rem', md: '1.5rem' },
              lineHeight: 1.2,
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            {displayTitle}
          </Typography>
          {shortDescription && (
            <Typography
              variant="body1"
              sx={{
                color: 'white',
                textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                opacity: 0.9,
                fontSize: { xs: '0.8rem', sm: '0.875rem', md: '1rem' },
                display: { xs: 'none', sm: '-webkit-box' },
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            >
              {shortDescription}
            </Typography>
          )}
        </Box>

        {/* Buttons Container */}
        <Box
          sx={{
            position: 'absolute',
            bottom: { xs: '15px', sm: '15px' },
            left: { xs: '15px', sm: '15px' },
            right: { xs: '15px', sm: '15px' },
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' }, // Stack buttons vertically on mobile
            gap: { xs: 2, sm: 1.5 },
            justifyContent: 'center', // Center buttons on both mobile and desktop
            alignItems: { xs: 'center', sm: 'center' }, // Center buttons on both mobile and desktop
          }}
        >
          <Button
            variant="contained"
            onClick={handleNavigation}
            endIcon={<ArrowForwardIcon />}
            sx={{
              bgcolor: 'var(--turquoise-color, #00CED1)',
              color: '#1A3D8F',
              width: { xs: '140px', sm: 'auto' },
              px: { xs: 1.5, sm: 2 },
              py: { xs: 0.5, sm: 0.75 },
              fontSize: { xs: '0.8rem', sm: '0.875rem' },
              borderRadius: '25px',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              '&:hover': {
                bgcolor: 'var(--turquoise-color-light, #20D8DB)',
              },
            }}
          >
            Read More
          </Button>
          <Button
            variant="contained"
            onClick={handleNavigation}
            endIcon={<ArrowForwardIcon />}
            sx={{
              bgcolor: '#1A3D8F',
              color: 'white',
              width: { xs: '140px', sm: 'auto' },
              px: { xs: 1.5, sm: 2 },
              py: { xs: 0.5, sm: 0.75 },
              fontSize: { xs: '0.8rem', sm: '0.875rem' },
              borderRadius: '25px',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              '&:hover': {
                bgcolor: '#2A4DA0',
              },
            }}
          >
            Book Now
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default TourCardV2; 