import React, { createContext, useContext, useMemo } from 'react';
import allToursData from '../data/all-tours.json';
import recommendedToursData from '../data/recommended-tours.json';
import tourImages from '../data/tour-images.json';

const OptimizedTourContext = createContext();

export const useOptimizedTourContext = () => {
  const context = useContext(OptimizedTourContext);
  if (!context) {
    throw new Error('useOptimizedTourContext must be used within an OptimizedTourProvider');
  }
  return context;
};

export const OptimizedTourProvider = ({ children }) => {
  // Convert tours data to array format with images
  const processToursData = (toursObject) => {
    return Object.entries(toursObject).map(([id, tour]) => ({
      id,
      ...tour,
      images: {
        header: {
          M: tourImages[id]?.header || ''
        },
        gallery: tourImages[id]?.gallery || []
      }
    }));
  };

  const getToursByIds = (tourIds) => {
    return tourIds.map(id => {
      const tour = allToursData[id];
      if (!tour) return null;
      return {
        id,
        ...tour,
        images: {
          header: {
            M: tourImages[id]?.header || ''
          },
          gallery: tourImages[id]?.gallery || []
        }
      };
    }).filter(tour => tour !== null);
  };

  // Filter tours for homepage - using a completely different approach
  const homePageTours = useMemo(() => {
    // Define the priority tour IDs that must be included
    const priorityIds = ['296606', '293823', '301652'];
    
    console.log('DEBUG - Priority Tour IDs:', priorityIds);
    
    // First, create the priority tours in exact order
    const priorityTours = priorityIds.map(id => {
      console.log(`DEBUG - Adding priority tour ${id}`);
      return {
        id,
        ...allToursData[id],
        images: {
          header: {
            M: tourImages[id]?.header || ''
          },
          gallery: tourImages[id]?.gallery || []
        }
      };
    });
    
    // Get all homepage tours that are not in the priority list
    const otherHomepageTours = Object.entries(allToursData)
      .filter(([id, tour]) => 
        tour.homePage === true && !priorityIds.includes(id)
      )
      .map(([id, tour]) => ({
        id,
        ...tour,
        images: {
          header: {
            M: tourImages[id]?.header || ''
          },
          gallery: tourImages[id]?.gallery || []
        }
      }));
    
    // Combine priority tours with other homepage tours
    const combinedTours = [...priorityTours, ...otherHomepageTours];
    
    console.log('DEBUG - Final homepage tour order:', combinedTours.map(t => t.id));
    
    return combinedTours;
  }, []);

  const value = {
    allTours: homePageTours,
    recommendedTours: processToursData(recommendedToursData),
    getToursByIds,
    // Keep full tours data available if needed
    fullToursList: processToursData(allToursData)
  };

  return <OptimizedTourContext.Provider value={value}>{children}</OptimizedTourContext.Provider>;
};

export default OptimizedTourContext; 