// src/components/MobileMenu.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  IconButton, 
  Drawer, 
  Box, 
  List, 
  ListItem, 
  ListItemText, 
  useTheme, 
  AppBar, 
  Toolbar, 
  Collapse, 
  Typography,
  Divider,
  Button 
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import ArticleIcon from '@mui/icons-material/Article';
import WeatherWidget from './WeatherWidget';
import { db } from 'config/firebase/firebase';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';
import SettingsIcon from '@mui/icons-material/Settings';

const BRAND_COLORS = {
  blue: '#1A3D8F',
  yellow: '#FFD700',
  lightBlue: '#2451B3',
  lightYellow: '#FFE44D'
};

const MENU_CATEGORIES = {
  'Explore': ['Beach Escape', 'Curaçao Island Tour', 'Historic Walking Tour', 'Trolley Train City Centre'],
  'Water Tours': ['SeaWorld Explorer', 'Half Day Snorkel Tour by Boat', 'Sunset Cruise'],
  'Experiential': ['Punda Vibes', 'Punda Vibes E-Bike experience', 'City e-Bike Tour', 'Shopping Tour', 'Culinary Tour']
};

const styles = {
  menuButton: {
    color: BRAND_COLORS.blue,
    marginLeft: 'auto',
    padding: 1,
    marginRight: 2,
    marginTop: 1,
    '& .MuiSvgIcon-root': { 
      fontSize: '2rem',
      transition: 'transform 0.3s ease',
      '&:hover': {
        transform: 'scale(1.1)'
      }
    }
  },
  closeButton: {
    position: 'absolute',
    right: '16px',
    top: '16px',
    color: BRAND_COLORS.blue,
    '& .MuiSvgIcon-root': { 
      fontSize: '2rem',
      transition: 'transform 0.3s ease',
      '&:hover': {
        transform: 'rotate(90deg)'
      }
    }
  },
  drawer: {
    '& .MuiDrawer-paper': {
      backgroundColor: 'white',
      width: '100vw',
      height: '100vh',
      boxShadow: '0 0 20px rgba(0,0,0,0.1)',
      overflowX: 'hidden'
    }
  },
  menuContent: {
    paddingTop: '24px',
    paddingBottom: '24px',
    paddingLeft: '24px',
    paddingRight: '24px',
    position: 'relative'
  },
  logo: {
    maxWidth: '180px',
    height: 'auto',
    marginBottom: 2
  },
  listItem: {
    margin: '4px 16px',
    borderRadius: '8px',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(26,61,143,0.08)',
      transform: 'translateX(8px)'
    }
  },
  categoryItem: {
    backgroundColor: 'rgba(26,61,143,0.03)',
    margin: '4px 0',
    borderRadius: '8px',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(26,61,143,0.08)'
    }
  },
  categoryText: {
    color: BRAND_COLORS.blue,
    fontWeight: 600,
    fontSize: '1.1rem',
    textTransform: 'uppercase',
    letterSpacing: '0.5px'
  },
  subItem: {
    paddingLeft: 4,
    margin: '2px 16px',
    borderRadius: '8px',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(26,61,143,0.08)',
      transform: 'translateX(8px)'
    }
  },
  itemText: {
    color: BRAND_COLORS.blue,
    fontWeight: 500,
    fontSize: '1rem'
  },
  groupsSection: {
    margin: '16px 0',
    padding: '16px',
    backgroundColor: BRAND_COLORS.blue,
    borderRadius: '12px',
    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  groupContact: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    color: BRAND_COLORS.yellow,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '1rem',
    transition: 'all 0.3s ease',
    '&:hover': {
      color: BRAND_COLORS.lightYellow,
      transform: 'translateY(-2px)'
    }
  },
  weatherSection: {
    margin: '16px 0',
    padding: '16px',
    backgroundColor: 'white',
    borderRadius: '12px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
  },
  mainNavItem: {
    margin: '4px 0',
    borderRadius: '8px',
    backgroundColor: 'rgba(26,61,143,0.03)',
    transition: 'all 0.3s ease',
    padding: '12px 20px',
    '&:hover': {
      backgroundColor: BRAND_COLORS.blue,
      transform: 'translateX(8px)',
      '& .MuiTypography-root': {
        color: 'white'
      }
    }
  },
  mainNavText: {
    color: BRAND_COLORS.blue,
    fontWeight: 600,
    fontSize: '1.1rem',
    textTransform: 'uppercase',
    letterSpacing: '1px'
  }
};

function MobileMenu() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [openCategories, setOpenCategories] = useState({});
  const [menuCategories, setMenuCategories] = useState(
    Object.keys(MENU_CATEGORIES).reduce((acc, category) => {
      acc[category] = [];
      return acc;
    }, {})
  );
  const { currentUser, logout, hasRole } = useAuth();

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const q = query(collection(db, 'activitySelections'), where('homePage', '==', true));
        const querySnapshot = await getDocs(q);
        const items = await Promise.all(querySnapshot.docs.map(async docSnapshot => {
          const activityDoc = await getDoc(doc(db, 'activityDetails', docSnapshot.id));
          return {
            title: activityDoc.exists() ? activityDoc.data().title : 'No Title',
            link: `/tour/${docSnapshot.id}`
          };
        }));

        const categorizedItems = Object.keys(MENU_CATEGORIES).reduce((acc, category) => {
          acc[category] = items.filter(item => MENU_CATEGORIES[category].includes(item.title));
          return acc;
        }, {});

        setMenuCategories(categorizedItems);
      } catch (error) {
        console.error('Error fetching menu items:', error);
      }
    };

    fetchMenuItems();
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event?.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };

  const handleCategoryClick = (category) => {
    setOpenCategories(prev => ({ ...prev, [category]: !prev[category] }));
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      setIsOpen(false);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const renderAuthSection = () => {
    if (currentUser) {
      const isAdmin = hasRole('admin');
      const isStaff = hasRole('staff');
      
      return (
        <>
          <Box sx={{ p: 2, bgcolor: 'rgba(26,61,143,0.05)', borderRadius: 2, mb: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: BRAND_COLORS.blue }}>
              {currentUser.displayName || currentUser.email}
            </Typography>
          </Box>
          
          <Divider sx={{ my: 2 }} />
          
          <ListItem 
            button 
            component={Link} 
            to="/dashboard"
            onClick={toggleDrawer(false)}
            sx={styles.mainNavItem}
          >
            <DashboardIcon sx={{ mr: 2, color: BRAND_COLORS.blue }} />
            <ListItemText 
              primary="Dashboard"
              primaryTypographyProps={{ sx: styles.mainNavText }}
            />
          </ListItem>
          
          {isAdmin && (
            <ListItem 
              button 
              component={Link} 
              to="/admin"
              onClick={toggleDrawer(false)}
              sx={styles.mainNavItem}
            >
              <SettingsIcon sx={{ mr: 2, color: BRAND_COLORS.blue }} />
              <ListItemText 
                primary="Admin Panel"
                primaryTypographyProps={{ sx: styles.mainNavText }}
              />
            </ListItem>
          )}
          
          {isAdmin && (
            <ListItem 
              button 
              component={Link} 
              to="/user-management"
              onClick={toggleDrawer(false)}
              sx={styles.mainNavItem}
            >
              <PeopleIcon sx={{ mr: 2, color: BRAND_COLORS.blue }} />
              <ListItemText 
                primary="User Management"
                primaryTypographyProps={{ sx: styles.mainNavText }}
              />
            </ListItem>
          )}
          
          {(isAdmin || isStaff) && (
            <ListItem 
              button 
              component={Link} 
              to="/waivers"
              onClick={toggleDrawer(false)}
              sx={styles.mainNavItem}
            >
              <ArticleIcon sx={{ mr: 2, color: BRAND_COLORS.blue }} />
              <ListItemText 
                primary="Waivers"
                primaryTypographyProps={{ sx: styles.mainNavText }}
              />
            </ListItem>
          )}
          
          <ListItem 
            button 
            onClick={handleLogout}
            sx={{
              ...styles.mainNavItem,
              bgcolor: 'rgba(211, 211, 211, 0.2)',
              borderRadius: '8px',
              mb: 2,
            }}
          >
            <LogoutIcon sx={{ mr: 2, color: BRAND_COLORS.blue }} />
            <ListItemText 
              primary="Logout"
              primaryTypographyProps={{ sx: styles.mainNavText }}
            />
          </ListItem>
        </>
      );
    }
    
    return (
      <ListItem 
        button 
        component={Link} 
        to="/login"
        onClick={toggleDrawer(false)}
        sx={{
          ...styles.mainNavItem,
          bgcolor: 'white',
          border: `2px solid ${BRAND_COLORS.blue}`,
          borderRadius: '8px',
          mb: 2,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <LoginIcon sx={{ mr: 2, color: BRAND_COLORS.blue }} />
        <ListItemText 
          primary="LOGIN"
          primaryTypographyProps={{ sx: styles.mainNavText }}
        />
      </ListItem>
    );
  };

  return (
    <>
      <AppBar position="fixed" elevation={0} sx={{ background: 'transparent' }}>
        <Toolbar disableGutters>
          <IconButton
            edge="end"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={styles.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer 
        anchor="left" 
        open={isOpen} 
        onClose={toggleDrawer(false)}
        sx={styles.drawer}
      >
        <Box sx={styles.menuContent}>
          <IconButton
            onClick={toggleDrawer(false)}
            sx={styles.closeButton}
          >
            <CloseIcon />
          </IconButton>

          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
            <img src="/images/Curacao_Tours_logo.png" alt="Curacao Tours Logo" style={styles.logo} />
          </Box>

          {renderAuthSection()}

          <List>
            <ListItem 
              button 
              component={Link} 
              to="/"
              onClick={toggleDrawer(false)}
              sx={styles.mainNavItem}
            >
              <ListItemText 
                primary="HOME" 
                primaryTypographyProps={{ sx: styles.mainNavText }}
              />
            </ListItem>

            {Object.entries(menuCategories).map(([category, items]) => (
              <React.Fragment key={category}>
                <ListItem 
                  button 
                  onClick={() => handleCategoryClick(category)}
                  sx={styles.categoryItem}
                >
                  <ListItemText 
                    primary={category}
                    primaryTypographyProps={{ sx: styles.categoryText }}
                  />
                  {openCategories[category] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openCategories[category]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {items.map((item) => (
                      <ListItem 
                        button 
                        key={item.title} 
                        component={Link} 
                        to={item.link}
                        onClick={toggleDrawer(false)}
                        sx={styles.subItem}
                      >
                        <ListItemText 
                          primary={item.title}
                          primaryTypographyProps={{ sx: styles.itemText }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}

            <ListItem 
              button 
              component={Link} 
              to="/local-deals"
              onClick={toggleDrawer(false)}
              sx={styles.mainNavItem}
            >
              <ListItemText 
                primary="LOCAL DEALS"
                primaryTypographyProps={{ sx: styles.mainNavText }}
              />
            </ListItem>

            <ListItem 
              button 
              component={Link} 
              to="/transfers"
              onClick={toggleDrawer(false)}
              sx={styles.mainNavItem}
            >
              <ListItemText 
                primary="TRANSFERS"
                primaryTypographyProps={{ sx: styles.mainNavText }}
              />
            </ListItem>

            <ListItem 
              button 
              component={Link} 
              to="/contact"
              onClick={toggleDrawer(false)}
              sx={styles.mainNavItem}
            >
              <ListItemText 
                primary="CONTACT"
                primaryTypographyProps={{ sx: styles.mainNavText }}
              />
            </ListItem>
          </List>

          <Box sx={styles.weatherSection}>
            <WeatherWidget />
          </Box>

          <Box sx={styles.groupsSection}>
            <Typography variant="h6" sx={{ 
              color: BRAND_COLORS.yellow,
              fontWeight: 600,
              fontSize: '1.1rem',
              textTransform: 'uppercase',
              letterSpacing: '0.5px'
            }}>
              Group Bookings
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Typography 
                component="a"
                href="mailto:groups@fb-tt.com"
                sx={styles.groupContact}
              >
                groups@fb-tt.com
              </Typography>
              <Typography 
                component="a"
                href="tel:+59998699559"
                sx={styles.groupContact}
              >
                +5999 869 9559
              </Typography>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

export default MobileMenu;