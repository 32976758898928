import React, { useState, useMemo } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Container,
  useTheme,
  useMediaQuery
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { motion, AnimatePresence } from 'framer-motion';
import TourCardV2 from './TourCardV2';
import { useOptimizedTourContext } from '../context/OptimizedTourContext';

const DoubleTourCarousel = ({
  title,
  description,
  textPosition = 'left',
  tourIds = [],
  actionButtonText = 'Explore sea tours',
  onActionClick = () => {},
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentPair, setCurrentPair] = useState(0);
  const { getToursByIds } = useOptimizedTourContext();

  // Constants for card sizing
  const CARD_WIDTH = isMobile ? window.innerWidth - 80 : 400; // Subtract space for arrows
  const CARD_GAP = isMobile ? 0 : 32; // No gap needed on mobile since we show one at a time

  // Get selected tours
  const selectedTours = useMemo(() => 
    getToursByIds(tourIds),
    [getToursByIds, tourIds]
  );

  // Calculate number of items to show
  const itemsPerView = isMobile ? 1 : 2;
  const numPages = Math.ceil(selectedTours.length / itemsPerView);

  // Get current tours
  const currentTours = useMemo(() => {
    const startIdx = currentPair * itemsPerView;
    return selectedTours.slice(startIdx, startIdx + itemsPerView);
  }, [selectedTours, currentPair, itemsPerView]);

  const handleNext = () => {
    setCurrentPair((prev) => (prev + 1) % numPages);
  };

  const handlePrev = () => {
    setCurrentPair((prev) => (prev - 1 + numPages) % numPages);
  };

  if (selectedTours.length === 0) return null;

  const TextContent = () => (
    <Box
      sx={{
        width: { xs: '100%', sm: '30%' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        pr: textPosition === 'left' ? { xs: 0, sm: 4 } : 0,
        pl: textPosition === 'right' ? { xs: 0, sm: 4 } : 0,
        mb: { xs: 3, sm: 0 },
      }}
    >
      {!isMobile && (
        <Typography 
          variant="body1" 
          sx={{ 
            color: 'text.secondary', 
            mb: 6, 
            fontSize: '1rem', 
            lineHeight: 1.8 
          }}
        >
          {description}
        </Typography>
      )}
      {!isMobile && (
        <Box
          onClick={onActionClick}
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            bgcolor: theme.brandColors?.yellow || '#FFD700',
            color: theme.brandColors?.blue || '#1A3D8F',
            py: 2,
            px: 4,
            borderRadius: '25px',
            fontWeight: 'bold',
            cursor: 'pointer',
            width: 'fit-content',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            '&:hover': { transform: 'scale(1.05)' },
            transition: 'transform 0.3s ease',
          }}
        >
          {actionButtonText}
          <ChevronRightIcon sx={{ ml: 1, fontSize: 18 }} />
        </Box>
      )}
    </Box>
  );

  const CarouselContent = () => (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: { xs: 2, sm: 4 },
        px: { xs: 0, sm: 0 }, // Remove padding on mobile
      }}
    >
      <IconButton
        onClick={handlePrev}
        sx={{
          width: { xs: 40, sm: 80 }, // Reduce width on mobile
          height: { xs: 40, sm: 80 }, // Reduce height on mobile
          '&:hover': { 
            transform: 'scale(1.1)',
          },
          transition: 'all 0.3s ease',
          position: 'absolute',
          left: { xs: '10px', sm: '-40px' }, // Adjust left position on mobile
          zIndex: 2,
        }}
      >
        <ChevronLeftIcon sx={{ 
          fontSize: { xs: 30, sm: 70 }, // Reduce icon size on mobile
          color: theme.brandColors?.blue || '#1A3D8F',
        }} />
      </IconButton>

      <Box 
        sx={{ 
          display: 'flex',
          width: isMobile 
            ? `${CARD_WIDTH}px`
            : `${(CARD_WIDTH * 2) + CARD_GAP}px`,
          overflow: 'hidden',
          mx: 'auto',
          position: 'relative', // Add relative positioning
          left: '0', // Ensure no offset
        }}
      >
        <AnimatePresence mode="wait">
          <motion.div
            key={currentPair}
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -200 }}
            transition={{ duration: 0.3 }}
            style={{ 
              display: 'flex', 
              gap: `${CARD_GAP}px`,
              width: '100%',
            }}
          >
            {currentTours.map((tour) => (
              <Box 
                key={tour.id} 
                sx={{
                  width: CARD_WIDTH,
                  flex: 'none',
                }}
              >
                <TourCardV2 tour={tour} />
              </Box>
            ))}
          </motion.div>
        </AnimatePresence>
      </Box>

      <IconButton
        onClick={handleNext}
        sx={{
          width: { xs: 40, sm: 80 }, // Reduce width on mobile
          height: { xs: 40, sm: 80 }, // Reduce height on mobile
          '&:hover': { 
            transform: 'scale(1.1)',
          },
          transition: 'all 0.3s ease',
          position: 'absolute',
          right: { xs: '10px', sm: '-40px' }, // Adjust right position on mobile
          zIndex: 2,
        }}
      >
        <ChevronRightIcon sx={{ 
          fontSize: { xs: 30, sm: 70 }, // Reduce icon size on mobile
          color: theme.brandColors?.blue || '#1A3D8F',
        }} />
      </IconButton>
    </Box>
  );

  return (
    <Container 
      maxWidth="xl" 
      sx={{ 
        py: { xs: 4, sm: 8 }, 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        gap: { xs: 2, sm: 4 }
      }}
    >
      {/* Title always at the top */}
      <Typography 
        variant="h2" 
        sx={{ 
          color: theme.brandColors?.blue || '#1A3D8F', 
          fontWeight: 'bold', 
          mb: { xs: 1, sm: 2 },
          fontSize: { xs: '2rem', sm: '2.5rem' },
          textAlign: 'center',
          width: '100%'
        }}
      >
        {title}
      </Typography>

      {/* Content section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          alignItems: 'center',
          gap: { xs: 1, sm: 4 }
        }}
      >
        {textPosition === 'left' ? (
          <>
            <TextContent />
            <CarouselContent />
          </>
        ) : (
          <>
            <CarouselContent />
            <TextContent />
          </>
        )}
      </Box>
    </Container>
  );
};

export default DoubleTourCarousel;