import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Box, Container, Card, useTheme } from '@mui/material';
import TourCardV2 from './TourCardV2';
import recommendedTours from '../data/recommended-tours.json';

function RecommendedTours({ excludeTourId }) {
  const [tours, setTours] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    try {
      // Convert tours object to array and filter out the excluded tour
      const toursArray = Object.entries(recommendedTours)
        .filter(([id]) => id !== excludeTourId)
        .map(([id, data]) => ({
          id,
          ...data
        }));

      // Randomly select up to 3 tours from the filtered list
      const shuffledTours = [...toursArray].sort(() => Math.random() - 0.5);
      const selectedTours = shuffledTours.slice(0, 3);

      setTours(selectedTours);
    } catch (error) {
      console.error('Error loading recommended tour data:', error);
    }
  }, [excludeTourId]);

  if (tours.length === 0) {
    return null;
  }

  return (
    <Card
      sx={{
        background: 'linear-gradient(180deg, rgba(26,61,143,0.05) 0%, rgba(26,61,143,0.1) 100%)',
        py: { xs: 4, md: 6 },
        mt: 0,
        mb: 0,
        borderRadius: { xs: '24px 24px 0 0', md: '24px 24px 0 0' },
        boxShadow: 'none',
        position: 'static'
      }}
    >
      <Container maxWidth="lg">
        <Typography 
          variant="h2" 
          component="h2"
          sx={{ 
            color: theme.brandColors?.blue || '#1A3D8F',
            fontWeight: 700,
            mb: 4,
            textAlign: 'center',
            fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' }
          }}
        >
          Recommended Tours
        </Typography>
        <Grid 
          container 
          spacing={{ xs: 2, sm: 3, md: 4 }}
          justifyContent="center"
          alignItems="center"
          sx={{ mb: { xs: 4, sm: 6, md: 8 } }}
        >
          {tours.map((tour) => (
            <Grid 
              item 
              xs={12} 
              sm={6} 
              md={4} 
              key={tour.id}
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <TourCardV2 tour={tour} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Card>
  );
}

export default RecommendedTours;
