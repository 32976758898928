import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Container, Grid, Card, CardMedia, CardContent, Button, useTheme, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Category data
const categories = [
  {
    id: 'island-tours',
    title: 'Island Tours',
    description: 'Explore the natural beauty and cultural heritage of Curaçao with our guided island tours. From historic landmarks to hidden gems, experience the best of what this Caribbean paradise has to offer.',
    image: '/images/categories/island-tours.jpg',
    color: '#1A3D8F'
  },
  {
    id: 'water-tours',
    title: 'Water Tours',
    description: 'Dive into the crystal-clear waters of Curaçao with our exciting water tours. From snorkeling and diving to boat trips and water sports, experience the vibrant marine life and stunning underwater landscapes.',
    image: '/images/categories/water-tours.jpg',
    color: '#0077B6'
  }
];

const Categories = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCategoryClick = (categoryId) => {
    navigate(`/categories/${categoryId}`);
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  return (
    <Box component="main">
      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          height: { xs: '40vh', sm: '50vh', md: '60vh' },
          width: '100%',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* Background Image */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: -1,
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1,
            },
            '& img': {
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            },
          }}
        >
          <img src="/images/curacao-hero.jpg" alt="Curaçao Tours" />
        </Box>

        {/* Hero Content */}
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Box
              sx={{
                color: 'white',
                textAlign: 'center',
                position: 'relative',
                zIndex: 2,
                px: { xs: 2, sm: 4 },
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' },
                  fontWeight: 'bold',
                  mb: 2,
                  textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                }}
              >
                <span style={{ color: '#FFD700' }}>Explore</span> Our Tours
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },
                  mb: 4,
                  maxWidth: '800px',
                  mx: 'auto',
                  textShadow: '1px 1px 3px rgba(0,0,0,0.5)',
                }}
              >
                Discover the best experiences Curaçao has to offer
              </Typography>
            </Box>
          </motion.div>
        </Container>
      </Box>

      {/* Categories Section */}
      <Box sx={{ py: { xs: 6, md: 10 }, backgroundColor: '#f9f9f9' }}>
        <Container maxWidth="lg">
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <Typography
              variant="h2"
              sx={{
                textAlign: 'center',
                mb: 6,
                fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                fontWeight: 'bold',
              }}
            >
              <span style={{ color: '#FFD700' }}>Tour</span>{' '}
              <span style={{ color: '#1A3D8F' }}>Categories</span>
            </Typography>

            <Grid container spacing={4}>
              {categories.map((category) => (
                <Grid item xs={12} md={6} key={category.id}>
                  <motion.div variants={itemVariants}>
                    <Card
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '16px',
                        overflow: 'hidden',
                        boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                          transform: 'translateY(-10px)',
                          boxShadow: '0 20px 40px rgba(0,0,0,0.2)',
                        },
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="300"
                        image={category.image}
                        alt={category.title}
                      />
                      <CardContent sx={{ flexGrow: 1, p: 4 }}>
                        <Typography
                          gutterBottom
                          variant="h4"
                          component="h2"
                          sx={{
                            fontWeight: 'bold',
                            color: category.color,
                            mb: 2,
                          }}
                        >
                          {category.title}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          sx={{ mb: 3 }}
                        >
                          {category.description}
                        </Typography>
                        <Button
                          variant="contained"
                          endIcon={<ArrowForwardIcon />}
                          onClick={() => handleCategoryClick(category.id)}
                          sx={{
                            backgroundColor: category.color,
                            color: 'white',
                            px: 3,
                            py: 1.2,
                            borderRadius: '30px',
                            '&:hover': {
                              backgroundColor: theme.palette.primary.dark,
                            },
                          }}
                        >
                          Explore {category.title}
                        </Button>
                      </CardContent>
                    </Card>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </motion.div>
        </Container>
      </Box>
    </Box>
  );
};

export default Categories; 