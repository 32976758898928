import React from 'react';
import { 
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, 
  Typography, Box, Chip
} from '@mui/material';
import { format, isValid } from 'date-fns';
import { useTheme } from '@mui/material/styles';

// Helper function to safely format dates
const safeFormatDate = (dateValue, formatString) => {
  if (!dateValue) return 'N/A';
  
  // Special case for "Invalid Date" string
  if (dateValue === 'Invalid Date') {
    return 'Date pending';
  }
  
  try {
    const date = typeof dateValue === 'string' ? new Date(dateValue) : dateValue;
    
    // Check if date is valid
    if (!isValid(date)) return 'Date pending';
    
    return format(date, formatString);
  } catch (error) {
    console.warn('Date formatting error:', error, dateValue);
    return 'Date pending';
  }
};

const MenuRequestDetail = ({ 
  open, 
  onClose, 
  selectedRequest, 
  guestChoices,
  onResendEmail
}) => {
  const theme = useTheme();
  
  if (!selectedRequest) {
    return null;
  }

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ bgcolor: theme.brandColors?.blue || theme.palette.primary.main, color: 'white' }}>
        Menu Request Details
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">Booking ID</Typography>
            <Typography variant="body1" gutterBottom>{selectedRequest.bookingNumber || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">Guest Name</Typography>
            <Typography variant="body1" gutterBottom>{selectedRequest.participantName || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">Email</Typography>
            <Typography variant="body1" gutterBottom>{selectedRequest.participantEmail || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">Number of Guests</Typography>
            <Typography variant="body1" gutterBottom>{selectedRequest.totalParticipants || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">Tour Name</Typography>
            <Typography variant="body1" gutterBottom>{selectedRequest.tourName || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">Tour Type</Typography>
            <Typography variant="body1" gutterBottom>{selectedRequest.tourType || 'Not specified'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">Tour Date</Typography>
            <Typography variant="body1" gutterBottom>
              {selectedRequest.tourDate ? safeFormatDate(new Date(selectedRequest.tourDate), 'MMMM d, yyyy') : 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">Email Sent</Typography>
            <Typography variant="body1" gutterBottom>
              {selectedRequest.createdAt ? safeFormatDate(selectedRequest.createdAt, 'MMMM d, yyyy h:mm a') : 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">Status</Typography>
            <Typography variant="body1" gutterBottom>
              {guestChoices[selectedRequest.bookingNumber] ? (
                <Chip 
                  label={`Completed on ${safeFormatDate(guestChoices[selectedRequest.bookingNumber].submittedAt, 'MMMM d, yyyy h:mm a')}`} 
                  color="success" 
                />
              ) : (
                <Chip 
                  label={selectedRequest.status === 'completed' ? 'Completed' : 'Pending Response'} 
                  color={selectedRequest.status === 'completed' ? 'success' : 'warning'} 
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">Menu Selection Link</Typography>
            <Box sx={{ wordBreak: 'break-all', bgcolor: 'grey.100', p: 2, borderRadius: 1 }}>
              <a 
                href={`https://www.curacao-tours.com/guest-menu?bookingId=${selectedRequest.bookingNumber}&name=${encodeURIComponent(selectedRequest.participantName)}&email=${encodeURIComponent(selectedRequest.participantEmail)}&guests=${selectedRequest.totalParticipants}&date=${selectedRequest.tourDate}&tourType=${selectedRequest.tourType || ''}`} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                {`https://www.curacao-tours.com/guest-menu?bookingId=${selectedRequest.bookingNumber}&name=${encodeURIComponent(selectedRequest.participantName)}&email=${encodeURIComponent(selectedRequest.participantEmail)}&guests=${selectedRequest.totalParticipants}&date=${selectedRequest.tourDate}&tourType=${selectedRequest.tourType || ''}`}
              </a>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        {selectedRequest && !guestChoices[selectedRequest.bookingNumber] && (
          <Button 
            onClick={() => onResendEmail(selectedRequest)}
            color="primary"
            variant="contained"
          >
            Resend Email
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MenuRequestDetail; 